

import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';

const ClickableResultBox = styled(motion.div, {
  shouldForwardProp: (prop) => prop !== 'smaller' && prop !== 'isSelected',
})(({ isSelected, smaller }) => ({
  color: isSelected ? '#fff' : '#303030', 
  padding: '5px',
  borderRadius: 4,
  width: smaller ? '138px' : '240px',
  height: smaller ? '58px' : '110px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '24px',
  fontFamily: "'Inter', sans-serif",
  cursor: 'pointer',
  pointerEvents: 'auto', // Make sure all boxes are clickable
  transition: 'all 0.1s ease',
  border: smaller ? '1px solid #303030' : 'none',
  userSelect: 'none', // Prevent text selection on click

  // Remove default outline and box-shadow on focus
  outline: 'none',
  boxShadow: 'none',

  '-webkit-tap-highlight-color': 'transparent',

  // Override focus styles
  '&:focus': {
    outline: 'none',
    boxShadow: 'none',
    backgroundColor: isSelected ? '#025131' : '#fff',
  },
  // Override active styles
  '&:active': {
    outline: 'none',
    boxShadow: 'none',
    backgroundColor: isSelected ? '#025131' : '#fff',
  },
  // Adjust hover state
  '&:hover': {
    backgroundColor: isSelected ? '#025131' : '#f0f0f0',
  },
  // Remove focus-visible outline
  '&:focus-visible': {
    outline: 'none',
    boxShadow: 'none',
  },
  // Remove inner border in Firefox
  '&::-moz-focus-inner': {
    border: 0,
  },
}));

export default ClickableResultBox;
