import { Typography, Grid2 as Grid } from "@mui/material";
import SystemEngineeringImg from "../../assets/pngs/systemEngineering.png";

const SystemEngineering = () => {

    return (
        <>
            <Grid container direction={"column"} spacing={2}>
                <Grid size={8}>
                    <Typography
                        fontWeight={"bold"}
                        fontFamily={"Inter"}
                        fontSize={"16px"}
                        color={"#303030"}
                    >
                        System Engineering
                    </Typography>
                </Grid>

                <Grid mt={5}>
                    <img
                        src={SystemEngineeringImg}
                        alt=""
                        style={{
                            width: '100%',
                            height: 'auto',
                            objectFit: 'cover',
                        }} />
                </Grid>
            </Grid>
        </>
    )
}
export default SystemEngineering;