import { Drawer, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink, useLocation } from 'react-router-dom';

const StyledButtonLink = styled(Button)(({ isSelected }) => ({
    height: '44px',
    width: '200px',
    backgroundColor: isSelected ? '#025131' : 'transparent',
    fontFamily: 'Inter',
    fontWeight: 'bold',
    fontSize: '20px',
    color: isSelected ? '#fff' : '#303030',
    '&:hover': {
        backgroundColor: isSelected ? '#025131' : '#F2F2F2',
        color: isSelected ? '#fff' : '#000',
    },
    textTransform: 'none'
}));

const Sidebar = () => {
    const location = useLocation();

    const isTracerActive = () => {
        return location.pathname === '/Tracer-Requirements';
    };

    const isDocumentsActive = () => {
        return location.pathname === '/Documents';
    }

    const isSummaryActive = () => {
        return location.pathname === '/Summary';
    }

    const isDecisionTreeActive = () => {
        return location.pathname === '/Tracer';
    }

    return (
        <Drawer
            variant="permanent"
            anchor="left"
            sx={{
                width: '250px',
                flexShrink: 0,
                zIndex: 100, // Set lower z-index to be under the modal's backdrop
                position: 'absolute',
                '& .MuiDrawer-paper': {
                    width: '250px',
                    boxSizing: 'border-box',
                    backgroundColor: '#F2F2F2',
                    zIndex: 1, // Ensure the drawer content has the same lower z-index
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '10em',
                    gap: '30px'
                }}
            >
                <NavLink
                    to="/Explore"
                    style={{ textDecoration: 'none' }}
                    children={({ isActive }) => (
                        <StyledButtonLink isSelected={isActive}>
                            Explore
                        </StyledButtonLink>
                    )}
                />
                <NavLink
                    to="/Summary"
                    style={{ textDecoration: 'none' }}
                    children={({ isActive }) => (
                        <StyledButtonLink isSelected={isSummaryActive() || isActive}>
                            Summary
                        </StyledButtonLink>
                    )}
                />

                <StyledButtonLink>
                    Projects
                </StyledButtonLink>

                <NavLink
                    to="/Tracer-Requirements"
                    style={{ textDecoration: 'none' }}
                    children={({ isActive }) => (
                        <StyledButtonLink isSelected={isTracerActive() || isActive}>
                            Tracer
                        </StyledButtonLink>
                    )}
                />

                <NavLink
                    to="/Documents"
                    style={{ textDecoration: 'none' }}
                    children={({ isActive }) => (
                        <StyledButtonLink isSelected={isDocumentsActive() || isActive}>
                            Documents
                        </StyledButtonLink>
                    )}
                />

                <NavLink
                    to="/Tracer"
                    style={{ textDecoration: 'none' }}
                    children={({ isActive }) => (
                        <StyledButtonLink isSelected={isDecisionTreeActive() || isActive}>
                            Decision Tree
                        </StyledButtonLink>
                    )}
                />
            </Box>
        </Drawer>
    );
}

export default Sidebar;
