import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { Box, Typography, Grid2 as Grid, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { useMutation, gql } from "@apollo/client";
import { GET_STANDARDS_AND_GUIDELINES_GQL } from "../../gql/queries/summary";
import { NotificationToaster } from "../../utils/NotificationToaster";

const StandardsAndGuidelines = (props) => {
    const { ideaId } = props;

    const [standardsAndGuidelines, setStandardsAndGuidelines] = useState([]);

    const REMOVE_STANDARD_TO_SUMMARY_GQL = gql`
		mutation RemoveStandardFromSummary($ideaId: ID!, $standardId: ID!) {
			removeStandardFromIdea(ideaId: $ideaId, standardId: $standardId) {
				id
			}
		}
	`;

    const [removeStandardFromSummary] = useMutation(REMOVE_STANDARD_TO_SUMMARY_GQL);

    const handleRemoveStandardFromSummary = (standard) => {
        removeStandardFromSummary({
            variables: {
                ideaId: ideaId,
                standardId: standard?.id,
            },
        })
            .then((res) => {
                NotificationToaster({
                    message: "Standard removed from summary",
                    type: "success",
                });

                setStandardsAndGuidelines((prevState) => ({
                    standards: prevState?.standards?.filter(obj => obj !== standard),
                    guidances: prevState?.guidances
                })
                );
            })
            .catch((err) => {
                NotificationToaster({
                    message: "Standard could not be removed from summary",
                    type: "error",
                });
            });
    };

    const REMOVE_GUIDANCE_FROM_SUMMARY_GQL = gql`
		mutation RemoveGuidanceFromSummary($ideaId: ID!, $guidanceId: ID!) {
			removeGuidanceFromIdea(ideaId: $ideaId, guidanceId: $guidanceId) {
				id
			}
		}
	`;

    const [removeGuidanceFromSummary] = useMutation(
        REMOVE_GUIDANCE_FROM_SUMMARY_GQL
    );

    const handleRemoveGuidanceFromSummary = (guidance) => {
        removeGuidanceFromSummary({
            variables: {
                ideaId: ideaId,
                guidanceId: guidance?.id,
            },
        })
            .then((res) => {
                NotificationToaster({
                    message: "Guidance removed from summary",
                    type: "success",
                });

                setStandardsAndGuidelines((prevState) => ({
                    standards: prevState?.standards,
                    guidances: prevState?.guidances?.filter(obj => obj !== guidance)
                })
                );
            })
            .catch((err) => {
                NotificationToaster({
                    message: "Guidance could not be removed from summary",
                    type: "error",
                });
            });
    };

    const [fetchStandardsGuidelines, { loading }] = useLazyQuery(
        GET_STANDARDS_AND_GUIDELINES_GQL,
        {
            fetchPolicy: 'no-cache'
        }
    );

    const fetchStandardsAndGuidelinesData = () => {
        fetchStandardsGuidelines({
            variables: {
                ideaId
            },
        })
            .then((res) => {
                setStandardsAndGuidelines(res?.data?.idea);
            })
            .catch((err) => {
                console.log(err);
                NotificationToaster({
                    message: err.message ?? "Something went wrong",
                    type: "error",
                });
            });
    }

    useEffect(() => {
        fetchStandardsAndGuidelinesData();
    }, []);

    console.log(standardsAndGuidelines)

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Typography
                    fontWeight={"bold"}
                    fontFamily={"Inter"}
                    fontSize={"16px"}
                    color={"#303030"}
                >
                    Applicable Standards & Guidelines
                </Typography>
            </Box>

            {
                loading ? (
                    <Grid
                        container
                        direction={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        <Grid
                            size={12}
                            textAlign={"center"}
                            sx={{
                                height: '100%',
                                alignItems: "center"
                            }}>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                ) : (
                    standardsAndGuidelines?.standards?.length === 0 && standardsAndGuidelines?.guidances?.length === 0 ? (
                        <Grid container mt={2} direction={"column"} justifyContent={"center"} alignItems={"center"} sx={{ width: "100%", height: "100%" }}>
                            <Grid size={12} textAlign={"center"}>
                                No Records Found
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container sx={{ marginTop: "10px", maxHeight: "14.5rem", overflowY: "auto" }}>
                            <TableContainer component={Paper} elevation={0}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ border: '1px solid #ccc', backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>ISO Number</TableCell>
                                            <TableCell style={{ border: '1px solid #ccc', backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Title</TableCell>
                                            <TableCell style={{ border: '1px solid #ccc', backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            standardsAndGuidelines?.standards?.map((standard, key) => {
                                                return (
                                                    <TableRow key={key}>
                                                        <TableCell sx={{ border: '1px solid #ccc' }}>
                                                            <a
                                                                href={standard?.url}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                style={{ textDecoration: "none" }}
                                                            >
                                                                <Typography
                                                                    fontWeight={600}
                                                                    fontFamily={"Inter"}
                                                                    fontSize={"16px"}
                                                                    color={"#038C5A"}
                                                                >
                                                                    {standard?.id}
                                                                </Typography>
                                                            </a>
                                                        </TableCell>

                                                        <TableCell sx={{ border: '1px solid #ccc' }}>
                                                            <a
                                                                href={standard?.url}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                style={{ textDecoration: "none" }}
                                                            >
                                                                <Typography
                                                                    fontWeight={600}
                                                                    fontFamily={"Inter"}
                                                                    fontSize={"16px"}
                                                                    color={"#038C5A"}
                                                                >
                                                                    {standard?.title}
                                                                </Typography>
                                                            </a>
                                                        </TableCell>

                                                        <TableCell sx={{ border: '1px solid #ccc' }}>
                                                            <Grid
                                                                onClick={() => handleRemoveStandardFromSummary(standard)}
                                                                sx={{
                                                                    backgroundColor: "#D9D9D9",
                                                                    textAlign: "center",
                                                                    fontWeight: "900",
                                                                    fontFamily: "Inter",
                                                                    cursor: "pointer",
                                                                    fontSize: "12px",
                                                                    height: '1.5rem',
                                                                    padding: "5px 5px"
                                                                }}>
                                                                X
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }

                                        {
                                            standardsAndGuidelines?.guidances?.map((guidance, key) => {
                                                return (
                                                    <TableRow key={key}>
                                                        <TableCell sx={{ border: '1px solid #ccc' }}>
                                                            <Typography
                                                                fontWeight={600}
                                                                fontFamily={"Inter"}
                                                                fontSize={"16px"}
                                                                color={"#038C5A"}
                                                            >
                                                                {guidance?.id}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell sx={{ border: '1px solid #ccc' }}>
                                                            <Typography
                                                                fontWeight={600}
                                                                fontFamily={"Inter"}
                                                                fontSize={"16px"}
                                                                color={"#038C5A"}
                                                            >
                                                                {guidance?.title}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell sx={{ border: '1px solid #ccc' }}>
                                                            <Grid
                                                                onClick={() => handleRemoveGuidanceFromSummary(guidance)}
                                                                sx={{
                                                                    backgroundColor: "#D9D9D9",
                                                                    textAlign: "center",
                                                                    fontWeight: "900",
                                                                    fontFamily: "Inter",
                                                                    cursor: "pointer",
                                                                    fontSize: "12px",
                                                                    height: '1.5rem',
                                                                    padding: "5px 5px"
                                                                }}>
                                                                X
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            {/* {
                                standardsAndGuidelines?.standards?.map((standard, key) => {
                                    return (
                                        <Box
                                            key={key}
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "10px",
                                            }}
                                        >
                                            <Grid
                                                container
                                                spacing={2}
                                            >
                                                <Grid
                                                    item
                                                    size={"grow"}
                                                >
                                                    <Typography
                                                        fontWeight={600}
                                                        fontFamily={"Inter"}
                                                        fontSize={"16px"}
                                                        color={"#038C5A"}
                                                    >
                                                        {standard?.developingOrganization}{" "}
                                                        {standard?.designationNumberAndDate} :{" "}
                                                        <em>{standard?.title}</em>
                                                    </Typography>
                                                </Grid>

                                                <Grid
                                                    onClick={() => handleRemoveStandardFromSummary(standard)}
                                                    sx={{
                                                        backgroundColor: "#D9D9D9",
                                                        textAlign: "center",
                                                        fontWeight: "900",
                                                        fontFamily: "Inter",
                                                        cursor: "pointer",
                                                        fontSize: "12px",
                                                        height: '1.5rem',
                                                        padding: "5px 5px"
                                                    }}>
                                                    X
                                                </Grid>
                                            </Grid>

                                            <a
                                                href={standard?.url}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{ textDecoration: "none" }}
                                            >
                                                <Typography
                                                    fontWeight={300}
                                                    fontFamily={"Inter"}
                                                    fontSize={"12px"}
                                                    color={"#303030"}
                                                    style={{
                                                        wordBreak: "break-word",
                                                    }}
                                                >
                                                    {standard?.url}
                                                </Typography>
                                            </a>
                                        </Box>
                                    )
                                })
                            } */}
                        </Grid >
                    )
                )
            }
        </>
    )
}
export default StandardsAndGuidelines;