import React, { useRef, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";

const DecisionLog = ({ decisionData, currentDecision, showSuggestion, setShowSuggestion }) => {
  const logContainerRef = useRef(null);
  const [logCount, setLogCount] = useState(0);

  const logEntries = Object.keys(decisionData)
    .filter((key) => parseInt(key) <= currentDecision)
    .map((key) => ({
      decision: `Decision (${key})`,
      rationale: decisionData[key].rationale,
      verified: decisionData[key].verified,
      usedIn: decisionData[key].usedIn,
    }));
    useEffect(() => {
      if (logEntries.length !== logCount) {
        setLogCount(logEntries.length);
      }
    }, [logEntries]);
  useEffect(() => {
    if (logContainerRef.current) {
      const logContainer = logContainerRef.current;
      const totalHeight = logContainer.scrollHeight - logContainer.clientHeight;
      const scrollDuration = 1000;
      const scrollStep = totalHeight / (scrollDuration / 16);

      let startTime = null;

      const scrollAnimation = (currentTime) => {
        if (!startTime) startTime = currentTime;
        const elapsedTime = currentTime - startTime;

        logContainer.scrollTop += scrollStep;

        if (logContainer.scrollTop < totalHeight) {
          requestAnimationFrame(scrollAnimation);
        }
      };

      requestAnimationFrame(scrollAnimation);
    }
  }, [currentDecision]);
  useEffect(() => {
    if (logContainerRef.current) {
      const logContainer = logContainerRef.current;
      logContainer.scrollTop = logContainer.scrollHeight;
    }
  }, [currentDecision]);
  const competitorProducts = [
    { name: "Competitor Product #1", contactType: "indirect contact" },
    { name: "Competitor Product #2", contactType: "direct contact" },
  ];

  const handleToggleSuggestion = () => {
    setShowSuggestion((prev) => !prev);
  };

  return (
    <LogContainer ref={logContainerRef}>
      {showSuggestion && (
        <motion.div
          initial={{ y: 0, opacity: 0 }} // Start from below
          animate={{ y: 0, opacity: 1 }} // Slide up to its position
          exit={{ y: 100,  opacity: 0  }} // Slide down when exiting
          transition={{ duration: 0.5, ease: [0.25, 0.5, 0.25, 1.0] }} // Smooth, slow transition
          style={{ width: "100%" }}
        >
          <SmartSuggestion>
            <StyledTitle variant="h6">Smart Suggestion</StyledTitle>
            <InfoWrapper>
              <Typography style={{ display: "flex", textAlign: "left", width: "100%" }} variant="body1">
                <strong style={{ width: "25%" }}>Info:</strong> 
                <span style={{ width: "73%" }}>
                  Contact of the device with the body has a direct impact on the testing required in order to demonstrate compliance with ISO 10993-1.
                </span>
              </Typography>
              <Typography variant="body1" style={{ display: "flex", textAlign: "left", flexDirection: "row", width: "100%" }}>
                <strong style={{ width: "25%" }}>Definitions:</strong>
                <span>
                  Patient/body contact = XXX <br /> 
                  directly = xxxx <br />
                  indirectly = xxxx <br />
                </span>
              </Typography>
              <Divider style={{ width: "50%" }} />
              <Typography variant="body1">
                <strong>Example products of competitors:</strong>
              </Typography>
              <div style={{ display: "flex", textAlign: "left", width: "100%" }}>
                <span style={{ width: "25%" }}></span>
                <ul>
                  {competitorProducts.map((product, index) => (
                    <li key={index}>
                      <CompetitorProduct variant="body2">
                        <i>{product.name} - {product.contactType}</i>
                      </CompetitorProduct>
                    </li>
                  ))}
                </ul>
              </div>
            </InfoWrapper>
          </SmartSuggestion>
        </motion.div>
      )}

<MotionDiv
  initial={{ y: 0, opacity: 0 }}  // Start with opacity 0 and y=0
  animate={{ y: showSuggestion ? "50%" : 0, opacity: 1 }}  // Fade in and move vertically
  exit={{ opacity: 0 }}  // Fade out when exiting
  transition={{ duration: 0.2, ease: [0.25, 0.5, 0.25, 1.0] }}  // Adjust the timing for smoothness
  style={{ marginBottom: `calc(${logCount * 40}px + 1rem)` }}
>
        <LogTitle variant="h6">Decision log</LogTitle>
        <LogTable  style={{ width: "100%" }}>
          <LogHeader container>
            <HeaderCell item xs={3}></HeaderCell>
            <HeaderCell item xs={3}>Rationale</HeaderCell>
            <HeaderCell item xs={3}>Verified</HeaderCell>
            <HeaderCell item xs={3}>Used in</HeaderCell>
          </LogHeader>
          {logEntries.map((entry, index) => (
            <LogEntry container key={index}>
              <DecisionCell item xs={3}>{entry.decision}</DecisionCell>
              <Cell item xs={3}>
                <AnimatedCell initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                  {entry.rationale}
                </AnimatedCell>
              </Cell>
              <Cell item xs={3}>
                <AnimatedCell initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                  {entry.verified}
                </AnimatedCell>
              </Cell>
              <Cell item xs={3}>
                <AnimatedCell initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                  {entry.usedIn}
                </AnimatedCell>
              </Cell>
            </LogEntry>
          ))}
        </LogTable>
      </MotionDiv>
    </LogContainer>
  );
};


const MotionDiv = styled(motion.div)`
  width: 100% !important;
`;
const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "'Inter', sans-serif",
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "24px",
  textDecoration: "underline",
  color: "#303030",
}));

const InfoWrapper = styled(Box)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 400,
  marginTop: "30px",
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
  },
  "& p": {
    marginBottom: "10px",
  },
  "& ul": {
    listStyleType: "none",
    paddingLeft: 0,
    
  },
  "& li": {
    marginBottom: "5px",
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
  },
}));

const CompetitorProduct = styled(Typography)(({ theme }) => ({
  fontFamily: "'Inter', sans-serif",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#303030",
  marginTop: theme.spacing(0.5),
  textDecoration:'underline',

}));

const Divider = styled(Box)(({ theme }) => ({
  borderBottom: `1px dashed #303030`,
  width: "100%",
  margin: theme.spacing(2, 0),
}));

const LogContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // marginTop: theme.spacing(6),
  fontFamily: "Inter, sans-serif",
  color: theme.palette.text.primary,
  maxHeight: '100%', 
  padding: '1.3rem',
 gap:'7rem',
 width:'94%',
 
  // [theme.breakpoints.up('md')]: {
  //   maxWidth: '800px', 
  //   minWidth: '700px',
  // },
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',  
    minWidth: 'auto',  
    padding: '0 20px', 
  },
}));

const SmartSuggestion = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  border: `3px solid #038c5a`,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3),
  maxWidth: '100%',
  textAlign: 'center',
  marginTop:'0 !important'
}));

const LogTitle = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.spacing(3),
  fontFamily: "'Inter', sans-serif",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "24px",
  textDecorationLine: "underline",
  fontFeatureSettings: "'tnum' on, 'lnum' on",
  color: "#303030",
}));

const LogTable = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "1000px",
  
}));

const LogHeader = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  fontSize: 18,
  fontWeight: 600,
}));

const HeaderCell = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 2.5),
  fontFamily: "'Inter', sans-serif",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "20px",
  lineHeight: "24px",
  fontFeatureSettings: "'tnum' on, 'lnum' on",
  color: "#303030",
}));

const LogEntry = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1.5, 0),
  fontSize: 14,
  fontWeight: 400,
}));

const DecisionCell = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(2.5),
  fontFamily: "'Inter', sans-serif",
  fontWeight: 700,
  fontSize: 20,
  lineHeight: "24px",
  fontFeatureSettings: "'tnum' on, 'lnum' on",
  color: "#303030",
}));

const Cell = styled(Grid)(({ theme, isPending }) => ({
  paddingLeft: theme.spacing(2.5),
  fontFamily: "'Inter', sans-serif",
  fontStyle: isPending ? "italic" : "normal",
  fontWeight: isPending ? 400 : 400,
  fontSize: isPending ? "12px" : "12px",
  lineHeight: isPending ? "15px" : "14px",
  fontFeatureSettings: "'tnum' on, 'lnum' on",
  color: isPending ? "#D9D9D9" : "#303030",
}));

const AnimatedCell = styled(motion.div)`
  display: inline-block;
`;

export default DecisionLog;
