import { gql } from "@apollo/client";


export const GET_PM_GLOSSARY_GQL = gql`
    query GetPMGlossary($ideaId: ID!) {
        idea(id: $ideaId) {
            deviceName
            productModel
            countries
            shelfLife
        }
    }
`;

export const GET_ENG_GLOSSARY_GQL = gql`
    query fetchIdeaEngGlossary($ideaId: ID!) {
        idea(id: $ideaId) {
            material
            height
            width
            depth
        }
    }
`;

export const GET_REGULATORY_AND_QUALITY_GLOSSARY_GQL = gql`
    query GetRegulatoryAndQualityGlossary($ideaId: ID!) {
        idea(id: $ideaId) {
            productCode {
                code
                deviceClassCode
            }
            regulations {
                id
            }
            indicationsForUse
            contraIndications
            intendedUse
        }
    }
`;

export const GET_PREDICATE_DEVICES_GQL = gql`
    query GetPredicateDevices($ideaId: ID!) {
        idea(id: $ideaId) {
            products {
            id
            manufacturer {
                name
            }
            name
            productCode {
                code
                deviceClassCode
            }
            status
            }
        }
    }
`;

export const GET_STANDARDS_AND_GUIDELINES_GQL = gql`
    query GetStandardsAndGuidelines($ideaId: ID!) {
        idea(id: $ideaId) {
            standards {
                id
                title
                designationNumberAndDate
                developingOrganization
                url
            }
            guidances {
                id
                title
            }
        }
    }
`;

export const GET_COMPETITOR_SPECS_GQL = gql`
    query GetCompetitorSpecs($ideaId: ID!) {
        idea(id: $ideaId) {
            products {
            id
            name
            specs {
                name 
                value
                }
            }
        }
    }
`;

export const GET_STANDARDS_COMPLIANCE_GQL = gql`
    query GetStandardsCompliance($ideaId: ID!) {
        idea(id: $ideaId) {
            standards {
                title
                completionRate
                complianceStatus
            }
        }
    }
`;