import { useSelector } from "react-redux";
import { Box, Dialog, Grid2 as Grid, Typography, Button } from "@mui/material";
import CloseButton from "../../../assets/pngs/closeButton.png";
import { styled } from "@mui/material/styles";
import AddToSummaryButton from "../../shared/AddToSummaryButton";
import { useMutation, useLazyQuery } from "@apollo/client";
import { ADD_COMPANY_TO_IDEA } from "../../../gql/mutations/companies";
import { useEffect, useState } from "react";
import { NotificationToaster } from "../../../utils/NotificationToaster";
import GraphTree from "../../GraphTree/GraphTree";
import SankeyChart from "../../FinancialGraph/FinancialGraph";
import { IS_COMPANY_ADDED_TO_IDEA } from "../../../gql/queries/companies";
import { REMOVE_COMPANY_FROM_IDEA } from "../../../gql/mutations/companies";
import GoToSummaryButton from "../../shared/GoToSummaryButton";

const StyledWhiteBox = styled(Box)(() => ({
	display: "flex",
	gap: "30px",
	padding: "30px 0px",
	flexDirection: "column",
	backgroundColor: "#fff",
	justifyContent: "center",
	alignItems: "center",
	borderRadius: "4px",
	border: "1px solid #626262",
	minWidth: "268px",
}));

const StyledProductButton = styled(Button)(({ isSelected }) => ({
	width: "230px",
	minHeight: "53px",
	backgroundColor: isSelected ? "#025131" : "transparent",
	borderRadius: "11px",
	border: isSelected ? "1px solid #025131" : "1px solid #000",
	"&:hover": {
		backgroundColor: isSelected ? "#025131" : "#F2F2F2",
		color: isSelected ? "#fff" : "#000",
	},
	textTransform: "none",
	cursor: "default",
}));

const CompanyDialog = ({
	companyData,
	open,
	handleClose,
	handleViewSummary,
	isSummary = false,
	handleCompanyGraphDialogOpen,
}) => {
	const PatentPlaceholder = [
		"Patent #1",
		"Patent #2",
		"Patent #3",
		"Patent #4",
		"Patent #5",
	];

	const ideaId = useSelector((state) => state?.explore?.idea?.id);

	const [addCompanyToIdea, { loading }] = useMutation(ADD_COMPANY_TO_IDEA);
	const [removeCompanyFromIdea] = useMutation(REMOVE_COMPANY_FROM_IDEA);
	const [isCompanyAdded, setIsCompanyAdded] = useState(false);
	const [companyGraphFullScreen, setCompanyGraphFullScreen] = useState(false);
	const [sankeyGraphFullScreen, setSankeyGraphFullScreen] = useState(false);

	const handleAddToSummary = () => {
		if (isCompanyAdded) {
			removeCompanyFromIdea({
				variables: {
					ideaId: ideaId,
					companyId: companyData?.company?.id,
				},
			})
				.then((res) => {
					NotificationToaster({
						message: "Company removed from Summary",
						type: "success",
					});
					setIsCompanyAdded(false);
				})
				.catch((e) => {
					NotificationToaster({
						message: e.message ?? "Something went wrong",
						type: "error",
					});
				});
		} else {
			addCompanyToIdea({
				variables: {
					ideaId: ideaId,
					companyId: companyData?.company?.id,
				},
			})
				.then((res) => {
					NotificationToaster({
						message: "Company added to Summary",
						type: "success",
					});
					setIsCompanyAdded(true);
				})
				.catch((e) => {
					NotificationToaster({
						message: e.message ?? "Something went wrong",
						type: "error",
					});
				});
		}
	};

	const [isCompanyAddedToIdea] = useLazyQuery(IS_COMPANY_ADDED_TO_IDEA, {
		fetchPolicy: "no-cache",
	});

	const checkCompanyAdded = () => {
		if (companyData?.company?.id) {
			isCompanyAddedToIdea({
				variables: {
					ideaId,
				},
			})
				.then((response) => {
					const addedCompanies = response.data.idea?.companies;
					if (
						addedCompanies?.length > 0 &&
						addedCompanies.some(
							(company) => company.id === companyData.company?.id
						)
					) {
						setIsCompanyAdded(true);
					} else {
						setIsCompanyAdded(false);
					}
				})
				.catch((e) => {
					NotificationToaster({
						message: e.message ?? "Something went wrong",
						type: "error",
					});
				});
		}
	};

	useEffect(() => {
		checkCompanyAdded();
	}, [companyData]);

	const handleExpandClick = () => {
		setCompanyGraphFullScreen((prev) => !prev);
	};

	const handleSankeyExpandClick = () => {
		setSankeyGraphFullScreen((prev) => !prev);
	};

	return (
		<Dialog
			onClose={() => {
				setIsCompanyAdded(false);
				handleClose();
			}}
			open={open}
			fullScreen
			disablePortal
			hideBackdrop
			PaperProps={{
				sx: {
					width: "70%",
					height: "80%",
					backgroundColor: "#F2F2F2",
					borderRadius: "4px",
					marginTop: "80px",
				},
			}}
		>
			<Box
				sx={{
					display: "flex",
					padding: 2,
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						width: "100%",
					}}
				>
					<Box
						style={{
							display: "flex",
						}}
					>
						<Typography
							display="inline"
							color="#038C5A"
							fontSize={"40px"}
							fontFamily={"Inter"}
							fontWeight={"bold"}
						>
							<em>
								{companyGraphFullScreen
									? `${companyData?.company?.name} Company Structure`
									: companyData?.company?.name}
							</em>
						</Typography>
					</Box>

					<img
						src={CloseButton}
						alt="x"
						width="30px"
						height="30px"
						style={{
							marginRight: 15,
							cursor: "pointer",
						}}
						onClick={
							companyGraphFullScreen
								? () => {
										setCompanyGraphFullScreen(false);
								  }
								: sankeyGraphFullScreen
								? () => {
										setSankeyGraphFullScreen(false);
								  }
								: handleClose
						}
					/>
				</Box>
			</Box>

			<Box
				sx={{
					display: "flex",
					padding:
						companyGraphFullScreen || sankeyGraphFullScreen ? "0" : "20px 30px",
					height: "100%",
					width: "100%",
				}}
			>
				{companyGraphFullScreen ? (
					<Box
						sx={{
							display: "flex",
							width: "100%",
							height: "100%",
						}}
					>
						{companyData &&
							companyData?.company &&
							companyData.company?.graphId && (
								<Box
									sx={{
										display: "flex",
										width: "100%",
										height: "100%",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<GraphTree
										initialGraphId={companyData.company.graphId}
										isFullScreen={true}
										onExpandClick={handleExpandClick} // Pass the expand click handler
									/>
								</Box>
							)}
					</Box>
				) : sankeyGraphFullScreen ? (
					<Box
						sx={{
							display: "flex",
							width: "100%",
							height: "100%",
						}}
					>
						{companyData &&
							companyData?.company &&
							companyData.company?.financials && (
								<Box
									sx={{
										display: "flex",
										width: "100%",
										height: "100%",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<SankeyChart
										isFullScreen={true}
										onExpandClick={handleSankeyExpandClick}
										financialData={companyData.company?.financials}
									/>
								</Box>
							)}
					</Box>
				) : (
					<Grid container spacing={4} style={{ paddingLeft: "50px" }}>
						{/* Product Portfolio Section */}
						<Grid item>
							<StyledWhiteBox>
								<Typography
									fontWeight={"bold"}
									fontFamily={"Inter"}
									fontSize={"20px"}
									color={"#303030"}
									style={{ textAlign: "center" }}
								>
									Product Portfolio
								</Typography>

								{companyData?.company?.products?.map((product, key) => (
									<Box
										key={key}
										sx={{
											width: "200px",
											minHeight: "53px",
											backgroundColor: "transparent",
											borderRadius: "11px",
											border: "1px solid #000",
											"&:hover": {
												backgroundColor: "#F2F2F2",
												color: "#000",
											},
											textTransform: "none",
											cursor: "default",
											padding: 2,
										}}
									>
										<Typography
											display="inline"
											fontWeight={400}
											fontFamily={"Inter"}
											fontSize={"16px"}
											color={"#303030"}
										>
											Product #{key + 1}:&nbsp;
										</Typography>

										<Typography
											display="inline"
											fontWeight={"bold"}
											fontFamily={"Inter"}
											fontSize={"16px"}
											color={"#303030"}
										>
											<em>{product?.name}</em>
										</Typography>
									</Box>
								))}
							</StyledWhiteBox>

							{!isSummary && (
								<>
									<AddToSummaryButton
										isAdded={isCompanyAdded}
										onClick={handleAddToSummary}
									/>
									<GoToSummaryButton handleViewSummary={handleViewSummary} />
								</>
							)}
						</Grid>

						{/* Company Structure Section */}
						<Grid item>
							<StyledWhiteBox>
								<Typography
									fontWeight={"bold"}
									fontFamily={"Inter"}
									fontSize={"20px"}
									color={"#303030"}
									style={{ textAlign: "center" }}
								>
									Company Structure
								</Typography>

								{companyData &&
									companyData?.company &&
									companyData.company?.graphId && (
										<Box
											sx={{
												display: "flex",
												width: "100%",
												height: "200px",
												cursor: "pointer",
											}}
											onClick={() => setCompanyGraphFullScreen(true)}
										>
											<GraphTree
												initialGraphId={companyData?.company?.graphId}
												onExpandClick={handleExpandClick}
											/>
										</Box>
									)}
							</StyledWhiteBox>
						</Grid>

						<Grid item>
							<StyledWhiteBox>
								<Typography
									fontWeight={"bold"}
									fontFamily={"Inter"}
									fontSize={"20px"}
									color={"#303030"}
									style={{ textAlign: "center" }}
								>
									Financial Analysis
								</Typography>

								{companyData && companyData?.company && (
									<Box
										sx={{
											display: "flex",
											width: "100%",
											height: "200px",
											cursor: "pointer",
										}}
										onClick={() => setSankeyGraphFullScreen(true)}
									>
										<SankeyChart
											onExpandClick={handleSankeyExpandClick}
											financialData={companyData?.company?.financials}
										/>
									</Box>
								)}
							</StyledWhiteBox>
						</Grid>

						<Grid item>
							<StyledWhiteBox>
								<Typography
									fontWeight={"bold"}
									fontFamily={"Inter"}
									fontSize={"20px"}
									color={"#303030"}
									style={{ textAlign: "center" }}
								>
									Patent Portfolio
								</Typography>

								{PatentPlaceholder?.map((patent, key) => (
									<StyledProductButton key={key} disableRipple>
										<Typography
											display="inline"
											fontWeight={"bold"}
											fontFamily={"Inter"}
											fontSize={"16px"}
											color={"#626262"}
										>
											{patent}
										</Typography>
									</StyledProductButton>
								))}
							</StyledWhiteBox>
						</Grid>
					</Grid>
				)}
			</Box>
		</Dialog>
	);
};

export default CompanyDialog;
