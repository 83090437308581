import { Typography, Grid2 as Grid } from "@mui/material";

const TestEngineering = () => {

    return (
        <>
            <Grid container direction={"column"} spacing={2}>
                <Grid size={8}>
                    <Typography
                        fontWeight={"bold"}
                        fontFamily={"Inter"}
                        fontSize={"16px"}
                        color={"#303030"}
                    >
                        Test Engineering
                    </Typography>
                </Grid>

                <Grid>
                    <Typography
                        fontWeight={"light"}
                        fontFamily={"Inter"}
                        fontSize={"16px"}
                        color={"#585B5B"}
                        style={{ textDecoration: "underline" }}
                    >
                        List of all Test Plans
                    </Typography>

                    <ul>
                        <li
                            style={{ color: "#585B5B" }}>
                            <Typography
                                fontWeight={"bold"}
                                fontFamily={"Inter"}
                                fontSize={"16px"}
                                color={"#585B5B"}
                            >
                                no test plans/protocols
                            </Typography>
                        </li>
                    </ul>
                </Grid>

                <Grid>
                    <Typography
                        fontWeight={"light"}
                        fontFamily={"Inter"}
                        fontSize={"16px"}
                        color={"#585B5B"}
                        style={{ textDecoration: "underline" }}
                    >
                        List of all Test Reports
                    </Typography>

                    <ul>
                        <li
                            style={{ color: "#585B5B" }}>
                            <Typography
                                fontWeight={"bold"}
                                fontFamily={"Inter"}
                                fontSize={"16px"}
                                color={"#585B5B"}
                            >
                                no test reports
                            </Typography>
                        </li>
                    </ul>
                </Grid>
            </Grid>
        </>
    )
}
export default TestEngineering;