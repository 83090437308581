import React, { useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ClickableResultBox from "./ClickableResultBox";
const DecisionNode = ({
  number,
  question,
  yesLabel,
  noLabel,
  onDecisionMade,
  onResultClick,
  onHelpClick,
  isDashed,
  leftLineDashed,
  thirdDecision = false,
  firstLabel,
  secondLabel,
  thirdLabel,
  onTopResultPositionChange,
}) => {
  const [selected, setSelected] = useState(null);
  const optionRefs = {
    first: useRef(null),
    second: useRef(null),
    third: useRef(null),
  };
  const handleDecision = (decision, optionKey) => {
    setSelected(decision);
    onDecisionMade(decision);

    // Get the position of the selected option and pass it to the parent
    const optionRef = optionRefs[optionKey];
    if (optionRef && optionRef.current) {
      const rect = optionRef.current.getBoundingClientRect();
      const position = {
        top: rect.top + window.scrollY + rect.height, // Adjust position below the selected option
        left: rect.left + window.scrollX,
      };

      onTopResultPositionChange(position); // Pass the position to parent
    }
  };
  
  const getResultText = (decision) => {
    if (!thirdDecision) {
      if (decision === "yes") {
        return "ISO 10993-1:2003(E) Material Characterization";
      } else {
        return "4.2.1 non-contact devices Biological Evaluation not applicable";
      }
    } else {
      switch (decision) {
        case "first":
          return "Option 1 Result";
        case "second":
          return "Option 2 Result";
        case "third":
          return "Option 3 Result";
        default:
          return "";
      }
    }
  };

  return (
    <>
      <Decision>
        <DecisionNumber>Decision ({number})</DecisionNumber>
      </Decision>

      {!thirdDecision ? (
        <NodeContainer number={number}>
          <LeftColumn>
            <ConnectorLineVerticalLeft isSelected={selected === "yes"} />
            <StyledButton
              variant={selected === "yes" ? "contained" : "outlined"}
              onClick={() => handleDecision("yes")}
              isSelected={selected === "yes"}
            >
              {yesLabel}
            </StyledButton>
            <LineAndArrow isSelected={selected === "yes"}>
              <VerticalLine isSelected={selected === "yes"} />
              <ArrowDown isSelected={selected === "yes"} />
            </LineAndArrow>

            <ClickableResultBox
              isSelected={selected === "yes"}
              number={number}
              onClick={() => {
                if (selected === "yes") {
                  onResultClick(getResultText("yes"));
                }
              }}
              animate={{
                backgroundColor: selected === "yes" ? "#025131" : "#e0e0e0",
              }}
              transition={{ duration: 0.5 }}
            >
              {getResultText("yes")}
            </ClickableResultBox>
            <DynamicLine isDashed={leftLineDashed} />
          </LeftColumn>

          <CenterColumn>
            <ConnectorLineLeft isSelected={selected === "yes"} />
            <NodeWrapper>
              <QuestionBox selected={selected} number={number}>
                <Question>{question}</Question>
                <HelpButton onClick={onHelpClick}>?</HelpButton>
              </QuestionBox>
            </NodeWrapper>
            <ConnectorLineRight isSelected={selected === "no"} />
          </CenterColumn>

          <RightColumn>
            <ConnectorLineVerticalRight isSelected={selected === "no"} />
            <StyledButton
              variant={selected === "no" ? "contained" : "outlined"}
              onClick={() => handleDecision("no")}
              isSelected={selected === "no"}
            >
              {noLabel}
            </StyledButton>
            <LineAndArrow isSelected={selected === "no"}>
              <VerticalLine isSelected={selected === "no"} />
              <ArrowDown isSelected={selected === "no"} />
            </LineAndArrow>

            <ClickableResultBox
              isSelected={selected === "no"}
              number={number}
              onClick={() => {
                if (selected === "no") {
                  onResultClick(getResultText("no"));
                }
              }}
              animate={{
                backgroundColor: selected === "no" ? "#025131" : "#e0e0e0",
              }}
              transition={{ duration: 0.5 }}
            >
              {getResultText("no")}
            </ClickableResultBox>
            <DynamicLine isDashed={isDashed} />
          </RightColumn>
        </NodeContainer>
      ) : (
        <NodeContainerThree number={number}>
          <CenterColumn>
            <NodeWrapper>
              <QuestionBox selected={selected} number={number}>
                <Question>{question}</Question>
                <HelpButton onClick={onHelpClick}>?</HelpButton>
              </QuestionBox>
            </NodeWrapper>
            <LineConnectorWrapper>
              <HorizontalLine />
              <VerticalLineMiddle />
            </LineConnectorWrapper>
          </CenterColumn>

          <ThreeColumnLayout>
          <Box ref={optionRefs.first} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <DynamicLineThree selected={selected === 'first'} isDashed={isDashed} initial={selected === null} />

 
  <ClickableResultBox
    onClick={() => handleDecision('first', 'first')} // Pass 'first' string as the key
    isSelected={selected === 'first'}
    style={{ cursor: 'pointer' }}
    animate={{
      backgroundColor: selected === 'first' ? '#025131' : '#fff',
    }}
    transition={{ duration: 0.1 }}
    smaller={true}
  >
    {firstLabel}
  </ClickableResultBox>
  {selected === 'first' &&<DynamicLineThreeDashed isDashed={isDashed} />}
</Box>

<Box ref={optionRefs.second} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
<DynamicLineThree selected={selected === 'second'} isDashed={isDashed} initial={selected === null} />

  
  <ClickableResultBox
    onClick={() => handleDecision('second', 'second')} // Pass 'second' string as the key
    isSelected={selected === 'second'}
    style={{ cursor: 'pointer' }}
    animate={{
      backgroundColor: selected === 'second' ? '#025131' : '#fff',
    }}
    transition={{ duration: 0.1 }}
    smaller={true}
  >
    {secondLabel}
  </ClickableResultBox>
  {selected === 'second' &&<DynamicLineThreeDashed isDashed={isDashed} />}
</Box>

<Box ref={optionRefs.third} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
<DynamicLineThree selected={selected === 'third'} isDashed={isDashed} initial={selected === null} />
  
  <ClickableResultBox
    onClick={() => handleDecision('third', 'third')} // Pass 'third' string as the key
    isSelected={selected === 'third'}
    style={{ cursor: 'pointer' }}
    animate={{
      backgroundColor: selected === 'third' ? '#025131' : '#fff',
    }}
    transition={{ duration: 0.1 }}
    smaller={true}
  >
    {thirdLabel}
  </ClickableResultBox>
  {selected === 'third' &&<DynamicLineThreeDashed isDashed={isDashed} />}
</Box>

          </ThreeColumnLayout>
        </NodeContainerThree>
      )}
    </>
  );
};


const NodeContainer = styled(Box)(({ theme, number }) => ({
  display: "flex",
  gridTemplateColumns: "1fr 1fr 1fr",
  justifyItems: "center",
  alignItems: "center",
  height: "100%",
  fontFamily: "Inter, sans-serif",
  position: "relative",
  marginTop: number === 1 ? "-4rem" : "-5rem",
  [theme.breakpoints.up("md")]: {
    maxWidth: "1000px", 
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
    width: "100%",
  },
}));

const LeftColumn = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  marginRight: "-3.5rem",
  marginBottom: "-24rem",
  [theme.breakpoints.down("md")]: {
    marginBottom: "0", 
  },
}));
const LineAndArrow = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
 
});

// Vertical line connecting the decision button to the result box
const VerticalLine = styled("div")(({ isSelected }) => ({
  width: "4px",
  height: "90px",
  backgroundColor: ` ${isSelected ? "#025131" : "#303030"}`,
}));

// Arrow pointing downward
const ArrowDown = styled("div")(({ isSelected }) => ({
  width: "0",
  height: "0",
  borderLeft: "10px solid transparent",
  borderRight: "10px solid transparent",
  borderTop: `10px solid ${isSelected ? "#025131" : "#303030"}`,
  marginTop: "-2px",
  
}));

const CenterColumn = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: 250,
  height: 250,
  position: "relative",
  [theme.breakpoints.down("md")]: {
    width: "100%",  // Full width for smaller screens
    marginBottom: "20px",  // Add spacing between items for smaller screens
  },
}));

const RightColumn = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  marginBottom: "-24rem",
  marginLeft: "-3.5rem",
  [theme.breakpoints.down("md")]: {
    marginBottom: "0",  // Adjust margin for smaller screens
  },
}));



const StyledButton = styled(Button)(({ isSelected, theme }) => ({
  borderRadius: 4,
  backgroundColor: isSelected ? "#025131" : "transparent",
  color: isSelected ? "#fff" : "#303030",
  padding: "10px 20px",
  border: "1px solid #025131",
  cursor: "pointer",
  transition: "all 0.3s ease",
  fontWeight: 700,
  fontSize: 20,

  "&:hover": {
    backgroundColor: isSelected ? "#024026" : "rgba(2, 81, 49, 0.1)",
    borderColor: isSelected ? "#024026" : "#025131",
    color: isSelected ? "#fff" : "#025131",
  },
  "&:focus": {
    backgroundColor: isSelected ? "#024026" : "transparent",
    borderColor: isSelected ? "#024026" : "#025131",
    outline: "none", // Remove default browser focus outline
    color: isSelected ? "#fff" : "#025131", // Ensure color stays correct
  },
  "&:active": {
    backgroundColor: isSelected ? "#024026" : "rgba(2, 81, 49, 0.2)", // Active state styling
    borderColor: isSelected ? "#024026" : "#025131",
  },
}));




const ArrowIcon = styled("img")(({ theme }) => ({
  width: 30,
  [theme.breakpoints.down("md")]: {
    width: "20px", // Adjust icon size for smaller screens
  },
}));
const NodeWrapper = styled(Box)(({ theme }) => ({
  width: '300px', 
  height: '300px',
  display: "flex",
  justifyContent: "flex-start", // Align content to the left horizontally
  alignItems: "flex-start",    // Align content to the top vertically
  position: "relative",
}));


const QuestionBox = styled(Box)(({ selected, number, theme }) => ({
  position: "relative",
  width: "250px",
  height: "250px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "30px",
  textAlign: "center",

  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: selected === null ? (number === 1 ? "#025131" : "#626262") : "#025131",
    clipPath: "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)",
    zIndex: -1,
  },
}));

const Question = styled(Typography)(({ theme }) => ({
  fontFamily: "Inter, sans-serif",
  fontWeight: 700,
  fontSize: "20px", 
  color: "#ffffff",
  maxWidth: "130px", 
  lineHeight: "1.2em", 
  textAlign: "center",
}));


const HelpButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
 
  bottom: 0,
  margin: "0.4rem",
  backgroundColor: "transparent",
  color: "#fff",
  width: 30,
  height: 30,

  "&:hover": {
    backgroundColor: "#ffffff",
    color: "#025131",
  },
}));

const ConnectorLineLeft = styled(Box)(({ isSelected, theme }) => ({
  position: "absolute",
  height: "4px",
  width: "71px",
  backgroundColor: isSelected ? "#025131" : "#303030",
  top: "50%",
  right: "100%",
  transform: "translateY(-50%)",
}));

const ConnectorLineRight = styled(Box)(({ isSelected, theme }) => ({
  position: "absolute",
  height: "4px",
  width: "71px",
  backgroundColor: isSelected ? "#025131" : "#303030",
  top: "50%",
  left: "100%",
  transform: "translateY(-50%)",
}));

const ConnectorLineVerticalLeft = styled(Box)(({ isSelected, theme }) => ({
  position: "absolute",
  width: "4px",
  height: "55px",
  backgroundColor: isSelected ? "#025131" : "#303030",
  top: "0",
  transform: "translateY(-100%)",
}));

const ConnectorLineVerticalRight = styled(Box)(({ isSelected, theme }) => ({
  position: "absolute",
  width: "4px",
  height: "55px",
  backgroundColor: isSelected ? "#025131" : "#303030",
  top: "0",
  transform: "translateY(-100%)",
}));
const Decision = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start", // Center horizontally
  alignItems: "center", // Center vertically
  width: "100%",
  padding: "20px", // Add space above and below
  marginBottom: "-40px", // Adjust margin to move closer to the node
}));

const DecisionNumber = styled(Typography)(({ theme }) => ({
  fontFamily: "Inter, sans-serif",
  fontWeight: 700,
  fontSize: 20, // Increase font size slightly for emphasis
  lineHeight: "28px",
  color: "#303030",
  textAlign: "center", // Center text
  marginBottom: "10px", // Add margin to position it well above the decision node
}));

const LineConnectorWrapper = styled(Box)({
  position: "relative",
  height: "50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const HorizontalLine = styled(Box)({
  width: "338px",
  height: "4px",
  backgroundColor: "#303030",
  position: "fixed",
  
  marginTop: '3.5rem',
});

const VerticalLineMiddle = styled(Box)({
  width: "4px",
  height: "60px",
  backgroundColor: "#303030",
  position: "absolute",
  top: "0",
  marginTop: '-2rem',
});

const NodeContainerThree = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyItems: "center",
  alignItems: "center",
  height: "100%",
  fontFamily: "Inter, sans-serif",
  position: "relative",
  marginTop: "-7.2rem",
  [theme.breakpoints.up("md")]: {
    maxWidth: "1000px",
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
    width: "100%",
  },
  gap: '8rem',
}));

const ThreeColumnLayout = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  maxWidth: "1000px",
  gap: "1rem",
  marginLeft:'0.3rem'
}));
const DynamicLine = styled(Box)(({ isDashed, theme }) => ({
  position: "absolute",
  width: "4px",
  height: "60px",
  backgroundColor: "transparent",
  borderLeft: isDashed ? "4px dashed #025131" : "4px solid #303030",
  top: "100%",
  left: "50%",
  transform: "translateX(-50%)",
}));
const DynamicLineThree = styled(Box)(({ isDashed, theme, selected, initial }) => ({
  height: "60px",
  backgroundColor: "transparent",
  borderLeft: isDashed ? "4px dashed #025131" : "4px solid #303030",
  marginTop: initial
    ? "-4.5rem"    // Initial state
    : selected
    ? "-4.5rem"  // Selected state
    : "-7.4rem", // Unselected state
  transform: "translateX(-50%)",
}));

const DynamicLineThreeDashed = styled(Box)(({ isDashed, theme }) => ({

  height: "47px",
  backgroundColor: "transparent",
  borderLeft: isDashed ? "4px dashed #025131" : "4px solid #303030",
  marginTop: '0rem',
  transform: "translateX(-50%)",
}));

export default DecisionNode;
