import { gql } from "@apollo/client";


export const ADD_PRODUCT_TO_IDEA = gql`
    mutation AddProductToIdea($ideaId: ID!, $productId: ID!) {
        addProductToIdea(ideaId: $ideaId, productId: $productId) {
            id
        }
    }
`;

export const REMOVE_PRODUCT_FROM_IDEA = gql`
    mutation RemoveProductFromIdea($ideaId: ID!, $productId: ID!) {
        removeProductFromIdea(ideaId: $ideaId, productId: $productId) {
            id
        }
    }
`;

export const CONFIRM_PRODUCT_STATUS_GQL = gql`
    mutation ConfirmProductInIdea($ideaId: ID!, $productId: ID!) {
        confirmProductInIdea(ideaId: $ideaId, productId: $productId) {
            status
        }
    }
`;

export const UNCONFIRM_PRODUCT_STATUS_GQL = gql`
    mutation UnconfirmProductInIdea($ideaId: ID!, $productId: ID!) {
        unconfirmProductInIdea(ideaId: $ideaId, productId: $productId) {
            status
        }
    }
`;