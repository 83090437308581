import { gql } from "@apollo/client";

export const ADD_REGULATION_TO_IDEA = gql`
    mutation AddRegulationToIdea($ideaId: ID!, $regulationId: ID!) {
        addRegulationToIdea(ideaId: $ideaId, regulationId: $regulationId) {
            id
        }
    }
`;

export const REMOVE_REGULATION_FROM_SUMMARY_GQL = gql`
    mutation RemoveRegulationFromIdea($ideaId: ID!, $regulationId: ID!) {
        removeRegulationFromIdea(ideaId: $ideaId, regulationId: $regulationId) {
            id
        }
    }
`;