import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Dialog, Grid, Typography, Button, Divider } from "@mui/material";
import CloseButton from "../../../assets/pngs/closeButton.png";
import { styled } from "@mui/material/styles";
import { useLazyQuery, useMutation, gql } from "@apollo/client";
import { NotificationToaster } from "../../../utils/NotificationToaster";
import AddToSummaryButton from "../../shared/AddToSummaryButton";
import {
	ADD_REGULATION_TO_IDEA,
	REMOVE_REGULATION_FROM_SUMMARY_GQL,
} from "../../../gql/mutations/regulations";
import GoToSummaryButton from "../../shared/GoToSummaryButton";

const StyledWhiteBox = styled(Box)(() => ({
	display: "flex",
	gap: "30px",
	padding: "30px 0px",
	flexDirection: "column",
	backgroundColor: "#fff",
	justifyContent: "center",
	alignItems: "center",
	borderRadius: "4px",
	border: "1px solid #626262",
}));

const StyledHighlightButton = styled(Button)(({ isSelected, buttoncolor }) => ({
	height: "24px",
	backgroundColor: isSelected ? buttoncolor : "transparent",
	borderRadius: "4px",
	border: isSelected ? `3px solid ${buttoncolor}` : `3px solid ${buttoncolor}`,
	"&:hover": {
		backgroundColor: isSelected ? buttoncolor : "transparent",
		color: "#303030",
	},
	color: "#303030",
	textTransform: "none",
	fontFamily: "Inter",
	fontSize: "20px",
	fontWeight: "bold",
	padding: "20px 10px",
}));

const FdaDialog = ({
	regulationData,
	open,
	handleClose,
	handleViewSummary,
}) => {
	const ideaId = useSelector((state) => state?.explore?.idea?.id);

	const [selectedSuggestions, setSelectedSuggestions] = useState([]);

	const [isRegulationAdded, setIsRegulationAdded] = useState(false);

	const [addRegulationToIdea] = useMutation(ADD_REGULATION_TO_IDEA);
	const [removeRegulationFromIdea] = useMutation(
		REMOVE_REGULATION_FROM_SUMMARY_GQL
	);

	const CHECK_IF_REGULATION_ADDED_GQL = gql`
		query CheckIfRegulationAdded($ideaId: ID!) {
			idea(id: $ideaId) {
				regulations {
					id
				}
			}
		}
	`;
	const [checkIfRegulationAdded] = useLazyQuery(CHECK_IF_REGULATION_ADDED_GQL, {
		fetchPolicy: "no-cache",
	});

	const handleAddToSummary = () => {
		if (isRegulationAdded) {
			removeRegulationFromIdea({
				variables: {
					ideaId,
					regulationId: regulationData?.regulation?.id,
				},
			})
				.then(() => {
					NotificationToaster({
						message: "Regulation removed from Summary",
						type: "success",
					});
					setIsRegulationAdded(false);
				})
				.catch((e) => {
					NotificationToaster({
						message: e.message ?? "Something went wrong",
						type: "error",
					});
				});
		} else {
			addRegulationToIdea({
				variables: {
					ideaId,
					regulationId: regulationData?.regulation?.id,
				},
			})
				.then(() => {
					NotificationToaster({
						message: "Regulation added to Summary",
						type: "success",
					});
					setIsRegulationAdded(true);
				})
				.catch((e) => {
					NotificationToaster({
						message: e.message ?? "Something went wrong",
						type: "error",
					});
				});
		}
	};

	const handleSelectsuggestion = (suggestion, color, className) => {
		const keywords = document.querySelectorAll(`.${className}`);

		if (selectedSuggestions?.includes(suggestion)) {
			setSelectedSuggestions(
				selectedSuggestions?.filter((e) => e !== suggestion)
			);

			keywords.forEach((word) => {
				word.style.backgroundColor = "transparent";
			});
		} else {
			setSelectedSuggestions([...selectedSuggestions, suggestion]);

			keywords.forEach((word) => {
				word.style.backgroundColor = color;
			});
		}
	};

	const checkRegulationAdded = () => {
		checkIfRegulationAdded({
			variables: {
				ideaId: ideaId,
			},
		})
			.then((res) => {
				console.log(res?.data?.idea?.regulations);
				const regulationsAdded = res?.data?.idea?.regulations?.filter(
					(obj) => obj.id === regulationData?.regulation?.id
				);
				if (regulationsAdded?.length !== 0) {
					setIsRegulationAdded(true);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		if (open) {
			checkRegulationAdded();
		}
	}, [open]);

	useEffect(() => {
		setIsRegulationAdded(false);
	}, [regulationData]);

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			fullScreen
			disablePortal
			hideBackdrop
			PaperProps={{
				sx: {
					width: "70%",
					height: "80%",
					backgroundColor: "#F2F2F2",
					borderRadius: "4px",
					marginTop: "80px",
				},
			}}
		>
			<Box
				sx={{
					display: "flex",
					padding: 2,
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "right",
						width: "100%",
					}}
				>
					<img
						src={CloseButton}
						alt="x"
						width="30px"
						height="30px"
						style={{
							marginRight: 15,
							cursor: "pointer",
						}}
						onClick={handleClose}
					/>
				</Box>
			</Box>

			<Box
				sx={{
					display: "flex",
					padding: 2,
					height: "100%",
				}}
			>
				<Grid container spacing={2} sx={{ height: "100%" }}>
					<Grid item xs={4}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<StyledWhiteBox style={{ width: "320px" }}>
								<Typography
									fontWeight={"bold"}
									fontFamily={"Inter"}
									fontSize={"20px"}
									color={"#303030"}
									style={{ textAlign: "center" }}
								>
									Suggestions
								</Typography>

								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										gap: "10px",
									}}
								>
									{regulationData?.regulation?.content?.suggestions?.map(
										(suggestion, key) => {
											return (
												<Box
													key={key}
													sx={{
														display: "flex",
														gap: "6px",
														justifyContent: "right",
														alignItems: "center",
														cursor: "pointer",
													}}
													onClick={() =>
														handleSelectsuggestion(
															suggestion?.name,
															suggestion?.color,
															suggestion?.className
														)
													}
												>
													<StyledHighlightButton
														buttoncolor={suggestion?.color}
														isSelected={selectedSuggestions?.includes(
															suggestion?.name
														)}
														disableRipple
													>
														{suggestion?.name}
													</StyledHighlightButton>

													<Box
														sx={{
															backgroundColor: selectedSuggestions?.includes(
																suggestion?.name
															)
																? suggestion?.color
																: "transparent",
															border: selectedSuggestions?.includes(
																suggestion?.name
															)
																? `3px solid ${suggestion?.color}`
																: `3px solid ${suggestion?.color}`,
															color: "#303030",
															borderRadius: "20px",
															display: "flex",
															alignItems: "center",
															justifyContent: "center",
															width: 40,
															height: 40,
															marginLeft: 1,
															"&:hover": {
																cursor: "pointer",
															},
														}}
													>
														<Typography
															fontWeight={"bold"}
															fontFamily={"Inter"}
															fontSize={"20px"}
															color={"#303030"}
														>
															{suggestion?.count}
														</Typography>
													</Box>
												</Box>
											);
										}
									)}
								</Box>

								<Divider
									style={{ minWidth: "70%", backgroundColor: "#626262" }}
								/>

								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<Typography
										fontWeight={"bold"}
										fontFamily={"Inter"}
										fontSize={"17px"}
										color={"#303030"}
										style={{ textAlign: "center" }}
									>
										Associated Guidelines/Standards
									</Typography>

									<Typography
										fontWeight={400}
										fontFamily={"Inter"}
										fontSize={"16px"}
										color={"#303030"}
										style={{ textAlign: "center" }}
										mt={-0.5}
									>
										<em>(Smart suggestion)</em>
									</Typography>

									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											alignItems: "left",
											justifyContent: "center",
											width: "80%",
											marginTop: 2,
										}}
									>
										<Typography
											fontWeight={"bold"}
											fontFamily={"Inter"}
											fontSize={"14px"}
											color={"#303030"}
											style={{ textAlign: "left" }}
										>
											ISO 10993-1
										</Typography>

										<Typography
											fontWeight={400}
											fontFamily={"Inter"}
											fontSize={"14px"}
											color={"#303030"}
											style={{ textAlign: "left" }}
											mt={-0.5}
										>
											<em>Biological evaluation of medical devices</em>
										</Typography>

										<Typography
											mt={2}
											fontWeight={"bold"}
											fontFamily={"Inter"}
											fontSize={"14px"}
											color={"#303030"}
											style={{ textAlign: "left" }}
										>
											FDA-2013-D-0350
										</Typography>

										<Typography
											fontWeight={400}
											fontFamily={"Inter"}
											fontSize={"14px"}
											color={"#303030"}
											style={{ textAlign: "left" }}
											mt={-0.5}
										>
											<em>FDA Biocomp. Guidance</em>
										</Typography>
									</Box>
								</Box>
							</StyledWhiteBox>
						</Box>

						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<AddToSummaryButton
								isAdded={isRegulationAdded}
								onClick={handleAddToSummary}
							/>

							<GoToSummaryButton handleViewSummary={handleViewSummary} />
						</Box>
					</Grid>

					<Grid item xs={8}>
						<StyledWhiteBox
							sx={{
								maxWidth: "800px",
								minHeight: "90%",
								padding: 0,
								marginBottom: 10,
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									padding: 2,
								}}
								dangerouslySetInnerHTML={{
									__html: regulationData?.regulation?.content?.html,
								}}
							/>
						</StyledWhiteBox>
					</Grid>
				</Grid>
			</Box>
		</Dialog>
	);
};

export default FdaDialog;
