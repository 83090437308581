import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { Box, Typography, CircularProgress, Grid2 as Grid, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { GET_COMPETITOR_SPECS_GQL } from "../../gql/queries/summary";
import { NotificationToaster } from "../../utils/NotificationToaster";

const CustomTableCell = styled(TableCell)(({ theme }) => ({
    border: '2px solid #d2d2d2',
    textAlign: 'center',
    color: '#8AB4B9',
    fontSize: '1rem',
}));

const CompetitorSpecs = (props) => {
    const { ideaId } = props;

    const [competitorSpecs, setCompetitorSpecs] = useState([]);

    const [fetchCompetitorSpecs, { loading }] = useLazyQuery(
        GET_COMPETITOR_SPECS_GQL,
        {
            fetchPolicy: 'no-cache'
        }
    );

    const fetchCompetitorSpecsData = () => {
        fetchCompetitorSpecs({
            variables: {
                ideaId
            },
        })
            .then((res) => {
                setCompetitorSpecs(res?.data?.idea?.products);
            })
            .catch((err) => {
                console.log(err);
                NotificationToaster({
                    message: err.message ?? "Something went wrong",
                    type: "error",
                });
            });
    }

    useEffect(() => {
        fetchCompetitorSpecsData();
    }, []);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Typography
                    fontWeight={"bold"}
                    fontFamily={"Inter"}
                    fontSize={"16px"}
                    color={"#303030"}
                >
                    Competitor Specs
                </Typography>
            </Box>

            {
                loading ? (
                    <Grid
                        container
                        direction={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        <Grid
                            size={12}
                            textAlign={"center"}
                            sx={{
                                height: '100%',
                                alignItems: "center"
                            }}>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                ) : (
                    competitorSpecs?.length === 0 ? (
                        <Grid container mt={2} direction={"column"} justifyContent={"center"} alignItems={"center"} sx={{ width: "100%", height: "100%" }}>
                            <Grid size={12} textAlign={"center"}>
                                No Records Found
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container sx={{ marginLeft: "30px", marginTop: "10px", maxHeight: "14.5rem", overflowY: "auto" }}>
                            <TableContainer component={Paper} style={{ borderRadius: '8px', overflow: 'hidden' }}>
                                <Table aria-label="customized table">
                                    <TableBody>
                                        {competitorSpecs?.map((itm, key) => {
                                            return (
                                                <TableRow key={key}>
                                                    <CustomTableCell>{itm?.id}</CustomTableCell>
                                                    <CustomTableCell>{itm?.name}</CustomTableCell>
                                                </TableRow>
                                            )
                                        })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    )
                )
            }
        </>
    )
}
export default CompetitorSpecs;