import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	Box,
	Button,
	Typography,
	Grid2 as Grid,
	Divider,
	CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { gql, useLazyQuery } from "@apollo/client";
import DownloadIcon from "../../assets/pngs/downloadIcon.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { NotificationToaster } from "../../utils/NotificationToaster";

const StyledBackButton = styled(Button)(() => ({
	height: "34px",
	width: "110px",
	backgroundColor: "#025131",
	fontFamily: "Inter",
	fontWeight: "bold",
	fontSize: "20px",
	color: "#fff",
	letterSpacing: 0,
	border: "1px solid #025131",
	borderRadius: "4px",
	"&:hover": {
		backgroundColor: "#025131",
		color: "#fff",
	},
	textTransform: "none",
}));

const StyledBorderBox = styled(Box)(() => ({
	border: "1px solid #D9D9D9",
	padding: "0.2rem 1rem",
}));

const DocumentWizard = ({ handleWizardToggle }) => {
	const ideaId = useSelector((state) => state?.explore?.idea?.id);

	const [docData, setDocData] = useState(null);
	const [iframeURL, setIframeURL] = useState(null);
	const [iframeLoader, setIframeLoader] = useState(false);

	const handleGetDocIframe = async (data) => {
		let payload = JSON.stringify({
			docno: data?.idea?.regulations[0]?.id || "",
			rev: data?.idea?.regulations?.revisionNumber || "",
			title: data?.idea?.regulations[0]?.id || "",
			dateOfAssessment: data?.today || "",
			deviceName: data?.idea?.deviceName || "",
			modalNumber: data?.idea?.productModel || "",
			deviceIntendedUse: data?.idea?.intendedUse || "",
			indicationsForUse: data?.idea?.indicationsForUse || "",
			deviceDescription: data?.idea?.summary || "",
			device: data?.idea?.deviceName || "",
			regularDescription:
				data?.idea?.regulations[0]?.excerpt?.substring(0, 100) || "",
			productCode: data?.idea?.productCode?.code || "",
			submissionType: data?.idea?.productCode?.submissionType || "",
			regulationNumber: data?.idea?.regulations[0]?.id || "",
			deviceClass: data?.idea?.productCode?.deviceClassCode || "",
			identification:
				data?.idea?.regulations[0]?.identification?.substring(0, 100) || "",
			exemptRationale: data?.idea?.regulations[0]?.excerpt || "",
			email: "rhuang@havideaconsulting.com",
		});

		try {
			const res = await fetch(
				"https://accelidea-be-staging-gzfahpbyehbrbyhx.eastus-01.azurewebsites.net/docusign/embedded-url",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: payload,
				}
			);

			const data = await res.json();
			setIframeURL(data?.url);
			setIframeLoader(false);
		} catch (error) {
			console.error("Error:", error);
			NotificationToaster({
				message: "Docusign document could not be generated",
				type: "error",
			});
			setIframeLoader(false);
		}
	};

	const GET_DOCUMENT_DATA_GQL = gql`
		query GetDocumentData($id: ID!) {
			today # Date of Assessment / Date in Approval Signatures
			idea(id: $id) {
				deviceName # Device Name / Device
				productModel # Model Number
				revisionNumber # Revision Number
				intendedUse # Device Intended Use
				indicationsForUse # Indications for Use
				summary # Device Description
				regulations {
					id # Regulation Number
					excerpt # Regulation Description
					identification # Regulation Identification
				}
				productCode {
					code # Product Code
					deviceClassCode # Device Class
					submissionType # Submission Type
				}
			}
			me {
				name # Assessment by / Head of Regulatory Affairs or Designee
			}
		}
	`;

	const [getDocumentInformation, { loading: GetDocumentDataLoading }] =
		useLazyQuery(GET_DOCUMENT_DATA_GQL, {
			fetchPolicy: "no-cache",
		});

	const handleGetDocumentInfo = () => {
		getDocumentInformation({
			variables: {
				id: ideaId,
			},
		})
			.then((res) => {
				setDocData(res?.data);
				setIframeLoader(true);
				handleGetDocIframe(res?.data);
			})
			.catch((err) => {
				console.log(err);
				NotificationToaster({
					message: "Document Information could not be fetched",
					type: "error",
				});
			});
	};

	useEffect(() => {
		handleGetDocumentInfo();
	}, []);

	return (
		<>
			<Box
				style={{
					display: "flex",
				}}
			>
				<StyledBackButton onClick={() => handleWizardToggle()}>
					<ArrowBackIcon
						style={{
							marginRight: 5,
						}}
					/>
					Back
				</StyledBackButton>
			</Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Typography
					fontWeight={"bold"}
					fontFamily={"Inter"}
					fontSize={"20px"}
					color={"#038C5A"}
					display="inline"
				>
					Document Wizard
				</Typography>

				<img src={DownloadIcon} alt="" width={"39px"} height={"39px"} />
			</Box>
			{GetDocumentDataLoading ? (
				<Box
					style={{
						display: "flex",
						width: "100%",
						height: "50%",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<CircularProgress />
				</Box>
			) : (
				<Grid container spacing={4}>
					<Grid
						item
						size={{
							xs: 2.5,
						}}
					>
						<StyledBorderBox
							style={{
								marginBottom: "1rem",
							}}
						>
							<Typography
								fontFamily={"Inter"}
								fontWeight={700}
								fontSize={"20px"}
								color={"#303030"}
								textAlign={"center"}
							>
								Document Information
							</Typography>
						</StyledBorderBox>

						<StyledBorderBox>
							<Box
								style={{
									display: "flex",
									flexDirection: "column",
									minHeight: "68.5vh",
								}}
							>
								<Typography
									fontFamily={"Inter"}
									fontWeight={700}
									fontSize={"20px"}
									color={"#303030"}
									textAlign={"center"}
									mb={"1rem"}
								>
									In This Document
								</Typography>

								{/* <Typography
                    fontFamily={"Inter"}
                    fontWeight={700}
                    fontSize={"16px"}
                    color={"#303030"}
                    sx={{
                        textDecoration: "underline",
                        fontStyle: "italic",
                    }}
                    mb={"0.5rem"}
                >
                    Latest Changes:
                </Typography> */}

								{/* <ul style={{ paddingLeft: "20px", marginTop: "8px" }}>
                    <li>
                        <Typography
                            fontFamily={"Inter"}
                            fontWeight={400}
                            fontSize={"16px"}
                            lineHeight={"19px"}
                            color={"#303030"}
                        >
                            <strong>ISO 10993-5 & ISO 10993-10</strong> were added to
                            List of Applicable Standards document based on{" "}
                            <em>Decision Tree by User-123</em>
                        </Typography>
                    </li>
                </ul> */}

								<Typography
									fontFamily={"Inter"}
									fontWeight={700}
									fontSize={"16px"}
									color={"#303030"}
									mb={"2rem"}
								>
									Device Name: {docData?.idea?.deviceName}
								</Typography>

								<Typography
									fontFamily={"Inter"}
									fontWeight={700}
									fontSize={"20px"}
									color={"#303030"}
									lineHeight={"24px"}
									textAlign={"center"}
									mb={"1rem"}
								>
									Found In Other Documents
								</Typography>

								<Typography
									fontFamily={"Inter"}
									fontWeight={700}
									fontSize={"16px"}
									color={"#303030"}
									mb={"0.25rem"}
								>
									Document 1
								</Typography>

								<Divider
									style={{
										marginTop: "2rem",
										minWidth: "70%",
										backgroundColor: "#626262",
									}}
								/>

								<Typography
									fontFamily={"Inter"}
									fontWeight={700}
									fontSize={"20px"}
									color={"#303030"}
									lineHeight={"20px"}
									textAlign={"center"}
									mb={"2rem"}
									mt={"1rem"}
								>
									Document Required By
								</Typography>

								<Typography
									fontFamily={"Inter"}
									fontWeight={700}
									fontSize={"16px"}
									lineHeight={"19px"}
									color={"#303030"}
									textAlign={"center"}
									mb={"1rem"}
									sx={{
										fontStyle: "italic",
									}}
								>
									{/* FDA - 21 CFR Part 820.30 */}
									{docData?.idea?.regulations[0]?.id}
								</Typography>
							</Box>
						</StyledBorderBox>
					</Grid>

					<Grid
						item
						size={{
							xs: 9.5,
						}}
					>
						<StyledBorderBox
							style={{
								width: "100%",
								height: "75vh",
								padding: 0,
							}}
						>
							{!iframeLoader ? (
								<iframe
									src={iframeURL}
									title="Document Wizard"
									height="100%"
									width="100%"
									style={{ border: "none" }}
								/>
							) : (
								<Box
									style={{
										display: "flex",
										width: "100%",
										height: "100%",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<CircularProgress />
								</Box>
							)}
						</StyledBorderBox>
					</Grid>
				</Grid>
			)}{" "}
		</>
	);
};

export default DocumentWizard;
