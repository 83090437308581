import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Grid2 as Grid } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import ProductDialog from "./dialogs/ProductDialog";
import FdaDialog from "./dialogs/FdaDialog";
import CompanyDialog from "./dialogs/CompanyDialog";
import IdeaColumn from "./gridComponents/IdeaColumn";
import AddKeywordColumn from "./gridComponents/AddKeywordColumn";
import SuggestedKeywordsColumn from "./gridComponents/SuggestedKeywordsColumn";
import CategoriesColumn from "./gridComponents/CategoriesColumn";
import ListingColumn from "./gridComponents/ListingColumn";
import ProductCodeGraphDialog from "./dialogs/ProductCodeGraphDialog";

const ExploreGrid = ({ setSelectedView }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const step = useSelector((state) => state?.explore?.step);
	const selectedCategoryInStore = useSelector(
		(state) => state?.explore?.selectedCategory
	);

	const [selectedCategory, setSelectedCategory] = useState(
		selectedCategoryInStore || null
	);
	const [openProductDialog, setOpenProductDialog] = useState(false);
	const [openFdaDialog, setOpenFdaDialog] = useState(false);
	const [openCompanyDialog, setOpenCompanyDialog] = useState(false);
	const [companyData, setCompanyData] = useState(null);
	const [productData, setProductData] = useState(null);
	const [regulationData, setRegulationData] = useState(null);

	const handleSelectedCategory = (category) => {
		setSelectedCategory(category);
		dispatch({
			type: "SELECTED_CATEGORY_SUCCESS",
			payload: category,
		});
	};

	const handleProductDialogOpen = (productData) => {
		setProductData(productData);
		setOpenProductDialog(true);
	};

	const handleProductDialogClose = () => {
		setOpenProductDialog(false);
		setProductData(null);
	};

	const handleFdaDialogOpen = (regulationData) => {
		setRegulationData(regulationData);
		setOpenFdaDialog(true);
	};

	const handleFdaDialogClose = () => {
		setOpenFdaDialog(false);
		setRegulationData(null);
	};

	const handleCompanyDialogOpen = (companyData) => {
		setCompanyData(companyData);
		setOpenCompanyDialog(true);
	};

	const handleCompanyDialogClose = () => {
		setOpenCompanyDialog(false);
		setCompanyData(null);
	};

	const handleViewSummary = () => {
		navigate("/Summary");
	};

	return (
		<Box
			className={"explore-box-wrapper"}
			sx={{
				display: "flex",
				flex: 1,
				maxWidth: "100rem",
			}}
		>
			<motion.div
				style={{
					display: "flex",
					height: "100%",
				}}
				initial={{ opacity: 1, display: "visible" }}
				animate={
					step === 0
						? { opacity: 1, display: "visible", x: "0px", y: "0px" }
						: {}
				}
				transition={{ duration: 1 }}
			>
				<Grid
					container
					spacing={3}
					wrap="nowrap"
					style={{
						height: step === 0 ? "100%" : "calc(100vh + 20rem)",
					}}
				>
					<Grid
						item
						style={{
							borderRight: step >= 1 && "1px dashed #000",
							paddingRight: 15,
							width: "380px",
						}}
					>
						<IdeaColumn step={step} />
					</Grid>

					<Grid
						item
						style={{
							borderRight: step >= 2 && "1px dashed #000",
							paddingRight: 15,
							width: "390px",
						}}
					>
						<AddKeywordColumn step={step} />
					</Grid>

					<Grid
						item
						style={{
							borderRight: step >= 5 && "1px dashed #000",
							paddingRight: 15,
							width: "390px",
						}}
					>
						<SuggestedKeywordsColumn
							step={step}
							handleSelectedCategory={handleSelectedCategory}
						/>
					</Grid>

					<Grid
						item
						style={{
							paddingRight: 15,
							borderRight:
								selectedCategory !== null && step > 6 ? "1px dashed #000" : "",
							width: "400px",
						}}
					>
						<CategoriesColumn
							step={step}
							selectedCategory={selectedCategory}
							handleSelectedCategory={handleSelectedCategory}
						/>
					</Grid>

					<AnimatePresence>
						{step === 7 && (
							<Grid
								item
								style={{
									paddingRight: 15,
									width: "390px",
									marginTop: "1rem",
									overflowY: "auto",
								}}
								component={motion.div}
								initial={{ opacity: 0, x: 50 }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: 0 }}
								transition={{ duration: 1 }}
							>
								<ListingColumn
									step={step}
									selectedCategory={selectedCategory}
									handleSelectedCategory={handleSelectedCategory}
									handleProductDialogOpen={handleProductDialogOpen}
									handleFdaDialogOpen={handleFdaDialogOpen}
									handleCompanyDialogOpen={handleCompanyDialogOpen}
								/>
							</Grid>
						)}
					</AnimatePresence>
				</Grid>
			</motion.div>

			{/*commented for future req*/}

			{/*<ProductDialog*/}
			{/*	productData={productData}*/}
			{/*	open={openProductDialog}*/}
			{/*	handleClose={handleProductDialogClose}*/}
			{/*	handleViewSummary={handleViewSummary}*/}
			{/*/>*/}

			<ProductCodeGraphDialog
				selectedProduct={productData?.product}
				open={openProductDialog}
				onClose={handleProductDialogClose}
			/>

			<FdaDialog
				regulationData={regulationData}
				open={openFdaDialog}
				handleClose={handleFdaDialogClose}
				handleViewSummary={handleViewSummary}
			/>

			<CompanyDialog
				companyData={companyData}
				open={openCompanyDialog}
				handleClose={handleCompanyDialogClose}
				handleViewSummary={handleViewSummary}
			/>
		</Box>
	);
};

export default ExploreGrid;
