import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

// Custom hook to determine if ViewToggle should be shown
export const useShowViewToggle = () => {
  const location = useLocation();
  const [showViewToggle, setShowViewToggle] = useState(false);

  useEffect(() => {
    if (location.pathname === '/Tracer-Requirements' || location.pathname === '/Tracer-Risk') {
      setShowViewToggle(true);
    } else {
      setShowViewToggle(false);
    }
  }, [location]);

  return showViewToggle;
};
