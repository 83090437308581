import { Box, Dialog } from "@mui/material";
import CloseButton from "../../assets/pngs/closeButton.png";

const ProductChartDialog = ({
    open,
    handleClose
}) => {
    return (
        <Dialog
            onClose={handleClose}
            open={open}
            fullScreen
            disablePortal
            hideBackdrop
            PaperProps={{
                sx: {
                    width: "90%",
                    height: "840px",
                    backgroundColor: "#F2F2F2",
                    borderRadius: "4px",
                    marginTop: "80px",
                },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    padding: 2,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "right",
                        width: "100%",
                    }}
                >
                    <img
                        src={CloseButton}
                        alt="x"
                        width="30px"
                        height="30px"
                        style={{
                            marginRight: 15,
                            cursor: "pointer",
                        }}
                        onClick={handleClose}
                    />
                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    padding: 2,
                    height: "100%",
                }}
            >
                <iframe
                    src="https://demo-product-chart.netlify.app"
                    title="Product Chart"
                    width="100%"
                    height="auto"
                    style={{ border: 'none' }}
                />
            </Box>
        </Dialog>
    );
};

export default ProductChartDialog;
