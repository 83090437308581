import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery, gql } from "@apollo/client";
import { Button, Box, Typography, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import LeftArrow from "../../../assets/pngs/leftArrow.png";

const StyledPopOverBox = styled(Box)(() => ({
	display: "flex",
	border: "1px solid #025131",
	borderRadius: "8px",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	minWidth: "297px",
	gap: "20px",
	padding: "10px 0px",
}));

const SelectionButton = styled(Typography)(({ isSelected }) => ({
	fontFamily: "Inter",
	fontWeight: 300,
	fontSize: "16px",
	letterSpacing: 0,
}));

const SuggestedKeywordsColumn = ({ step, handleSelectedCategory }) => {
	const dispatch = useDispatch();
	const ideaId = useSelector((state) => state?.explore?.idea?.id);
	const suggestedKeywordsInStore = useSelector(
		(state) => state?.explore?.suggestedKeywords
	);
	const suggestedKeywordsInViewInStore = useSelector(
		(state) => state?.explore?.suggestedKeywordsInView
	);
	const selectedKeywordsInStore = useSelector(
		(state) => state?.explore?.selectedKeywords
	);

	const [selectedKeywords, setSelectedKeywords] = useState(
		selectedKeywordsInStore || []
	);
	const [suggestedKeywords, setSuggestedKeywords] = useState(
		suggestedKeywordsInStore || []
	);
	const [suggestedKeywordsView, setSuggestedKeywordsView] = useState(
		suggestedKeywordsInViewInStore || []
	);

	const handleSelectKeyword = (keyword) => {
		handleSelectedCategory(null);

		let newSelectedKeywords = [];

		if (selectedKeywords?.some((item) => item?.name === keyword?.name)) {
			newSelectedKeywords = selectedKeywords?.filter(
				(val) => val?.name !== keyword?.name
			);
			setSelectedKeywords(newSelectedKeywords);

			dispatch({
				type: "SELECTED_KEYWORDS_UPDATE",
				payload: newSelectedKeywords,
			});
		} else {
			newSelectedKeywords = [...selectedKeywords, keyword];
			setSelectedKeywords(newSelectedKeywords);

			dispatch({
				type: "SELECTED_KEYWORDS_UPDATE",
				payload: newSelectedKeywords,
			});
		}

		if (newSelectedKeywords?.length === 0) {
			// dispatch({
			// 	type: "UPDATE_STEP",
			// 	payload: 5,
			// });

			dispatch({ type: "SET_CATEGORY_COUNTS_INIT" });
		} else {
			dispatch({
				type: "UPDATE_STEP",
				payload: 6,
			});
		}
	};

	const handleSelectAllKeywords = () => {
		handleSelectedCategory(null);

		setSelectedKeywords([...suggestedKeywordsView]);

		dispatch({
			type: "SELECTED_KEYWORDS_UPDATE",
			payload: [...suggestedKeywordsView],
		});

		dispatch({
			type: "UPDATE_STEP",
			payload: 6,
		});
	};

	const handleUnSelectAllKeywords = () => {
		dispatch({
			type: "SELECTED_KEYWORDS_UPDATE",
			payload: [],
		});

		setSelectedKeywords([]);

		dispatch({ type: "SET_CATEGORY_COUNTS_INIT" });
		handleSelectedCategory(null);

		dispatch({
			type: "UPDATE_STEP",
			payload: 6,
		});
	};

	const handleRemoveSuggestion = (keyword) => {
		const updatedKeywordsArray = suggestedKeywords?.filter(
			(key) => key?.id !== keyword?.id
		);
		setSuggestedKeywords(updatedKeywordsArray);

		dispatch({
			type: "SUGGESTED_KEYWORDS_SUCCESS",
			payload: updatedKeywordsArray,
		});

		if (updatedKeywordsArray?.length > 5) {
			const indexToSwap = suggestedKeywordsView?.findIndex(
				(obj) => obj === keyword
			);

			let tmpSuggestedKeywordsInView = [...suggestedKeywordsView];
			tmpSuggestedKeywordsInView[indexToSwap] = updatedKeywordsArray[5];
			setSuggestedKeywordsView(tmpSuggestedKeywordsInView);

			dispatch({
				type: "SUGGESTED_KEYWORDS_IN_VIEW_SUCCESS",
				payload: tmpSuggestedKeywordsInView,
			});
		} else {
			setSuggestedKeywordsView(
				suggestedKeywordsView?.filter((key) => key !== keyword)
			);

			dispatch({
				type: "SUGGESTED_KEYWORDS_IN_VIEW_SUCCESS",
				payload: suggestedKeywordsView?.filter((key) => key !== keyword),
			});
		}

		if (suggestedKeywords?.length === 0) {
			dispatch({ type: "SET_CATEGORY_COUNTS_INIT" });
		}

		if (selectedKeywords?.some((item) => item?.name === keyword?.name)) {
			let newSelectedKeywords = selectedKeywords?.filter(
				(val) => val?.name !== keyword?.name
			);
			setSelectedKeywords(newSelectedKeywords);

			dispatch({
				type: "SELECTED_KEYWORDS_UPDATE",
				payload: newSelectedKeywords,
			});

			if (newSelectedKeywords?.length === 0) {
				dispatch({ type: "SET_CATEGORY_COUNTS_INIT" });
			}

			dispatch({
				type: "UPDATE_STEP",
				payload: 6,
			});
			handleSelectedCategory(null);
		}
	};

	const GET_SUGGESTED_KEYWORDS_GQL = gql`
		query Ideas($id: ID!) {
			idea(id: $id) {
				suggestedKeywords {
					id
					name
				}
			}
		}
	`;

	const [fetchKeywords, { loading }] = useLazyQuery(
		GET_SUGGESTED_KEYWORDS_GQL,
		{
			fetchPolicy: "no-cache",
		}
	);

	useEffect(() => {
		if (step === 5 && selectedKeywords?.length === 0) {
			fetchKeywords({ variables: { id: ideaId } })
				.then((res) => {
					dispatch({
						type: "SUGGESTED_KEYWORDS_SUCCESS",
						payload: res?.data?.idea?.suggestedKeywords,
					});

					setSuggestedKeywords(res?.data?.idea?.suggestedKeywords);
					setSuggestedKeywordsView(
						res?.data?.idea?.suggestedKeywords?.slice(0, 5)
					);

					dispatch({
						type: "SUGGESTED_KEYWORDS_IN_VIEW_SUCCESS",
						payload: res?.data?.idea?.suggestedKeywords?.slice(0, 5),
					});
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [step]);

	useEffect(() => {
		if (
			suggestedKeywordsInViewInStore === null ||
			suggestedKeywordsInViewInStore === undefined ||
			suggestedKeywordsInViewInStore?.length === 0
		) {
			setSuggestedKeywordsView([]);
		} else {
			setSuggestedKeywordsView(suggestedKeywordsInViewInStore);
		}
	}, [suggestedKeywordsInViewInStore]);

	useEffect(() => {
		if (
			suggestedKeywordsInStore === null ||
			suggestedKeywordsInStore === undefined ||
			suggestedKeywordsInStore?.length === 0
		) {
			setSuggestedKeywords([]);
		} else {
			setSuggestedKeywords(suggestedKeywordsInStore);
		}
	}, [suggestedKeywordsInStore]);

	useEffect(() => {
		if (
			selectedKeywordsInStore === null ||
			selectedKeywordsInStore === undefined ||
			selectedKeywordsInStore?.length === 0
		) {
			setSelectedKeywords([]);
		} else {
			setSelectedKeywords(selectedKeywordsInStore);
		}
	}, [selectedKeywordsInStore]);

	return (
		<Box
			sx={{
				display: "flex",
				marginTop: "12em",
				flexDirection: "column",
			}}
		>
			<motion.div
				initial={{ opacity: 0, display: "hidden" }}
				animate={step > 4 ? { opacity: 1, display: "visible" } : {}}
				transition={{ duration: 1 }}
			>
				<Box
					style={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
					}}
				>
					<img
						src={LeftArrow}
						alt=""
						style={{
							position: "relative",
							top: "100px",
							left: "-15px",
							width: "16px",
							height: "29px",
						}}
					/>

					<StyledPopOverBox style={{ minHeight: "363px" }}>
						<Typography
							fontWeight={"bold"}
							fontFamily={"Inter"}
							fontSize={"20px"}
							color={"#303030"}
						>
							Suggested Keywords
						</Typography>

						<Divider style={{ minWidth: "70%", backgroundColor: "#303030" }} />

						<Box
							sx={{
								display: "flex",
								alignItems: "end",
								gap: "5px",
							}}
						>
							<Button
								style={{
									border: "1px solid #303030",
									padding: "0px 10px",
									minWidth: "112px",
									height: "23px",
									backgroundColor: "#F2F2F2",
									fontFamily: "Inter",
									fontWeight: 300,
									fontSize: "16px",
									color: "#A35C50",
									textTransform: "none",
								}}
								onClick={handleUnSelectAllKeywords}
							>
								Select None
							</Button>

							<Button
								style={{
									border: "1px solid #303030",
									padding: "0px 10px",
									minWidth: "112px",
									height: "23px",
									backgroundColor: "#F2F2F2",
									fontFamily: "Inter",
									fontWeight: 300,
									fontSize: "16px",
									color: "#303030",
									textTransform: "none",
								}}
								onClick={handleSelectAllKeywords}
							>
								Select All
							</Button>
						</Box>

						{loading ? (
							<>
								<Typography>Loading Suggested Keywords</Typography>
							</>
						) : (
							suggestedKeywordsView?.map((keyword, key) => {
								return (
									<Box
										key={key}
										sx={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											minHeight: "39px",
											width: "200px",
											backgroundColor: selectedKeywords?.some(
												(item) => item?.name === keyword?.name
											)
												? "#025131"
												: "transparent",
											border: selectedKeywords?.some(
												(item) => item?.name === keyword?.name
											)
												? "1px solid #025131"
												: "1px solid #000",
											color: selectedKeywords?.some(
												(item) => item?.name === keyword?.name
											)
												? "#fff"
												: "#303030",
											borderRadius: "4px",
											padding: "0px 10px",
											"&:hover": {
												backgroundColor: selectedKeywords?.some(
													(item) => item?.name === keyword?.name
												)
													? "#025131"
													: "#F2F2F2",
												color: selectedKeywords?.some(
													(item) => item?.name === keyword?.name
												)
													? "#fff"
													: "#000",
												cursor: "pointer",
											},
										}}
										onClick={() => {
											handleSelectKeyword(keyword);
										}}
									>
										<SelectionButton>{keyword?.name}</SelectionButton>

										<CloseIcon
											onClick={(e) => {
												e.stopPropagation();
												handleRemoveSuggestion(keyword);
											}}
										/>
									</Box>
								);
							})
						)}
					</StyledPopOverBox>
				</Box>
			</motion.div>
		</Box>
	);
};

export default SuggestedKeywordsColumn;
