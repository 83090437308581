import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useLazyQuery, gql } from "@apollo/client";
import NestedTable from "./NestedTable";

const DHF_COLUMNS = [
	{
		id: "tableOfContent",
		label: "DHF Table of Content",
		align: "left",
	},
	{
		id: "dueDate",
		label: "Due Date",
		width: 150,
		align: "center",
	},
	{
		id: "percentageComplete",
		label: "% Complete",
		width: 150,
		align: "center",
	},
];

const records = [
	{
		tableOfContent: "Design Planning",
		dueDate: "30 SEP 2023",
		percentageComplete: "0.0%",
		documents: [
			{
				name: "Document 1",
				dueDate: "20 SEP 2024",
				percentageComplete: "15.0%",
			},
		],
	},
	{
		tableOfContent: "Design Input",
		dueDate: "12 OCT 2023",
		percentageComplete: "0.0%",
		documents: [
			{
				name: "Document 2",
				dueDate: "18 OCT 2024",
				percentageComplete: "10.0%",
			},
		],
	},
	{
		tableOfContent: "Risk Management",
		dueDate: "07 FEB 2024",
		percentageComplete: "0.0%",
		documents: [
			{
				name: "Document 3",
				dueDate: "25 FEB 2024",
				percentageComplete: "5.0%",
			},
		],
	},
	{
		tableOfContent: "Usability Engineering",
		dueDate: "12 JUN 2024",
		percentageComplete: "0.0%",
		documents: [
			{
				name: "Document 4",
				dueDate: "30 JUN 2024",
				percentageComplete: "0.0%",
			},
		],
	},
	{
		tableOfContent: "Product Specification",
		dueDate: "26 MAR 2024",
		percentageComplete: "0.0%",
		documents: [
			{
				name: "Document 5",
				dueDate: "01 APR 2024",
				percentageComplete: "0.0%",
			},
		],
	},
	{
		tableOfContent: "Verification",
		dueDate: "30 JUN 2024",
		percentageComplete: "0.0%",
		documents: [
			{
				name: "Document 6",
				dueDate: "05 JUL 2024",
				percentageComplete: "0.0%",
			},
		],
	},
	{
		tableOfContent: "Validation",
		dueDate: "30 JUN 2024",
		percentageComplete: "0.0%",
		documents: [
			{
				name: "Document 7",
				dueDate: "10 JUL 2024",
				percentageComplete: "0.0%",
			},
		],
	},
	{
		tableOfContent: "Design Review",
		dueDate: "26 MAR 2024",
		percentageComplete: "0.0%",
		documents: [
			{
				name: "Document 8",
				dueDate: "15 APR 2024",
				percentageComplete: "0.0%",
			},
		],
	},
	{
		tableOfContent: "Design Changes",
		dueDate: "30 JUN 2024",
		percentageComplete: "0.0%",
		documents: [
			{
				name: "Document 9",
				dueDate: "01 AUG 2024",
				percentageComplete: "0.0%",
			},
		],
	},
	{
		tableOfContent: "Regulatory Information",
		dueDate: "26 OCT 2024",
		percentageComplete: "0.0%",
		documents: [
			{
				name: "Document 10",
				dueDate: "15 NOV 2024",
				percentageComplete: "0.0%",
			},
		],
	},
	{
		tableOfContent: "Device Lifecycle",
		dueDate: "24 DEC 2024",
		percentageComplete: "0.0%",
		documents: [
			{
				name: "Document 11",
				dueDate: "01 JAN 2025",
				percentageComplete: "0.0%",
			},
		],
	},
	{
		tableOfContent: "Device Master Record",
		dueDate: "24 DEC 2024",
		percentageComplete: "0.0%",
		documents: [
			{
				name: "Document 12",
				dueDate: "05 JAN 2025",
				percentageComplete: "0.0%",
			},
		],
	},
];

const DHFView = ({ handleWizardToggle, ideaId }) => {
	const [documentsData, setDocumentsData] = useState([]);

	const GET_DOCUMENTS_GQL = gql`
		query GetDocumentsData($ideaId: ID!) {
			idea(id: $ideaId) {
				dhfDocument {
					dueOn
					completionRate
					sections {
						id
						title
						dueOn
						completionRate
						templates {
							id
							title
							dueOn
							completionRate
						}
					}
				}
			}
		}
	`;

	const [getDocumentsData, { loading }] = useLazyQuery(GET_DOCUMENTS_GQL, {
		fetchPolicy: "no-cache",
	});

	const handleGetDocumentsData = () => {
		getDocumentsData({
			variables: {
				ideaId,
			},
		})
			.then((res) => {
				console.log(res?.data?.idea?.dhfDocument);
				setDocumentsData([res?.data?.idea?.dhfDocument]);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		handleGetDocumentsData();
	}, []);

	return (
		<Box
			sx={{
				display: "flex",
				padding: "0rem 4rem",
			}}
		>
			{loading ? (
				<Box
					sx={{
						display: "flex",
						height: "400px",
						width: "100%",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<CircularProgress />
				</Box>
			) : (
				<NestedTable
					view={"DHF"}
					columns={DHF_COLUMNS}
					records={documentsData}
					handleWizardToggle={handleWizardToggle}
				/>
			)}
		</Box>
	);
};

export default DHFView;
