import { Typography, Grid2 as Grid } from "@mui/material";
import PricingImg from "../../assets/pngs/pricing.png";

const Pricing = () => {

    return (
        <>
            <Grid container direction={"column"} spacing={2}>
                <Grid size={8}>
                    <Typography
                        fontWeight={"bold"}
                        fontFamily={"Inter"}
                        fontSize={"16px"}
                        color={"#303030"}
                    >
                        Pricing
                    </Typography>
                </Grid>

                <Grid container justifyContent="center" alignItems="center" mt={5}>
                    <img
                        src={PricingImg}
                        alt=""
                        style={{
                            width: '133px',
                            height: '133px',
                            objectFit: 'cover',
                        }} />
                </Grid>
            </Grid>
        </>
    )
}
export default Pricing;