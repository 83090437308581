import { Box, Typography } from "@mui/material";


const AdverseEvents = () => {

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Typography
                    fontWeight={"bold"}
                    fontFamily={"Inter"}
                    fontSize={"16px"}
                    color={"#303030"}
                >
                    Adverse Events
                </Typography>
            </Box>
        </>
    )
}
export default AdverseEvents;