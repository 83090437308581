import { gql } from "@apollo/client";


export const ADD_COMPANY_TO_IDEA = gql`
    mutation AddCompanyToIdea($ideaId: ID!, $companyId: ID!) {
        addCompanyToIdea(ideaId: $ideaId, companyId: $companyId) {
            id
        }
    }
`;

export const REMOVE_COMPANY_FROM_IDEA = gql`
    mutation RemoveCompanyFromIdea($ideaId: ID!, $companyId: ID!) {
        removeCompanyFromIdea(ideaId: $ideaId, companyId: $companyId) {
            id
        }
    }
`;