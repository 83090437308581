import { useState } from "react";
import ExploreGrid from "../components/explore/ExploreGrid";
import Summary from "../components/explore/Summary";

const Explore = () => {
	const [selectedView, setSelectedView] = useState("grid");

	return <ExploreGrid setSelectedView={setSelectedView} />;
};

export default Explore;
