import { Dialog, DialogContent, DialogTitle, Grid2 as Grid, Typography } from "@mui/material";
import CloseButton from "../../../assets/pngs/closeButton.png";
import GraphTree from "../../GraphTree/GraphTree";

const ProductCodeGraphDialog = ({ open, onClose, selectedProduct }) => {
    return (
        <Dialog fullWidth maxWidth={"xl"} open={open} onClose={onClose}>
            <DialogTitle>

                <Grid container direction={"row"} justifyContent={"space-between"}>
                    <Grid item>
                        <Typography
                            display="inline"
                            color="#038C5A"
                            fontSize={"40px"}
                            fontFamily={"Inter"}
                            fontWeight={"bold"}
                        >
                            <em>{selectedProduct?.code}</em>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <img
                            src={CloseButton}
                            alt="x"
                            width="30px"
                            height="30px"
                            style={{
                                marginRight: 15,
                                cursor: "pointer",
                            }}
                            onClick={onClose}
                        />
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction={"row"}>
                    <Grid sx={{ width: "100%", height: "calc(100vh - 12rem)" }}>
                        <GraphTree showFullScreenIcon={false} isFullScreen={true} fullHeight={true} initialGraphId={selectedProduct?.graphId} onExpandClick={() => { }} isProductDialog={true} />
                    </Grid>
                </Grid>

            </DialogContent>
        </Dialog>
    )
}
export default ProductCodeGraphDialog;