import { gql } from "@apollo/client";

export const UPDATE_IDEA_PM_GLOSSARY = gql`
    mutation UpdateIdea($updateIdeaId: ID!, $deviceName: String, $productModel: String, $shelfLife: String, $countries: [String]) {
        updateIdea(id: $updateIdeaId, deviceName: $deviceName, productModel: $productModel, shelfLife: $shelfLife, countries: $countries) {
            id
        }
    }
`;

export const UPDATE_ENGINEERING_GLOSSARY_GQL = gql`
    mutation UpdateIdea($updateIdeaId: ID!, $width: String, $height: String, $depth: String, $material: String) {
        updateIdea(id: $updateIdeaId, width: $width, height: $height, depth: $depth, material: $material) {
            material
        }
    }
`;