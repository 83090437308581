import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import DownloadIcon from "../../assets/pngs/downloadIcon.png";
import DHFView from "./DHFView";

const StyledTabButton = styled(Button)(({ isSelected, isSearch }) => ({
	height: "44px",
	width: "136px",
	backgroundColor: isSelected ? "#025131" : "transparent",
	fontFamily: "Inter",
	fontWeight: "bold",
	fontSize: "20px",
	color: isSelected ? "#fff" : "#303030",
	letterSpacing: 0,
	border: isSelected
		? "1px solid #025131"
		: isSearch
		? "1px solid #000"
		: "none",
	borderRadius: "4px",
	"&:hover": {
		backgroundColor: isSelected ? "#025131" : "#F2F2F2",
		color: isSelected ? "#fff" : "#000",
	},
	textTransform: "none",
}));

const DocumentsMain = ({ handleWizardToggle, ideaId }) => {
	const [currentView, setCurrentView] = useState("DHF");

	const handleSetView = (view) => {
		setCurrentView(view);
	};

	return (
		<>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					padding: "0rem 2.5rem",
				}}
			>
				<Box sx={{ display: "flex" }}>
					<Typography
						fontWeight={"bold"}
						fontFamily={"Inter"}
						fontSize={"20px"}
						color={"#303030"}
						display="inline"
					>
						Documents -&nbsp;
					</Typography>

					<Typography
						fontWeight={"bold"}
						fontFamily={"Inter"}
						fontSize={"20px"}
						color={"#038C5A"}
						display="inline"
					>
						Tissue Containment
					</Typography>
				</Box>

				<img src={DownloadIcon} alt="" width={"39px"} height={"39px"} />
			</Box>
			<Box
				sx={{
					display: "flex",
					gap: "40px",
					padding: "0rem 4rem",
				}}
			>
				<StyledTabButton
					onClick={() => {
						handleSetView("DHF");
					}}
					isSelected={currentView === "DHF"}
				>
					DHF View
				</StyledTabButton>
				<StyledTabButton
					onClick={() => {
						handleSetView("EStar");
					}}
					isSelected={currentView === "EStar"}
				>
					eStar View
				</StyledTabButton>
				<StyledTabButton isSearch>Search</StyledTabButton>
			</Box>

			{currentView === "DHF" ? (
				<DHFView handleWizardToggle={handleWizardToggle} ideaId={ideaId} />
			) : currentView === "EStar" ? (
				<></>
			) : (
				<></>
			)}
		</>
	);
};

export default DocumentsMain;
