import { useState, useEffect } from "react";
import { Grid2 as Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link, Box, CircularProgress } from "@mui/material";
import { GET_STANDARDS_COMPLIANCE_GQL } from "../../gql/queries/summary";
import { useLazyQuery } from "@apollo/client";
import { NotificationToaster } from "../../utils/NotificationToaster";

const StandardsCompliance = (props) => {
    const { ideaId } = props;

    const [standardsCompliance, setStandardsCompliance] = useState([]);

    const [fetchStandardsCompliance, { loading }] = useLazyQuery(
        GET_STANDARDS_COMPLIANCE_GQL,
        {
            fetchPolicy: 'no-cache'
        }
    );

    const fetchStandardsComplianceData = () => {
        fetchStandardsCompliance({
            variables: {
                ideaId
            },
        })
            .then((res) => {
                setStandardsCompliance(res?.data?.idea?.standards);
            })
            .catch((err) => {
                console.log(err);
                NotificationToaster({
                    message: err.message ?? "Something went wrong",
                    type: "error",
                });
            });
    }

    useEffect(() => {
        fetchStandardsComplianceData();
    }, []);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Typography
                    fontWeight={"bold"}
                    fontFamily={"Inter"}
                    fontSize={"16px"}
                    color={"#303030"}
                >
                    Standards Compliance
                </Typography>
            </Box>

            {
                loading ? (
                    <Grid
                        container
                        direction={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        <Grid
                            size={12}
                            textAlign={"center"}
                            sx={{
                                height: '100%',
                                alignItems: "center"
                            }}>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                ) : (
                    standardsCompliance?.length === 0 || standardsCompliance?.length === 0 ? (
                        <Grid container mt={2} direction={"column"} justifyContent={"center"} alignItems={"center"} sx={{ width: "100%", height: "100%" }}>
                            <Grid size={12} textAlign={"center"}>
                                No Records Found
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container sx={{ marginLeft: "30px", marginTop: "10px" }} spacing={1}>
                            <TableContainer component={Paper} elevation={0}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ border: '1px solid #ccc', backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Standard</TableCell>
                                            <TableCell style={{ border: '1px solid #ccc', backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>% Complete</TableCell>
                                            <TableCell style={{ border: '1px solid #ccc', backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Compliance Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ border: '1px solid #ccc' }}>Standard</TableCell>
                                            <TableCell sx={{ border: '1px solid #ccc' }}>
                                                <Typography style={{ color: 'orange', fontWeight: 'bold' }}>0.0%</Typography>
                                            </TableCell>
                                            <TableCell sx={{ border: '1px solid #ccc' }}>
                                                <Link href="#" underline="none" style={{ color: 'orange' }}>
                                                    Start with <em>Decision Tree</em>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    )
                )
            }
        </>
    )
}
export default StandardsCompliance;