import { gql, useLazyQuery } from "@apollo/client";
import { Box, Button, styled, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logo/logo.png";

const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "100vh",
  backgroundColor: "#fff",
});

const Header = styled(Box)({
  width: "100%",
  position: "fixed",
  top: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  height: 100,
  backgroundColor: "#f2f2f2",
  zIndex: 10,
});

const LogoWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 12,
  marginTop: "10px",
  padding: "0.3rem",
});

const ContentWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flex: 1,
  width: "100%",
  marginTop: "100px",
});

const CenteredLogoWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginBottom: 50,
  marginTop: -20,
});

const FormContainer = styled("form")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",

  gap: "1rem",
});

const StyledTextField = styled(TextField)({
  width: "100%",
});

const StyledTextFieldWithGap = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  maxWidth: 400,
  gap: "3rem",
});

const StyledButton = styled(Button)({
  width: "100%",
  maxWidth: 300,
  padding: "12px",
  fontSize: "1rem",
  background: "#025131",
});

const LoginPage = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  const CHECK_IF_HAS_ACCESS_GQL = gql`
    query ExampleQuery($options: OptionsInput) {
      ideas(options: $options) {
        id
      }
    }
  `;

  const [getUserAccess] = useLazyQuery(CHECK_IF_HAS_ACCESS_GQL);

  const checkIfUserHasAccess = () => {
    getUserAccess({
      variables: {
        options: {
          limit: 5,
        },
      },
    })
      .then((res) => {
        // console.log(res);
        if (res?.data) {
          // setIsUserLoggedIn(true);
          navigate("/Explore");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    checkIfUserHasAccess();
  }, []);

  return (
    <MainContainer>
      <Header>
        <LogoWrapper>
          <img src={Logo} alt="" height={"70px"} />
        </LogoWrapper>
      </Header>

      <ContentWrapper>
        <CenteredLogoWrapper>
          <img src={Logo} alt="" height={"70px"} />
        </CenteredLogoWrapper>

        <FormContainer>
          {/* <StyledTextFieldWithGap>
                  <StyledTextField
                      label="Username"
                      variant="outlined"
                      type="text"
                      placeholder="Enter your username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                  />

                  <StyledTextField
                      label="Password"
                      variant="outlined"
                      type="password"
                      placeholder="Enter your password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                  />
              </StyledTextFieldWithGap> */}
          <a
            style={{
              color: "#fff",
              textDecoration: "none",
              width: "100%",
              maxWidth: 300,
            }}
            href={`${process.env.REACT_APP_AUTH_BASE_URL}.auth/login/aad?post_login_redirect_uri=${document.location.origin}`}
            target="_self"
          >
            <StyledButton variant="contained">Login with Microsoft</StyledButton>
          </a>

          {/* 
            Temporary disabling Google access
            
            <a
              style={{
                color: "#fff",
                textDecoration: "none",
                width: "100%",
                maxWidth: 300,
              }}
              href={`${process.env.REACT_APP_AUTH_BASE_URL}/.auth/login/google?post_login_redirect_uri=${document.location.origin}`}
              target="_self"
            >
              <StyledButton variant="contained">Login with Google</StyledButton>
            </a> 
          */}
        </FormContainer>
      </ContentWrapper>
    </MainContainer>
  );
};

export default LoginPage;
