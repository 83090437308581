import React, { useEffect, lazy, Suspense, useState } from "react";

// Lazy loading the components
const RequirementsView = lazy(() => import("../components/TracerRequirements/RequirementsView"));
const RiskView = lazy(() => import("../components/RiskView/riskView"));

// Preload both components to prevent loading delays
RequirementsView.preload = () => import("../components/TracerRequirements/RequirementsView");
RiskView.preload = () => import("../components/RiskView/riskView");

function TracerSection({ activeView, dashedLines, openModal }) {
  // Preload both views when the component mounts
  useEffect(() => {
    RequirementsView.preload();
    RiskView.preload();
  }, []);

  // State to manage the component visibility, keeping both mounted
  const [showRequirements, setShowRequirements] = useState(activeView === "requirements");

  useEffect(() => {
    // Toggle between requirements and risk views
    setShowRequirements(activeView === "requirements");
  }, [activeView]);

  return (
    <div style={{ position: "relative", height: "100%", width: "100%" }}>
      <Suspense fallback={null}>
        {/* RequirementsView is always mounted but only visible when active */}
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            opacity: showRequirements ? 1 : 0,
            transition: "opacity 0.5s ease-in-out",
            pointerEvents: showRequirements ? "auto" : "none", // Prevent interaction when hidden
          }}
        >
          <RequirementsView openModal={openModal} />
        </div>

        {/* RiskView is always mounted but only visible when active */}
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            opacity: showRequirements ? 0 : 1,
            transition: "opacity 0.5s ease-in-out",
            pointerEvents: showRequirements ? "none" : "auto", // Prevent interaction when hidden
          }}
        >
          <RiskView dashedLines={dashedLines} />
        </div>
      </Suspense>
    </div>
  );
}

export default TracerSection;
