import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import {
	Button,
	Box,
	Typography,
	CircularProgress,
	Grid2 as Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "../../../assets/pngs/Plus.png";
import CheckIcon from "../../../assets/pngs/Check.png";
import { NotificationToaster } from "../../../utils/NotificationToaster";

const StyledButton = styled(Button)(({ isSelected }) => ({
	height: "44px",
	backgroundColor: isSelected ? "#025131" : "transparent",
	fontFamily: "Inter",
	fontWeight: "bold",
	fontSize: "20px",
	color: isSelected ? "#fff" : "#303030",
	letterSpacing: 0,
	border: isSelected ? "1px solid #025131" : "1px solid #000",
	borderRadius: "4px",
	"&:hover": {
		backgroundColor: isSelected ? "#025131" : "#F2F2F2",
		color: isSelected ? "#fff" : "#000",
	},
	textTransform: "none",
}));

const ListingColumn = ({
	step,
	selectedCategory,
	handleSelectedCategory,
	handleProductDialogOpen,
	handleFdaDialogOpen,
	handleCompanyDialogOpen,
}) => {
	const dispatch = useDispatch();

	const selectedKeywords = useSelector(
		(state) => state?.explore?.selectedKeywords
	);
	const ideaId = useSelector((state) => state?.explore?.idea?.id);

	const [categoryData, setCategoryData] = useState({
		regulations: [],
		productCodes: [],
		companies: [],
		standards: [],
		guidances: [],
	});
	const [addedStandards, setAddedStandards] = useState([]);
	const [addedGuidances, setAddedGuidances] = useState([]);

	const abortControllerRef = useRef(null);

	const getKeywordIds = () => {
		return selectedKeywords?.map((obj) => obj?.id);
	};

	const removeHtmlTags = (text) => {
		return text?.replace(/<.*?>/g, "");
	};

	const ADD_STANDARD_TO_SUMMARY_GQL = gql`
		mutation AddStandardToSummary($ideaId: ID!, $standardId: ID!) {
			addStandardToIdea(ideaId: $ideaId, standardId: $standardId) {
				id
			}
		}
	`;

	const [addStandardToSummary] = useMutation(ADD_STANDARD_TO_SUMMARY_GQL);

	const handleAddStandardToSummary = (id) => {
		addStandardToSummary({
			variables: {
				ideaId: ideaId,
				standardId: id,
			},
		})
			.then((res) => {
				console.log(res);
				NotificationToaster({
					message: "Standard added to summary",
					type: "success",
				});
				setAddedStandards((prevState) => [...prevState, { id: id }]);
			})
			.catch((err) => {
				NotificationToaster({
					message: "Standard could not be added to summary",
					type: "error",
				});
			});
	};

	const REMOVE_STANDARD_TO_SUMMARY_GQL = gql`
		mutation RemoveStandardFromSummary($ideaId: ID!, $standardId: ID!) {
			removeStandardFromIdea(ideaId: $ideaId, standardId: $standardId) {
				id
			}
		}
	`;

	const [removeStandardFromSummary] = useMutation(
		REMOVE_STANDARD_TO_SUMMARY_GQL
	);

	const handleRemoveStandardFromSummary = (id) => {
		removeStandardFromSummary({
			variables: {
				ideaId: ideaId,
				standardId: id,
			},
		})
			.then((res) => {
				NotificationToaster({
					message: "Standard removed from summary",
					type: "success",
				});

				setAddedStandards((prevState) =>
					prevState?.filter((obj) => obj?.id !== id)
				);
			})
			.catch((err) => {
				NotificationToaster({
					message: "Standard could not be removed from summary",
					type: "error",
				});
			});
	};

	const ADD_GUIDANCE_TO_SUMMARY_GQL = gql`
		mutation AddGuidanceToSummary($ideaId: ID!, $guidanceId: ID!) {
			addGuidanceToIdea(ideaId: $ideaId, guidanceId: $guidanceId) {
				id
			}
		}
	`;

	const [addGuidanceToSummary] = useMutation(ADD_GUIDANCE_TO_SUMMARY_GQL);

	const handleAddGuidanceToSummary = (id) => {
		addGuidanceToSummary({
			variables: {
				ideaId: ideaId,
				guidanceId: id,
			},
		})
			.then((res) => {
				console.log(res);
				NotificationToaster({
					message: "Guidance added to summary",
					type: "success",
				});
				setAddedGuidances((prevState) => [...prevState, { id: id }]);
			})
			.catch((err) => {
				NotificationToaster({
					message: "Guidance could not be added to summary",
					type: "error",
				});
			});
	};

	const REMOVE_GUIDANCE_FROM_SUMMARY_GQL = gql`
		mutation RemoveGuidanceFromSummary($ideaId: ID!, $guidanceId: ID!) {
			removeGuidanceFromIdea(ideaId: $ideaId, guidanceId: $guidanceId) {
				id
			}
		}
	`;

	const [removeGuidanceFromSummary] = useMutation(
		REMOVE_GUIDANCE_FROM_SUMMARY_GQL
	);

	const handleRemoveGuidanceFromSummary = (id) => {
		removeGuidanceFromSummary({
			variables: {
				ideaId: ideaId,
				guidanceId: id,
			},
		})
			.then((res) => {
				NotificationToaster({
					message: "Guidance removed from summary",
					type: "success",
				});

				setAddedGuidances((prevState) =>
					prevState?.filter((obj) => obj?.id !== id)
				);
			})
			.catch((err) => {
				NotificationToaster({
					message: "Guidance could not be removed from summary",
					type: "error",
				});
			});
	};

	const GET_REGULATION_DATA_GQL = gql`
		query GetRegulationData($ideaId: ID!, $ids: [ID!]) {
			idea(id: $ideaId) {
				suggestedKeywordsSummary(ids: $ids) {
					regulations {
						id
						title
						excerpt
						content {
							html
							suggestions {
								className
								color
								count
								name
							}
						}
					}
				}
			}
		}
	`;

	const [getRegulationData, { loading: getRegulationLoading }] = useLazyQuery(
		GET_REGULATION_DATA_GQL,
		{
			fetchPolicy: "no-cache",
		}
	);

	const GET_PRODUCT_DATA_GQL = gql`
		query GetProductCodeData($ideaId: ID!, $ids: [ID!]) {
			idea(id: $ideaId) {
				suggestedKeywordsSummary(ids: $ids) {
					productCodes {
						graphId
						code
						deviceClassCode
						deviceName
						products {
							id
							name
							graphId
						}
					}
				}
			}
		}
	`;

	const [getProductData, { loading: getProductCodeLoading }] = useLazyQuery(
		GET_PRODUCT_DATA_GQL,
		{
			fetchPolicy: "no-cache",
		}
	);

	const GET_COMPANIES_DATA_GQL = gql`
		query GetCompaniesData($ideaId: ID!, $ids: [ID!]) {
			idea(id: $ideaId) {
				suggestedKeywordsSummary(ids: $ids) {
					companies {
						id
						name
						graphId
						products {
							id
							name
						}
						financials {
							nodes {
								id
							}
							links {
								source
								target
								value
							}
						}
					}
				}
			}
		}
	`;

	const [getCompaniesData, { loading: getCompaniesLoading }] = useLazyQuery(
		GET_COMPANIES_DATA_GQL,
		{ fetchPolicy: "no-cache" }
	);

	const GET_STANDARDS_DATA_GQL = gql`
		query GetStandardsData($ideaId: ID!, $ids: [ID!]) {
			idea(id: $ideaId) {
				suggestedKeywordsSummary(ids: $ids) {
					standardsCount
					standards {
						id
						title
						designationNumberAndDate
						developingOrganization
						url
					}
				}
				standards {
					id
				}
			}
		}
	`;

	const [getStandardsData, { loading: getStandardsLoading }] = useLazyQuery(
		GET_STANDARDS_DATA_GQL,
		{
			fetchPolicy: "no-cache",
		}
	);

	const GET_GUIDANCES_DATA_GQL = gql`
		query GetguidancesData($ideaId: ID!, $ids: [ID!]) {
			idea(id: $ideaId) {
				suggestedKeywordsSummary(ids: $ids) {
					guidances {
						id
						title
					}
				}
				guidances {
					id
				}
			}
		}
	`;

	const [getGuidancesData, { loading: getGuidancesLoading }] = useLazyQuery(
		GET_GUIDANCES_DATA_GQL,
		{
			fetchPolicy: "no-cache",
		}
	);

	const fetchData = (query, variables) => {
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}

		abortControllerRef.current = new AbortController();

		return query({
			variables,
			context: {
				fetchOptions: {
					signal: abortControllerRef.current.signal,
				},
			},
		})
			.then((res) => {
				if (res?.data?.idea?.suggestedKeywordsSummary[selectedCategory]) {
					setCategoryData((prevState) => ({
						...prevState,
						[selectedCategory]: [
							...res?.data?.idea?.suggestedKeywordsSummary[selectedCategory],
						],
					}));
				}
			})
			.catch((err) => {
				if (err.name === "AbortError") {
					console.log("Fetch aborted");
				} else {
					console.log(err);
				}
			});
	};

	useEffect(() => {
		if (selectedCategory === "regulations") {
			getRegulationData({
				variables: {
					ideaId: ideaId,
					ids: getKeywordIds(),
				},
			})
				.then((res) => {
					setCategoryData((prevState) => ({
						...prevState,
						[selectedCategory]: [
							...res?.data?.idea?.suggestedKeywordsSummary[selectedCategory],
						],
					}));
				})
				.catch((err) => {
					console.log("err ", err);
				});
		} else if (selectedCategory === "productCodes") {
			getProductData({
				variables: {
					ideaId: ideaId,
					ids: getKeywordIds(),
				},
			})
				.then((res) => {
					setCategoryData((prevState) => ({
						...prevState,
						[selectedCategory]: [
							...res?.data?.idea?.suggestedKeywordsSummary[selectedCategory],
						],
					}));
				})
				.catch((err) => {
					console.log("err ", err);
				});
		} else if (selectedCategory === "companies") {
			getCompaniesData({
				variables: {
					ideaId: ideaId,
					ids: getKeywordIds(),
				},
			})
				.then((res) => {
					setCategoryData((prevState) => ({
						...prevState,
						[selectedCategory]: [
							...res?.data?.idea?.suggestedKeywordsSummary[selectedCategory],
						],
					}));
				})
				.catch((err) => {
					console.log("err ", err);
				});
		} else if (selectedCategory === "standards") {
			getStandardsData({
				variables: {
					ideaId: ideaId,
					ids: getKeywordIds(),
				},
			})
				.then((res) => {
					setCategoryData((prevState) => ({
						...prevState,
						[selectedCategory]: [
							...res?.data?.idea?.suggestedKeywordsSummary[selectedCategory],
						],
					}));
					setAddedStandards(res?.data?.idea?.standards);
				})
				.catch((err) => {
					console.log("err ", err);
				});
		} else if (selectedCategory === "guidances") {
			getGuidancesData({
				variables: {
					ideaId: ideaId,
					ids: getKeywordIds(),
				},
			})
				.then((res) => {
					setCategoryData((prevState) => ({
						...prevState,
						[selectedCategory]: [
							...res?.data?.idea?.suggestedKeywordsSummary[selectedCategory],
						],
					}));
					setAddedGuidances(res?.data?.idea?.guidances);
				})
				.catch((err) => {
					console.log("err ", err);
				});
		}
	}, []);

	useEffect(() => {
		if (selectedCategory) {
			if (selectedCategory === "regulations") {
				fetchData(getRegulationData, {
					ideaId: ideaId,
					ids: getKeywordIds(),
				});
			} else if (selectedCategory === "productCodes") {
				fetchData(getProductData, {
					ideaId: ideaId,
					ids: getKeywordIds(),
				});
			} else if (selectedCategory === "companies") {
				fetchData(getCompaniesData, {
					ideaId: ideaId,
					ids: getKeywordIds(),
				});
			} else if (selectedCategory === "standards") {
				fetchData(getStandardsData, {
					ideaId: ideaId,
					ids: getKeywordIds(),
				});
			} else if (selectedCategory === "guidances") {
				fetchData(getGuidancesData, {
					ideaId: ideaId,
					ids: getKeywordIds(),
				});
			}
		}
	}, [selectedCategory]);

	return (
		<Box
			sx={{
				display: "flex",
				marginTop: "2em",
				flexDirection: "column",
			}}
		>
			{getRegulationLoading ||
			getProductCodeLoading ||
			getCompaniesLoading ||
			getStandardsLoading ||
			getGuidancesLoading ? (
				<Box
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "calc(100vh - 200px)",
					}}
				>
					<CircularProgress />
				</Box>
			) : (
				<motion.div
					initial={{ opacity: 0, display: "hidden" }}
					animate={step > 6 ? { opacity: 1, display: "visible" } : {}}
					transition={{ duration: 1 }}
				>
					<Box
						style={{
							display: "flex",
							flexDirection: "column",
							width: "100%",
							gap: "10px",
						}}
					>
						<StyledButton
							isSelected
							style={{ width: "110px" }}
							onClick={() => {
								handleSelectedCategory(null);
								dispatch({
									type: "UPDATE_STEP",
									payload: 6,
								});
							}}
						>
							<ArrowBackIcon style={{ marginRight: 5 }} />
							Back
						</StyledButton>

						<Typography
							fontWeight={"bold"}
							fontFamily={"Inter"}
							fontSize={"20px"}
							color={"#303030"}
						>
							{selectedCategory === "regulations" ? (
								<>
									Suggested <span style={{ color: "#038C5A" }}>FDA</span>{" "}
									Regulations
								</>
							) : selectedCategory === "productCodes" ? (
								<>
									Suggested <span style={{ color: "#038C5A" }}>Product</span>{" "}
									Codes
								</>
							) : selectedCategory === "companies" ? (
								<>
									Suggested <span style={{ color: "#038C5A" }}>Companies</span>{" "}
									with <span style={{ color: "#038C5A" }}> 510(k)</span>
								</>
							) : selectedCategory === "standards" ? (
								<>
									Suggested <span style={{ color: "#038C5A" }}>Standards</span>
								</>
							) : selectedCategory === "guidances" ? (
								<>
									Suggested{" "}
									<span style={{ color: "#038C5A" }}>Guidance Docs.</span>
								</>
							) : (
								<></>
							)}
						</Typography>

						{selectedCategory === "regulations" ? (
							categoryData?.regulations?.map((regulation, key) => {
								return (
									<Box
										key={key}
										style={{
											display: "flex",
											flexDirection: "column",
											gap: "10px",
											cursor: "pointer",
										}}
										onClick={() => {
											handleFdaDialogOpen({
												id: key + 1,
												regulation: regulation,
											});
										}}
									>
										<Typography
											fontWeight={600}
											fontFamily={"Inter"}
											fontSize={"16px"}
											color={"#4BAAC8"}
										>
											{regulation?.id} : {removeHtmlTags(regulation?.title)}
										</Typography>

										<Typography
											fontWeight={300}
											fontFamily={"Inter"}
											fontSize={"12px"}
											color={"#303030"}
										>
											{removeHtmlTags(regulation?.excerpt)}
										</Typography>
									</Box>
								);
							})
						) : selectedCategory === "productCodes" ? (
							categoryData?.productCodes?.map((pCode, key) => {
								return (
									<Box
										key={key}
										style={{
											display: "flex",
											flexDirection: "column",
											gap: "10px",
											cursor: "pointer",
										}}
										onClick={() => {
											handleProductDialogOpen({ id: key + 1, product: pCode });
										}}
									>
										<Typography
											fontWeight={600}
											fontFamily={"Inter"}
											fontSize={"16px"}
											color={"#038C5A"}
										>
											<em>{pCode?.code}</em>
										</Typography>

										<Typography
											fontWeight={300}
											fontFamily={"Inter"}
											fontSize={"12px"}
											color={"#303030"}
										>
											{pCode?.productCode}
										</Typography>
									</Box>
								);
							})
						) : selectedCategory === "companies" ? (
							categoryData?.companies?.slice(0, 10)?.map((company, key) => {
								return (
									<Box
										key={key}
										style={{
											display: "flex",
											flexDirection: "column",
											gap: "10px",
											cursor: "pointer",
										}}
										onClick={() => {
											handleCompanyDialogOpen({
												id: key + 1,
												company: company,
											});
										}}
									>
										<Typography
											fontWeight={600}
											fontFamily={"Inter"}
											fontSize={"16px"}
											color={"#038C5A"}
										>
											<em>{company?.name}</em>
										</Typography>

										{company?.products?.map((product, key) => {
											return (
												<>
													<Box key={key}>
														<Typography
															fontWeight={"light"}
															fontFamily={"Inter"}
															fontSize={"14px"}
															color={"#303030"}
															display="inline"
														>
															Product #{key + 1}:&nbsp;
														</Typography>

														<Typography
															fontFamily={"Inter"}
															fontSize={"14px"}
															color={"#303030"}
															display="inline"
														>
															{product?.name}
														</Typography>
													</Box>
												</>
											);
										})}
									</Box>
								);
							})
						) : selectedCategory === "standards" ? (
							categoryData?.standards?.slice(0, 10)?.map((standard, key) => {
								return (
									<Grid
										container
										alignItems={"center"}
										justifyContent={"center"}
										spacing={2}
									>
										<Grid
											item
											size={{
												xs: 10,
											}}
										>
											<Box
												key={key}
												style={{
													display: "flex",
													flexDirection: "column",
													gap: "10px",
												}}
											>
												<Typography
													fontWeight={600}
													fontFamily={"Inter"}
													fontSize={"16px"}
													color={"#038C5A"}
												>
													{standard?.developingOrganization}{" "}
													{standard?.designationNumberAndDate} :{" "}
													<em>{standard?.title}</em>
												</Typography>

												<a
													href={standard?.url}
													target="_blank"
													rel="noreferrer"
													style={{ textDecoration: "none" }}
												>
													<Typography
														fontWeight={300}
														fontFamily={"Inter"}
														fontSize={"12px"}
														color={"#303030"}
														style={{
															wordBreak: "break-word",
														}}
													>
														{standard?.url}
													</Typography>
												</a>
											</Box>
										</Grid>

										<Grid
											item
											size={{
												xs: 2,
											}}
										>
											<img
												src={
													addedStandards?.some(
														(obj) => obj?.id === standard?.id
													)
														? CheckIcon
														: AddIcon
												}
												alt=""
												height={"auto"}
												width={"40px"}
												style={{
													cursor: "pointer",
												}}
												onClick={() => {
													addedStandards?.some(
														(obj) => obj?.id === standard?.id
													)
														? handleRemoveStandardFromSummary(standard?.id)
														: handleAddStandardToSummary(standard?.id);
												}}
											/>
										</Grid>
									</Grid>
								);
							})
						) : selectedCategory === "guidances" ? (
							categoryData?.guidances?.slice(0, 10)?.map((guidance, key) => {
								return (
									<Grid
										container
										alignItems={"center"}
										justifyContent={"center"}
										spacing={2}
										key={key}
									>
										<Grid
											item
											size={{
												xs: 10,
											}}
										>
											<Box
												key={key}
												style={{
													display: "flex",
													flexDirection: "column",
													gap: "10px",
												}}
											>
												<Typography
													fontWeight={600}
													fontFamily={"Inter"}
													fontSize={"16px"}
													color={"#038C5A"}
												>
													{guidance?.id} : <em>{guidance?.title}</em>
												</Typography>
											</Box>
										</Grid>

										<Grid
											item
											size={{
												xs: 2,
											}}
										>
											<img
												src={
													addedGuidances?.some(
														(obj) => obj?.id === guidance?.id
													)
														? CheckIcon
														: AddIcon
												}
												alt=""
												height={"auto"}
												width={"40px"}
												style={{
													cursor: "pointer",
												}}
												onClick={() => {
													addedGuidances?.some(
														(obj) => obj?.id === guidance?.id
													)
														? handleRemoveGuidanceFromSummary(guidance?.id)
														: handleAddGuidanceToSummary(guidance?.id);
												}}
											/>
										</Grid>
									</Grid>
								);
							})
						) : (
							<></>
						)}
					</Box>
				</motion.div>
			)}
		</Box>
	);
};

export default ListingColumn;
