import { Typography, Grid2 as Grid } from "@mui/material";
import ProductChartImg from "../../assets/pngs/productChart.png";

const ProductChart = ({ handleProductChartDialogOpen }) => {

    return (
        <>
            <Grid container direction={"column"} spacing={2}>
                <Grid size={8}>
                    <Typography
                        fontWeight={"bold"}
                        fontFamily={"Inter"}
                        fontSize={"16px"}
                        color={"#303030"}
                    >
                        Product Chart
                    </Typography>
                </Grid>

                <Grid
                    style={{
                        cursor: 'pointer'
                    }}
                    onClick={handleProductChartDialogOpen}
                >
                    <img
                        src={ProductChartImg}
                        alt=""
                        style={{
                            width: '100%',
                            height: 'auto',
                            objectFit: 'cover',
                        }} />
                </Grid>
            </Grid>
        </>
    )
}
export default ProductChart;