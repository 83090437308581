import React from 'react'
import DecisionTreeWizard from '../components/DecisionTreeWizard/DecisionTreeWizard'
const TracerDecision = () => {
  return (
    <>
     <DecisionTreeWizard/>
    </>
  )
}

export default TracerDecision
