import React from "react";
import { styled } from "@mui/material/styles";

const SwitchContainer = styled("label")({
  display: "inline-flex",
  alignItems: "center",
  width: "62px",
  position: "relative",
});

const SwitchTrack = styled("div")(({ theme }) => ({
  width: "100%",
  height: "32px",
  borderRadius: "16px",
  backgroundColor: "rgba(217, 217, 217, 1)",
  display: "flex",
  alignItems: "center",
  padding: "3px",
  transition: "background-color 0.2s",
}));

const SwitchThumb = styled("div")({
  width: "26px",
  height: "26px",
  borderRadius: "50%",
  backgroundColor: "rgba(255, 255, 255, 1)",
  transition: "transform 0.2s, background-color 0.2s",
  cursor: "pointer",
});

const HiddenCheckbox = styled("input")({
  type: "checkbox",
  cursor: "pointer",
  position: "absolute",
  opacity: 0,
  width: "62px",
  height: "32px",
  margin: 0,
  "&:checked + div": {
    backgroundColor: "rgba(3, 140, 90, 0.25)", // Custom green color when checked
  },
  "&:checked + div div": {
    transform: "translateX(30px)",
    backgroundColor: "#038C5A", // Thumb changes to green when checked
  },
});

function ToggleSwitch({ checked, onChange }) {
  return (
    <SwitchContainer>
      <HiddenCheckbox type="checkbox" checked={checked} onChange={onChange} />
      <SwitchTrack>
        <SwitchThumb />
      </SwitchTrack>
    </SwitchContainer>
  );
}

export default ToggleSwitch;
