import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, gql, useLazyQuery } from "@apollo/client";
import { Button, Box, TextField, Typography, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import TopArrow from "../../../assets/pngs/topArrow.png";
import { NotificationToaster } from "../../../utils/NotificationToaster";
import { useEffect } from "react";

const StyledButton = styled(Button)(({ isSelected }) => ({
	height: "44px",
	backgroundColor: isSelected ? "#025131" : "transparent",
	fontFamily: "Inter",
	fontWeight: "bold",
	fontSize: "20px",
	color: isSelected ? "#fff" : "#303030",
	letterSpacing: 0,
	border: isSelected ? "1px solid #025131" : "1px solid #000",
	borderRadius: "4px",
	"&:hover": {
		backgroundColor: isSelected ? "#025131" : "#F2F2F2",
		color: isSelected ? "#fff" : "#000",
	},
	textTransform: "none",
}));

const StyledTextField = styled(TextField)(() => ({
	height: "60px",
	minWidth: "300px",
	backgroundColor: "#fff",
	fontFamily: "Inter",
	fontWeight: "light",
	fontSize: "16px",
	"& .MuiFilledInput-root": {
		border: "1px solid #000",
		borderRadius: "4px",
		backgroundColor: "#fff",
		"&:hover": {
			backgroundColor: "#fff",
		},
	},
	"& label": {
		color: "#000000",
	},
	"& label.Mui-focused": {
		color: "#000000",
	},
	"&:hover label": {
		color: "#000000",
	},
}));

const StyledPopOverBox = styled(Box)(() => ({
	display: "flex",
	border: "1px solid #025131",
	borderRadius: "8px",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	minWidth: "297px",
	gap: "20px",
}));

const AddKeywordColumn = ({ step }) => {
	const dispatch = useDispatch();
	const ideaId = useSelector((state) => state?.explore?.idea?.id);
	const keywordName = useSelector((state) => state?.explore?.keyword);
	const suggestedKeywordsCount = useSelector(
		(state) => state?.explore?.suggestedKeywords?.length
	);
	const addedKeywordsLstInStore = useSelector(
		(state) => state?.explore?.addedKeywordsLst
	);

	const [keyword, setKeyword] = useState(keywordName || "");
	const [error, setError] = useState({ error: false, message: "" });
	const [sKeywordsCount, setSKeywordsCount] = useState(
		suggestedKeywordsCount || 0
	);
	const [addedKeywordsLst, setAddedKeywordsLst] = useState(
		addedKeywordsLstInStore || []
	);

	const ADD_KEYWORD_TO_IDEA_GQL = gql`
		mutation AddKeywordToIdea($ideaId: ID!, $name: String!) {
			addKeywordToIdea(ideaId: $ideaId, name: $name) {
				id
				name
			}
		}
	`;

	const [addKeywordToIdea, { loading }] = useMutation(ADD_KEYWORD_TO_IDEA_GQL);

	const GET_SUGGESTED_KEYWORDS_GQL = gql`
		query Ideas($id: ID!) {
			idea(id: $id) {
				suggestedKeywords {
					name
				}
			}
		}
	`;

	const [loadKeywords] = useLazyQuery(GET_SUGGESTED_KEYWORDS_GQL, {
		fetchPolicy: "no-cache",
	});

	const getSuggestedKeywords = () => {
		loadKeywords({ variables: { id: ideaId } })
			.then((res) => {
				dispatch({ type: "SUGGESTED_KEYWORDS_INIT" });
				dispatch({ type: "SELECTED_KEYWORDS_INIT" });
				dispatch({ type: "SUGGESTED_KEYWORDS_IN_VIEW_INIT" });

				dispatch({
					type: "SUGGESTED_KEYWORDS_SUCCESS",
					payload: res?.data?.idea?.suggestedKeywords,
				});

				setSKeywordsCount(res?.data?.idea?.suggestedKeywords?.length);

				dispatch({
					type: "UPDATE_STEP",
					payload: 4,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleAddKeyword = () => {
		const isValid = /^[a-zA-Z0-9 ]*$/.test(keyword);

		if (
			keyword?.length === 0 ||
			keyword === "" ||
			keyword === undefined ||
			keyword === null
		) {
			setError({ error: true, message: "Keyword field cannot be empty" });
		} else {
			if (addedKeywordsLst?.includes(keyword)) {
				setError({ error: true, message: "Duplicate Keyword cannot be added" });
			} else if (keyword?.trim() === "") {
				setError({ error: true, message: "Please enter a valid idea" });
			} else if (!isValid) {
				setError({ error: true, message: "No special chracters allowed" });
			} else {
				addKeywordToIdea({
					variables: {
						ideaId: ideaId,
						name: keyword,
					},
				})
					.then((res) => {
						dispatch({
							type: "NEW_KEYWORD_SUCCESS",
							payload: res?.data?.addKeywordToIdea?.name,
						});

						dispatch({
							type: "ADDED_KEYWORDS_LST_SUCCESS",
							payload: [...addedKeywordsLst, res?.data?.addKeywordToIdea?.name],
						});

						setAddedKeywordsLst((prevState) => [
							...prevState,
							res?.data?.addKeywordToIdea?.name,
						]);

						NotificationToaster({
							message: "Keyword added to idea",
							type: "success",
						});

						getSuggestedKeywords();
					})
					.catch((err) => {
						NotificationToaster({
							message: "Keyword could not be added to idea",
							type: "error",
						});
						console.error("Error adding keyword:", err);
					});
			}
		}
	};

	useEffect(() => {
		if (
			keywordName === null ||
			keywordName === undefined ||
			keywordName?.length === 0
		) {
			setKeyword(null);
			setAddedKeywordsLst([]);
		} else {
			setKeyword(keywordName);
		}
	}, [keywordName, addedKeywordsLstInStore]);

	return (
		<Box
			sx={{
				display: "flex",
				marginTop: "3.8em",
				flexDirection: "column",
				gap: "20px",
			}}
		>
			<motion.div
				initial={{ opacity: 0, display: "hidden" }}
				animate={step > 1 ? { opacity: 1, display: "visible" } : {}}
				transition={{ duration: 1 }}
			>
				<StyledButton
					isSelected={step >= 3}
					style={{
						width: "189px",
					}}
					onClick={() => {
						dispatch({
							type: "UPDATE_STEP",
							payload: 3,
						});
					}}
					disabled={loading}
				>
					+ Add Keyword
				</StyledButton>
			</motion.div>

			<motion.div
				initial={{ opacity: 0, display: "hidden" }}
				animate={step > 2 ? { opacity: 1, display: "visible" } : {}}
				transition={{ duration: 1 }}
			>
				<Box
					style={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
						gap: "20px",
					}}
				>
					<StyledTextField
						label="New Keyword"
						variant="filled"
						InputProps={{
							disableUnderline: true,
						}}
						inputProps={{
							maxLength: 30,
						}}
						onKeyDown={(e) => {
							e.keyCode === 13 && handleAddKeyword();
						}}
						value={keyword ?? ""}
						onChange={(e) => {
							setError({ error: false, message: "" });
							setKeyword(e.target.value);
						}}
						error={error?.error}
						helperText={error?.message}
						disabled={loading}
					/>

					<Box
						style={{
							display: "flex",
							justifyContent: "right",
						}}
					>
						<StyledButton
							style={{
								width: "86px",
							}}
							onClick={handleAddKeyword}
							disabled={loading}
						>
							Next
						</StyledButton>
					</Box>
				</Box>
			</motion.div>

			<motion.div
				initial={{ opacity: 0, display: "hidden" }}
				animate={step > 3 ? { opacity: 1, display: "visible" } : {}}
				transition={{ duration: 1 }}
			>
				<Box
					style={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
						marginTop: 15,
					}}
				>
					<StyledPopOverBox style={{ height: "149px" }}>
						<Typography
							fontWeight={"bold"}
							fontFamily={"Inter"}
							fontSize={"20px"}
							color={"#303030"}
						>
							Keywords
						</Typography>

						<Divider style={{ minWidth: "70%", backgroundColor: "#303030" }} />

						<Box
							sx={{
								display: "flex",
								alignItems: "center",
							}}
						>
							<Typography
								fontWeight={"bold"}
								fontFamily={"Inter"}
								fontSize={"16px"}
								color={"#303030"}
							>
								Suggested Keywords
							</Typography>

							<Box
								sx={{
									backgroundColor: "#025131",
									color: "#025131",
									borderRadius: "20px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									width: "auto",
									padding: "0px 8px",
									height: 30,
									marginLeft: 1,
									"&:hover": {
										cursor: "pointer",
									},
								}}
								onClick={() => {
									dispatch({
										type: "UPDATE_STEP",
										payload: 5,
									});
								}}
							>
								<Typography
									fontWeight={"bold"}
									fontFamily={"Inter"}
									fontSize={"20px"}
									color={"#fff"}
								>
									{sKeywordsCount}
								</Typography>
							</Box>
						</Box>
					</StyledPopOverBox>
					<img
						src={TopArrow}
						alt=""
						style={{
							position: "relative",
							top: "-166px",
							left: "20px",
							height: "16px",
							width: "29px",
						}}
					/>
				</Box>
			</motion.div>

			{addedKeywordsLst?.map((kWord, idx) => {
				return (
					<Typography
						key={idx}
						fontWeight={500}
						fontFamily={"Inter"}
						fontSize={"18px"}
						color={"#303030"}
					>
						{kWord}
					</Typography>
				);
			})}
		</Box>
	);
};

export default AddKeywordColumn;
