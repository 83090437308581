import { Grid2 } from "@mui/material";

const AddToSummaryButton = ({ isAdded = false, onClick }) => {
    return (
        <Grid2 sx={{ mt: 4, mb: 2, cursor: 'pointer' }}>
            {
                isAdded ? (
                    <img
                        onClick={onClick}
                        src={require("../../assets/pngs/addToSummaryButton.png")}
                        alt="Add to Summary"
                        width={"230px"}
                        height={"auto"}
                    />
                ) : (
                    <img
                        onClick={onClick}
                        src={require("../../assets/pngs/addToSummaryBtn.png")}
                        alt="Add to Summary"
                        width={"230px"}
                        height={"auto"}
                    />
                )
            }

        </Grid2>
    )
}
export default AddToSummaryButton;