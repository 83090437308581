import React from "react";
import { styled } from "@mui/material/styles";
import ViewButton from "./ViewButton";
import ToggleSwitch from "./ToggleSwitch";
import { Box } from "@mui/material";

const ViewToggleContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "30px",
  justifyContent: "flex-start",
  flexWrap: "wrap",
});

const ButtonGroup = styled(Box)({
  alignSelf: "stretch",
  display: "flex",
  minWidth: "240px",
  alignItems: "flex-start",
  gap: "28px",
  justifyContent: "flex-start",
  margin: "auto 0",
  fontWeight: 700,
  fontSize: "20px",
  fontFamily: "'Inter', sans-serif",
});

const SuggestionToggle = styled(Box)({
  alignSelf: "stretch",
  display: "flex",
  minWidth: "240px",
  alignItems: "center",
  gap: "15px",
  justifyContent: "flex-start",
  margin: "auto 0",
});

const SuggestionTextWrapper = styled(Box)({
  position: "relative",
  display: "inline-block",
  width: "max-content",
  height: "24px",
});

const Text = styled("span")(({ visible }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  opacity: visible ? 1 : 0,
  transition: "opacity 0.3s ease-in-out",
  color: "var(--havidea-grey-100-by-axel, #000)",
  fontVariantNumeric: "lining-nums tabular-nums",
  fontFamily: "'Inter', sans-serif",
  fontWeight: 400,
  fontSize: "20px",
  whiteSpace: "nowrap",
  textTransform: "capitalize",
}));

const ViewToggle = ({ activeView, onViewChange, checked, onChange }) => {
  return (
    <ViewToggleContainer>
      <ButtonGroup>
        <ViewButton
          active={activeView === "requirements"}
          isRequirements
          onClick={() => onViewChange("requirements")}
        >
          Req. View
        </ViewButton>
        <ViewButton
          active={activeView === "risk"}
          onClick={() => onViewChange("risk")}
        >
          Risk View
        </ViewButton>
      </ButtonGroup>
      <SuggestionToggle>
        <ToggleSwitch checked={checked} onChange={onChange} />
        <SuggestionTextWrapper>
          <Text visible={!checked}>Show Smart Suggestions</Text>
          <Text visible={checked}>Hide Smart Suggestions</Text>
        </SuggestionTextWrapper>
      </SuggestionToggle>
    </ViewToggleContainer>
  );
};

export default ViewToggle;
