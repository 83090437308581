import { gql } from "@apollo/client";


export const GET_IDEA_COMPANIES = gql`
    query fetchCompanies($ideaId: ID!) {
        idea(id: $ideaId) {
            companies {
                id
                name
                products {
                    id
                    name
                }
            }
        }
    }
`;

export const IS_COMPANY_ADDED_TO_IDEA = gql`
    query IsCompanyAddedToIdea($ideaId: ID!) {
        idea(id: $ideaId) {
            companies {
                id
            }
        }
    }
`;

export const COMPANY_STRUCTURE_INFO_GQL = gql`
    query Graph($ids: [ID!]!) {
        graph(ids: $ids) {
            links {
                source
                target
            }
            nodes {
                id
                type
                properties
            }
        }
    }
`;