import React, { useEffect, useState } from "react";
import { Grid2 as Grid, TextField, Typography, CircularProgress, Button } from "@mui/material";
import { useForm, Controller } from 'react-hook-form';
import CustomAutocompleteSelectField from "../shared/inputs/CustomAutocompleteSelectField";
// import {countriesList} from "../../dummyData/countries";
import { NotificationToaster } from "../../utils/NotificationToaster";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_PM_GLOSSARY_GQL } from "../../gql/queries/summary";
import { UPDATE_IDEA_PM_GLOSSARY } from "../../gql/mutations/ideas";

const ProductManagementGlossary = (props) => {
    const { ideaId } = props;

    const [fetchPMData, { loading }] = useLazyQuery(
        GET_PM_GLOSSARY_GQL,
        {
            fetchPolicy: 'no-cache'
        }
    );

    const [UpdateIdea, { loading: updatingPMGlossaryLoading }] = useMutation(
        UPDATE_IDEA_PM_GLOSSARY
    );

    const [countriesList, setCountriesList] = useState([{
        label: "USA",
        value: "USA"
    }]);
    const [defaultFormValues, setDefaultFormValues] = useState({});


    const {
        reset,
        control,
        handleSubmit,
    } = useForm({
        defaultValues: {
            deviceName: '',
            productModel: '',
            countries: [],
            shelfLife: '',
        }
    });

    const checkIfFormValuesChanged = (initialValues, currentValues) => {
        for (const key in initialValues) {
            if (initialValues[key] !== currentValues[key]) {
                return true;
            }
        }
        return false;
    };

    const onSubmit = (data) => {
        const payload = {
            deviceName: data.deviceName || '',
            productModel: data.productModel || '',
            shelfLife: data.shelfLife || ''
        }

        if (checkIfFormValuesChanged(defaultFormValues, payload)) {
            payload.countries = ["USA"]
            UpdateIdea({
                variables: {
                    updateIdeaId: ideaId,
                    ...payload,
                },
            })
                .then(() => {
                    delete payload.countries;
                    setDefaultFormValues(payload)
                    NotificationToaster({
                        message: "PM glossary updated successfully",
                        type: "success",
                    });
                })
                .catch((e) => {
                    NotificationToaster({
                        message: e.message ?? "Something went wrong",
                        type: "error",
                    });
                });
        }
    };

    const fetchPMGlossaryData = () => {
        fetchPMData({
            variables: {
                ideaId
            },
        })
            .then((response) => {
                const companiesResponse = response.data.idea ?? {};

                reset({
                    ...companiesResponse,
                    countries: [{ label: "USA", value: "USA" }]
                })

                // setCountriesList(companiesResponse.countries?.length > 0 ? companiesResponse.countries.map(item => {
                //     return {
                //         label: item,
                //         value: "US"
                //     }
                // }) : []);

                setDefaultFormValues({
                    deviceName: companiesResponse.deviceName || '',
                    productModel: companiesResponse.productModel || '',
                    shelfLife: companiesResponse.shelfLife || '',
                });

            })
            .catch((e) => {
                NotificationToaster({
                    message: e.message ?? "Something went wrong",
                    type: "error",
                });
            });
    }

    useEffect(() => {
        fetchPMGlossaryData();
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction={"column"} spacing={0} sx={{ maxWidth: "25rem" }}>
                <Grid size={8}>
                    <Typography
                        fontWeight={"bold"}
                        fontFamily={"Inter"}
                        fontSize={"16px"}
                        color={"#303030"}
                    >
                        Product Management Glossary
                    </Typography>
                </Grid>

                {
                    loading ? (
                        <Grid
                            container
                            direction={"column"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            sx={{
                                width: "100%",
                                height: "100%",
                                marginTop: 2
                            }}
                        >
                            <Grid
                                size={12}
                                textAlign={"center"}
                                sx={{
                                    height: '100%',
                                    alignItems: "center"
                                }}>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container direction={"column"} size={12} sx={{ ml: 4, mt: 2 }} spacing={1}>
                            <Grid
                                container
                                direction={"row"}
                                spacing={1}
                                alignItems={"center"}
                            >
                                <Grid size={4}>
                                    <Typography
                                        fontWeight={"light"}
                                        fontFamily={"Inter"}
                                        fontSize={"16px"}
                                        color={"#303030"}
                                    >
                                        Device Name:
                                    </Typography>
                                </Grid>

                                <Grid size={8}>
                                    <Controller
                                        name="deviceName"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                // onMouseLeave={() => onSubmit(getValues())}
                                                sx={{ margin: 0 }}
                                                size={"small"}
                                                {...field}
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                direction={"row"}
                                spacing={1}
                                alignItems={"center"}
                            >
                                <Grid size={4}>
                                    <Typography
                                        fontWeight={"light"}
                                        fontFamily={"Inter"}
                                        fontSize={"16px"}
                                        color={"#303030"}
                                    >
                                        Product Model:
                                    </Typography>
                                </Grid>

                                <Grid size={8}>
                                    <Controller
                                        name="productModel"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                // onMouseLeave={() => onSubmit(getValues())}
                                                sx={{ margin: 0 }}
                                                size={"small"}
                                                {...field}
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                direction={"row"}
                                spacing={1}
                                alignItems={"center"}
                            >
                                <Grid size={4}>
                                    <Typography
                                        fontWeight={"light"}
                                        fontFamily={"Inter"}
                                        fontSize={"16px"}
                                        color={"#303030"}
                                    >
                                        Country List:
                                    </Typography>
                                </Grid>

                                <Grid size={8}>
                                    <CustomAutocompleteSelectField
                                        control={control}
                                        name="countries"
                                        options={countriesList}
                                        valueKey={"value"}
                                        labelKey={"label"}
                                        size={"small"}
                                        multiple
                                    />

                                </Grid>
                            </Grid>

                            <Grid
                                container
                                direction={"row"}
                                spacing={1}
                                alignItems={"center"}
                            >
                                <Grid size={4}>
                                    <Typography
                                        fontWeight={"light"}
                                        fontFamily={"Inter"}
                                        fontSize={"16px"}
                                        color={"#303030"}
                                    >
                                        Shelf Life:
                                    </Typography>
                                </Grid>

                                <Grid size={8}>
                                    <Controller
                                        name="shelfLife"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                // onMouseLeave={() => onSubmit(getValues())}
                                                sx={{ margin: 0 }}
                                                size={"small"}
                                                {...field}
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
            </Grid>

            {
                !loading && (
                    <Grid
                        container
                        direction={"row"}
                        spacing={1}
                        alignItems={"center"}
                        justifyContent={"center"}
                        mt={2}
                    >
                        <Button
                            type="submit"
                            sx={{
                                width: '20px',
                                color: '#fff',
                                backgroundColor: '#025131'
                            }}
                            disabled={updatingPMGlossaryLoading}
                        >
                            Save
                        </Button>
                    </Grid>
                )
            }
        </form>
    )
}
export default ProductManagementGlossary;