import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { sankey as d3Sankey, sankeyLinkHorizontal } from "d3-sankey";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import OpenInFullOutlinedIcon from "@mui/icons-material/OpenInFullOutlined";
import CloseFullscreenSharpIcon from "@mui/icons-material/CloseFullscreenSharp";

const API_URL = process.env.REACT_APP_API_BASE_URL;

const CustomTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .MuiTooltip-tooltip`]: {
		backgroundColor: "#fff",
		color: "#000",
		fontSize: "1em",
		padding: "10px 15px",
		borderRadius: "8px",
		boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
	},
}));

const colorList = [
	{ node: "rgba(247, 154, 15, 1)", link: "#F79A0F80" },
	{ node: "rgba(29, 115, 184, 1)", link: "rgba(29, 115, 184, 0.25)" },
	{ node: "rgba(231, 16, 16, 1)", link: "rgba(231, 16, 16, 0.3)" },
	{ node: "rgba(20, 133, 93, 1)", link: "rgba(3, 140, 90, 0.25)" },
];

const SankeyChart = ({ isFullScreen, onExpandClick, financialData }) => {
	const svgRef = useRef();
	const containerRef = useRef();
	const [data, setData] = useState(null);
	const [isExpanded, setIsExpanded] = useState(isFullScreen || false);
	const [tooltipData, setTooltipData] = useState(null);

	const padding = isExpanded ? 40 : 0;
	const labelFontSize = isExpanded ? "15px" : "5px";
	const nodeWidth = isExpanded ? 30 : 10;
	const nodePadding = isExpanded ? 28 : 20;

	useEffect(() => {
		const nodes = financialData?.nodes.map((node) => ({
			id: node.id,
			name: node.id,
		}));

		const links = financialData?.links.map((link) => ({
			source: nodes.findIndex((n) => n.id === link.source),
			target: nodes.findIndex((n) => n.id === link.target),
			value: link.value,
		}));

		setData({ nodes, links });
	}, [financialData]);

	const renderSankey = () => {
		if (!data) return;

		const svg = d3.select(svgRef.current);
		svg.selectAll("*").remove();

		const width = containerRef.current.clientWidth - 2 * padding;
		const height = containerRef.current.clientHeight - 2 * padding;

		const sankey = d3Sankey()
			.nodeWidth(nodeWidth)
			.nodePadding(nodePadding)
			.extent([
				[padding, padding],
				[width + padding, height + padding],
			]);

		const { nodes, links } = sankey(data);

		nodes.forEach((node, index) => {
			const color = colorList[index % colorList.length];
			node.color = color.node;
			node.linkColor = color.link;
		});

		svg
			.append("g")
			.attr("fill", "none")
			.attr("stroke-opacity", 0.5)
			.selectAll("path")
			.data(links)
			.join("path")
			.attr("d", sankeyLinkHorizontal())
			.attr("stroke", (d) => nodes[d.source.index].linkColor)
			.attr("stroke-width", (d) => Math.max(1, d.width))
			.on("mouseenter", (event, d) => {
				setTooltipData({
					text: `Source: ${nodes[d.source.index].name} ➔ Target: ${
						nodes[d.target.index].name
					}\nValue: ${d.value}`,
					x: event.clientX,
					y: event.clientY,
				});
			})
			.on("mouseleave", () => setTooltipData(null));

		svg
			.append("g")
			.selectAll("rect")
			.data(nodes)
			.join("rect")
			.attr("x", (d) => d.x0)
			.attr("y", (d) => d.y0)
			.attr("height", (d) => d.y1 - d.y0)
			.attr("width", (d) => d.x1 - d.x0)
			.attr("fill", (d) => d.color);

		// Add labels with line breaks and bold font
		svg
			.append("g")
			.style("font", `${labelFontSize} sans-serif`)
			.style("font-weight", "bold") // Make labels bold
			.selectAll("text")
			.data(nodes)
			.join("text")
			.attr("x", (d) => (d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6))
			.attr("y", (d) => (d.y0 + d.y1) / 2)
			.attr("dy", "-0.5em")
			.attr("text-anchor", (d) => (d.x0 < width / 2 ? "start" : "end"))
			.each(function (d) {
				const words = d.name.split(" ");
				d3.select(this)
					.selectAll("tspan")
					.data(words)
					.join("tspan")
					.attr("x", d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6)
					.attr("dy", (d, i) => (i ? "1em" : 0))
					.text((word) => word);
			});
	};

	useEffect(() => {
		renderSankey();
		window.addEventListener("resize", renderSankey);
		return () => window.removeEventListener("resize", renderSankey);
	}, [data, isExpanded]);

	const handleExpandClick = () => {
		setIsExpanded((prev) => !prev);
		if (onExpandClick) onExpandClick(!isExpanded);
	};

	return (
		<div
			ref={containerRef}
			style={{
				position: "relative",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				width: isExpanded ? "100%" : "266px",
				height: isExpanded ? "100%" : "195px",
				backgroundColor: isExpanded ? "#fff" : "#fff",
				top: isExpanded ? 0 : "auto",
				left: isExpanded ? 0 : "auto",
				right: isExpanded ? 0 : "auto",
				bottom: isExpanded ? 0 : "auto",
				// zIndex: isExpanded ? 1 : "auto",
			}}
		>
			<svg ref={svgRef} width="100%" height="100%"></svg>
			{tooltipData && (
				<CustomTooltip
					title={
						<span style={{ fontSize: "1.2em", whiteSpace: "pre-line" }}>
							{tooltipData.text}
						</span>
					}
					open={Boolean(tooltipData)}
					placement="top"
					style={{
						position: "fixed",
						top: tooltipData.y,
						left: tooltipData.x,
						pointerEvents: "none",
						zIndex: 1000,
					}}
				>
					<div />
				</CustomTooltip>
			)}
			<button
				onClick={handleExpandClick}
				style={{
					position: "absolute",
					top: "10px",
					right: "10px",
					background: "transparent",
					border: "none",
					color: "#000",
					cursor: "pointer",
					fontSize: "20px",
					zIndex: 1001,
				}}
			>
				{isExpanded ? <CloseFullscreenSharpIcon /> : <OpenInFullOutlinedIcon />}
			</button>
		</div>
	);
};

export default SankeyChart;
