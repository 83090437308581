import { gql } from "@apollo/client";

export const TREE_GRAPH = gql`
    query Graph($ids: [ID!]!, $labels: [String!], , $length: LengthInput) {
        graph(ids: $ids, labels: $labels, length: $length) {
            links {
                source
                target
            }
            nodes {
                id
                type
                properties
            }
        }
    }
`;