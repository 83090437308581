import { gql, useMutation } from "@apollo/client";
import { AppBar, Box, Button, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../assets/logo/logo.png";
import { ReactComponent as AvatarPlaceholder } from "../../assets/placeholders/avatar.svg";
import { ToggleContext } from "../../contexts/ToggleContext";
import { NotificationToaster } from "../../utils/NotificationToaster";
import ViewToggle from "./ViewToggle/ViewToggle";

const StyledButton = styled(Button)(({ isSelected }) => ({
  height: "44px",
  backgroundColor: isSelected ? "#025131" : "transparent",
  fontFamily: "Inter",
  fontWeight: "bold",
  fontSize: "20px",
  color: isSelected ? "#fff" : "#303030",
  letterSpacing: 0,
  border: isSelected ? "1px solid #025131" : "1px solid #000",
  borderRadius: "4px",
  "&:hover": {
    backgroundColor: isSelected ? "#025131" : "#F2F2F2",
    color: isSelected ? "#fff" : "#000",
  },
  textTransform: "none",
}));

const Navbar = ({ showViewToggle, onViewChange, activeView }) => {
  const dispatch = useDispatch();
  const { showDashedLines, setShowDashedLines } = useContext(ToggleContext);

  const ideaId = useSelector((state) => state?.explore?.idea?.id);

  const handleToggleChange = (event) => {
    setShowDashedLines(event.target.checked);
  };

  const CLEAR_IDEA_MUTATION_GQL = gql`
    mutation DeleteIdea($deleteIdeaId: ID!) {
      deleteIdea(id: $deleteIdeaId) {
        id
      }
    }
  `;

  const [clearIdeaMutation] = useMutation(CLEAR_IDEA_MUTATION_GQL);

  const handleClearIdea = () => {
    clearIdeaMutation({
      variables: {
        deleteIdeaId: ideaId,
      },
    })
      .then((res) => {
        dispatch({ type: "IDEA_INIT" });
        dispatch({ type: "KEYWORD_INIT" });
        dispatch({ type: "ADDED_KEYWORDS_LST_INIT" });
        dispatch({ type: "SUGGESTED_KEYWORDS_INIT" });
        dispatch({ type: "SELECTED_KEYWORDS_INIT" });
        dispatch({ type: "SUGGESTED_KEYWORDS_IN_VIEW_INIT" });
        dispatch({ type: "SET_CATEGORY_COUNTS_INIT" });
        dispatch({ type: "SET_CATEGORY_DATA_INIT" });
        dispatch({ type: "SELECTED_CATEGORY_INIT" });
        dispatch({ type: "GET_STEP_INIT" });
      })
      .catch((err) => {
        console.log(err);

        NotificationToaster({
          message: "Something went wrong",
          type: "error",
        });
      });
  };

  return (
    <AppBar
      sx={{
        backgroundColor: "#F2F2F2",
        height: "100px",
        zIndex: 2, // Override default AppBar z-index
      }}
      elevation={0}
      position="sticky"
    >
      <Toolbar sx={{ padding: 0 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "3rem",
              alignItems: "center",
              width: "100%",
              padding: "10px",
            }}
          >
            {/* Application Logo */}
            <img src={Logo} alt={""} width={"110px"} />

            {showViewToggle && (
              <ViewToggle
                activeView={activeView} // Pass active view state
                onViewChange={onViewChange} // Handle view switching
                checked={showDashedLines}
                onChange={handleToggleChange} // Handle dashed lines toggle
              />
            )}
          </Box>

          {ideaId && (
            <StyledButton
              style={{
                width: "149px",
                marginRight: "20px",
              }}
              onClick={() => {
                handleClearIdea();
              }}
            >
              Clear Idea
            </StyledButton>
          )}

          {/* Avatar Placeholder */}
          <a
            href={`${process.env.REACT_APP_AUTH_BASE_URL}.auth/logout?post_logout_redirect_uri=${document.location.origin}`}
            target="_self"
          >
            <AvatarPlaceholder width={"80"} height={"80px"} />
          </a>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
