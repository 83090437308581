import { useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import DocumentsMain from "../components/Documents/DocumentsMain";
import DocumentWizard from "../components/Documents/DocumentWizard";

const Documents = () => {
	const ideaId = useSelector((state) => state?.explore?.idea?.id);

	const [isWizardView, setIsWizardView] = useState(false);

	const handleWizardToggle = () => {
		setIsWizardView((prevState) => !prevState);
	};

	return (
		<Box
			sx={{
				display: "flex",
				flex: 1,
				padding: "3rem 3rem",
				gap: "20px",
				flexDirection: "column",
			}}
		>
			{isWizardView ? (
				<DocumentWizard handleWizardToggle={handleWizardToggle} />
			) : (
				<DocumentsMain
					handleWizardToggle={handleWizardToggle}
					ideaId={ideaId}
				/>
			)}
		</Box>
	);
};

export default Documents;
