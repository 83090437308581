import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Box, Grid2 as Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ProductManagementGlossary from "../Summary/ProductManagementGlossary";
import PredicateDevicesTable from "../Summary/PredicateDevicesTable";
import RegulatoryQualityGlossary from "../Summary/RegulatoryQualityGlossary";
import CompetitorsList from "../Summary/CompetitorsList";
import EngineeringGlossary from "../Summary/EngineeringGlossary";
import CompanyDialog from "./dialogs/CompanyDialog";
import CompetitorSpecs from "../Summary/CompetitorSpecs";
import AdverseEvents from "../Summary/AdverseEvents";
import StandardsAndGuidelines from "../Summary/StandardsAndGuidelines";
import Predicate from "../Summary/Predicate";
import ProductChart from "../Summary/ProductChart";
import ProductChartDialog from "../Summary/ProductChartDialog";
import MarketShare from "../Summary/MarketShare";
import Pricing from "../Summary/Pricing";
import StandardsCompliance from "../Summary/StandardsCompliance";
import SystemEngineering from "../Summary/SystemEngineering";
import JiraIntegration from "../Summary/JiraIntegration";
import PatentsAndIp from "../Summary/PatentsAndIP";
import TestEngineering from "../Summary/TestEngineering";

const StyledButton = styled(Button)(({ isSelected }) => ({
	height: "44px",
	backgroundColor: isSelected ? "#025131" : "transparent",
	fontFamily: "Inter",
	fontWeight: "bold",
	fontSize: "20px",
	color: isSelected ? "#fff" : "#303030",
	letterSpacing: 0,
	border: isSelected ? "1px solid #025131" : "1px solid #000",
	borderRadius: "4px",
	"&:hover": {
		backgroundColor: isSelected ? "#025131" : "#F2F2F2",
		color: isSelected ? "#fff" : "#000",
	},
	textTransform: "none",
	padding: 12,
}));

const StyledCardBox = styled(Box)(() => ({
	borderRadius: "5px",
	border: "1px solid #145BE6",
	padding: "10px 20px",
	minHeight: "280px",
}));

const Summary = ({ setSelectedView }) => {
	const ideaId = useSelector((state) => state?.explore?.idea?.id);

	const [openCompanyDialog, setOpenCompanyDialog] = useState(false);
	const [companyData, setCompanyData] = useState(null);

	const [openProductChartDialog, setOpenProductChartDialog] = useState(false);

	const handleCompanyDialogOpen = (companyData) => {
		setCompanyData(companyData);
		setOpenCompanyDialog(true);
	};

	const handleCompanyDialogClose = () => {
		setOpenCompanyDialog(false);
		setCompanyData(null);
	};

	const handleProductChartDialogOpen = () => {
		setOpenProductChartDialog(true);
	};

	const handleProductChartDialogClose = () => {
		setOpenProductChartDialog(false);
	};

	return (
		<>
			{ideaId ? (
				<>
					<Grid container p={"3rem 2.5rem"} spacing={2}>
						<Grid size={12}>
							{/* <StyledButton
						isSelected
						style={{ height: "34px" }}
						onClick={() => setSelectedView("grid")}
					>
						<ArrowBackIcon /> &nbsp;Back
					</StyledButton> */}

							<Typography
								fontWeight={"bold"}
								fontFamily={"Inter"}
								fontSize={"20px"}
								color={"#303030"}
								mt={1.5}
							>
								Summary
							</Typography>
						</Grid>

						<Grid size={{ xs: 12, sm: 12, md: 12, lg: 6 }}>
							<StyledCardBox>
								<RegulatoryQualityGlossary ideaId={ideaId} />
							</StyledCardBox>
						</Grid>

						<Grid size={{ xs: 12, sm: 12, md: 12, lg: 6 }}>
							<StyledCardBox>
								<PredicateDevicesTable ideaId={ideaId} />
							</StyledCardBox>
						</Grid>

						<Grid size={{ xs: 12, sm: 12, md: 12, lg: 4 }}>
							<StyledCardBox style={{ border: "1px solid #C8C8C8" }}>
								<AdverseEvents />
							</StyledCardBox>
						</Grid>

						<Grid size={{ xs: 12, sm: 12, md: 12, lg: 4 }}>
							<StyledCardBox>
								<StandardsAndGuidelines ideaId={ideaId} />
							</StyledCardBox>
						</Grid>

						<Grid size={{ xs: 12, sm: 12, md: 12, lg: 4 }}>
							<StyledCardBox style={{ border: "1px solid #C8C8C8" }}>
								<Predicate />
							</StyledCardBox>
						</Grid>

						<Grid size={{ xs: 12, sm: 12, md: 12, lg: 6 }}>
							<StyledCardBox>
								<ProductManagementGlossary ideaId={ideaId} />
							</StyledCardBox>
						</Grid>

						<Grid size={{ xs: 12, sm: 12, md: 12, lg: 6 }}>
							<StyledCardBox>
								<CompetitorsList
									ideaId={ideaId}
									handleCompanyDialogOpen={handleCompanyDialogOpen}
								/>
							</StyledCardBox>
						</Grid>

						<Grid size={{ xs: 12, sm: 12, md: 12, lg: 3 }}>
							<StyledCardBox>
								<ProductChart
									handleProductChartDialogOpen={handleProductChartDialogOpen}
								/>
							</StyledCardBox>
						</Grid>

						<Grid size={{ xs: 12, sm: 12, md: 12, lg: 3 }}>
							<StyledCardBox style={{ border: "1px solid #C8C8C8" }}>
								<MarketShare />
							</StyledCardBox>
						</Grid>

						<Grid size={{ xs: 12, sm: 12, md: 12, lg: 3 }}>
							<StyledCardBox style={{ border: "1px solid #C8C8C8" }}>
								<Pricing />
							</StyledCardBox>
						</Grid>

						<Grid size={{ xs: 12, sm: 12, md: 12, lg: 3 }}>
							<StyledCardBox>
								<CompetitorSpecs ideaId={ideaId} />
							</StyledCardBox>
						</Grid>

						<Grid size={{ xs: 12, sm: 12, md: 12, lg: 6 }}>
							<StyledCardBox>
								<EngineeringGlossary ideaId={ideaId} />
							</StyledCardBox>
						</Grid>

						<Grid size={{ xs: 12, sm: 12, md: 12, lg: 6 }}>
							<StyledCardBox style={{ border: "1px solid #C8C8C8" }}>
								<StandardsCompliance ideaId={ideaId} />
							</StyledCardBox>
						</Grid>

						<Grid size={{ xs: 12, sm: 12, md: 12, lg: 3 }}>
							<StyledCardBox style={{ border: "1px solid #C8C8C8" }}>
								<SystemEngineering />
							</StyledCardBox>
						</Grid>

						<Grid size={{ xs: 12, sm: 12, md: 12, lg: 3 }}>
							<StyledCardBox style={{ border: "1px solid #C8C8C8" }}>
								<PatentsAndIp />
							</StyledCardBox>
						</Grid>

						<Grid size={{ xs: 12, sm: 12, md: 12, lg: 3 }}>
							<StyledCardBox style={{ border: "1px solid #C8C8C8" }}>
								<JiraIntegration />
							</StyledCardBox>
						</Grid>

						<Grid size={{ xs: 12, sm: 12, md: 12, lg: 3 }}>
							<StyledCardBox style={{ border: "1px solid #C8C8C8" }}>
								<TestEngineering />
							</StyledCardBox>
						</Grid>
					</Grid>

					<CompanyDialog
						companyData={companyData}
						open={openCompanyDialog}
						handleClose={handleCompanyDialogClose}
						isSummary={true}
					/>

					<ProductChartDialog
						open={openProductChartDialog}
						handleClose={handleProductChartDialogClose}
					/>
				</>
			) : (
				<Grid container p={"3rem 2.5rem"} spacing={2}>
					<Grid size={12}>
						{/* <StyledButton
						isSelected
						style={{ height: "34px" }}
						onClick={() => setSelectedView("grid")}
					>
						<ArrowBackIcon /> &nbsp;Back
					</StyledButton> */}

						<Typography
							fontWeight={"bold"}
							fontFamily={"Inter"}
							fontSize={"20px"}
							color={"#303030"}
							mt={1.5}
						>
							Summary
						</Typography>

						<Typography
							fontWeight={500}
							fontFamily={"Inter"}
							fontSize={"22px"}
							color={"#303030"}
							mt={1.5}
						>
							Please Create an Idea
						</Typography>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default Summary;
