import { Button, CircularProgress, Grid2 as Grid, Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NotificationToaster } from "../../utils/NotificationToaster";
import { GET_PREDICATE_DEVICES_GQL } from "../../gql/queries/summary";
import { useLazyQuery, useMutation } from "@apollo/client";
import { REMOVE_PRODUCT_FROM_IDEA } from "../../gql/mutations/products";
import { CONFIRM_PRODUCT_STATUS_GQL, UNCONFIRM_PRODUCT_STATUS_GQL } from "../../gql/mutations/products";

const HeaderCell = styled(Grid)(() => ({
    backgroundColor: "#F2F2F2",
    fontWeight: "bold",
    fontFamily: "Inter",
    fontSize: "10px",
    textAlign: "center",
    width: "120px",
    minWidth: "12em",
    padding: 0,
    height: "30px",
    border: "1px solid #888",
    alignContent: 'center'
}));

const StatusButton = styled(Button)({
    color: "#fff",
    textTransform: "none",
    fontWeight: 500,
    fontFamily: "Inter",
    fontSize: "10px",
    fontStyle: "italic",
    borderRadius: "15px",
    height: "15px",
    width: "90px",
    padding: 0,
});

const StatusCell = styled(Grid)({
    backgroundColor: "#C0E2D6",
    fontWeight: "bold",
    fontFamily: "Inter",
    fontSize: "10px",
    textAlign: "center",
    width: "120px",
    minWidth: "12em",
    padding: 0,
    height: "30px",
    border: "1px solid #888",
    alignContent: 'center'
});

const StyledTableCell = styled(Grid)({
    fontFamily: "Inter",
    fontWeight: 500,
    fontStyle: "italic",
    fontSize: "10px",
    // width: "120px",
    // minWidth: "12em",
    padding: "0px 10px",
    height: "30px",
    border: "0.2px solid #888",
    lineHeight: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap'
});

const PredicateDevicesTable = (props) => {
    const { ideaId } = props;
    const dispatch = useDispatch();

    const [predicateDevices, setPredicateDevices] = useState([]);

    const [removePredicateDevice, { loading: removeProductLoading }] = useMutation(REMOVE_PRODUCT_FROM_IDEA);
    const [confirmPredicateDevice, { loading: confirmPredicateDeviceLoading }] = useMutation(CONFIRM_PRODUCT_STATUS_GQL);
    const [unconfirmPredicateDevice, { loading: unconfirmPredicateDeviceLoading }] = useMutation(UNCONFIRM_PRODUCT_STATUS_GQL);

    const handleStatusChange = (productId, productStatus) => {
        if (productStatus === "NEW_CHANGES") {
            confirmPredicateDevice({
                variables: {
                    ideaId: ideaId,
                    productId: productId
                }
            })
                .then((res) => {
                    let updatedPredicateDevices = predicateDevices;
                    updatedPredicateDevices = updatedPredicateDevices?.map((obj) => {
                        return (
                            obj?.id === productId ?
                                { ...obj, status: res?.data?.confirmProductInIdea?.status }
                                : obj
                        )
                    })

                    setPredicateDevices(updatedPredicateDevices);
                    dispatch({
                        type: "PREDICATE_DEVICES_SUCCESS",
                        payload: updatedPredicateDevices,
                    });
                })
                .catch((err) => {
                    NotificationToaster({
                        message: "Predicate Device status could not be confirmed",
                        type: "error",
                    });
                })
        } else if (productStatus === "CONFIRMED") {
            unconfirmPredicateDevice({
                variables: {
                    ideaId: ideaId,
                    productId: productId
                }
            })
                .then((res) => {
                    let updatedPredicateDevices = predicateDevices;
                    updatedPredicateDevices = updatedPredicateDevices?.map((obj) => {
                        return (
                            obj?.id === productId ?
                                { ...obj, status: res?.data?.unconfirmProductInIdea?.status }
                                : obj
                        )
                    })

                    setPredicateDevices(updatedPredicateDevices);
                    dispatch({
                        type: "PREDICATE_DEVICES_SUCCESS",
                        payload: updatedPredicateDevices,
                    });
                })
                .catch((err) => {
                    NotificationToaster({
                        message: "Predicate Device status could not be unconfirmed",
                        type: "error",
                    });
                })
        } else {
            NotificationToaster({
                message: "Unkown Status Receieved",
                type: "error",
            });
        }
    }

    const handleRemovePredicateDevice = (row) => {
        removePredicateDevice({
            variables: {
                ideaId,
                productId: row?.id,
            }
        })
            .then((res) => {
                setPredicateDevices((prevState) => prevState?.filter(item => item?.id !== row?.id));
                dispatch({
                    type: "PREDICATE_DEVICES_SUCCESS",
                    payload: predicateDevices?.filter(item => item?.id !== row?.id),
                });
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const [fetchPredicateDevices, { loading }] = useLazyQuery(
        GET_PREDICATE_DEVICES_GQL,
        {
            fetchPolicy: 'no-cache'
        }
    );

    const fetchPredicateDevicesData = () => {
        fetchPredicateDevices({
            variables: {
                ideaId
            },
        })
            .then((res) => {
                setPredicateDevices(res?.data?.idea?.products);
                dispatch({
                    type: "PREDICATE_DEVICES_SUCCESS",
                    payload: res?.data?.idea?.products,
                });
            })
            .catch((err) => {
                console.log(err);
                NotificationToaster({
                    message: err.message ?? "Something went wrong",
                    type: "error",
                });
            });
    }

    useEffect(() => {
        fetchPredicateDevicesData();
    }, []);

    return (
        <Grid container direction={"column"} spacing={2}>
            <Grid>
                <Typography
                    fontWeight={"bold"}
                    fontFamily={"Inter"}
                    fontSize={"16px"}
                    color={"#303030"}
                >
                    Predicate Devices
                </Typography>
            </Grid>
            {
                loading || removeProductLoading || confirmPredicateDeviceLoading || unconfirmPredicateDeviceLoading ? (
                    <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"}>
                        <Grid>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                ) : (
                    predicateDevices?.length === 0 ? (
                        <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"} sx={{ width: '100%', maxHeight: "16em" }}>
                            <Grid>
                                No Records Found
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            container
                            direction={"row"}
                            wrap={"nowrap"}
                            sx={{
                                overflowX: "auto",
                                width: "100%",
                                height: '100%',
                                marginTop: -1
                            }}
                            spacing={0}
                        >
                            <Grid container direction={"column"} sx={{ minWidth: "7.5em" }} mt={4.1}>
                                <HeaderCell >Device ID</HeaderCell>
                                <HeaderCell >Company Name</HeaderCell>
                                <HeaderCell >Device Name</HeaderCell>
                                <HeaderCell >Product Code</HeaderCell>
                                <HeaderCell >Device Class</HeaderCell>
                                <StatusCell >Status</StatusCell>
                            </Grid>
                            {
                                predicateDevices?.map((item, idx) => {
                                    return (
                                        <Box
                                            key={idx}
                                            sx={{
                                                display: "flex",
                                                flexDirection: 'column',
                                                width: '100%',
                                                // justifyContent: "center",
                                            }}
                                        >
                                            <Box
                                                onClick={() => handleRemovePredicateDevice(item)}
                                                sx={{
                                                    display: 'flex',
                                                    mb: 1,
                                                    cursor: "pointer",
                                                    width: '100%',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        p: 0.3,
                                                        backgroundColor: "#D9D9D9",
                                                        textAlign: "center",
                                                        fontWeight: "900",
                                                        fontFamily: "Inter",
                                                        cursor: "pointer",
                                                        width: '2em'
                                                    }}>
                                                    X
                                                </Box>
                                            </Box>

                                            <StyledTableCell align="center">{item?.id || "---"}</StyledTableCell>
                                            <StyledTableCell align="center">{item?.manufacturer?.name || "---"}</StyledTableCell>
                                            <StyledTableCell align="center">{item?.name || "---"}</StyledTableCell>
                                            <StyledTableCell align="center">{item?.productCode?.code || "---"}</StyledTableCell>
                                            <StyledTableCell align="center">{item?.productCode?.deviceClassCode || "---"}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <StatusButton
                                                    variant="contained"
                                                    onClick={() => handleStatusChange(item?.id, item?.status)}
                                                    style={{
                                                        backgroundColor: item?.status === "CONFIRMED" ? "#038C5A" : "#F0C577",
                                                    }}
                                                >
                                                    {item?.status === "NEW_CHANGES" ? "CONFIRM" : (item?.status || "---")}
                                                </StatusButton>
                                            </StyledTableCell>
                                        </Box>
                                    )
                                })
                            }

                        </Grid>
                    )
                )
            }
        </Grid>
    )
}
export default PredicateDevicesTable;