import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Box, Grid, Typography, Button, Divider, Link, ListItem, List } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import DecisionNode from "./DecisionNode";
import DecisionLog from "./DecisionLog";
import Stepper from "./Stepper";
import { styled } from "@mui/material/styles";
import { ArcherContainer, ArcherElement } from "react-archer";

const DecisionTreeWizard = () => {
  const navigate = useNavigate();

  const [decisionData, setDecisionData] = useState({
    1: { rationale: "Pending", verified: "Pending", usedIn: "Pending" },
    2: { rationale: "Pending", verified: "Pending", usedIn: "Pending" },
    3: { rationale: "Pending", verified: "Pending", usedIn: "Pending" },
    4: { rationale: "Pending", verified: "Pending", usedIn: "Pending" }, 
  });
  const transitionConfig = { duration: 0.17, ease: "easeInOut" };
  const [hasSelected, setHasSelected] = useState(false);
  const [currentDecision, setCurrentDecision] = useState(1);
  const [decision1Result, setDecision1Result] = useState(null); // Store Decision 1 result separately
  const [topResult, setTopResult] = useState(null); // Top result box value
  const [showSuggestion, setShowSuggestion] = useState(false); // Toggle Smart Suggestion visibility
  const [topResultPosition, setTopResultPosition] = useState(null);
  const topResultBoxRef = useRef(null); // Ref for the result box

  // Ensure the position is recalculated when topResultPosition changes
  useLayoutEffect(() => {
    if (hasSelected && topResultPosition) {
      const { top, left } = topResultPosition;
      console.log(`New Position: Top ${top}, Left ${left}`);
    }
  }, [topResultPosition]);

  const handleTopResultPositionChange = (position) => {
    setTopResultPosition(position);
    setHasSelected(true); // Set flag to indicate a box was selected
  };

  const handleDecisionMade = (decision) => {
    setTopResult(`Selected Result for ${decision}`);
    setHasSelected(true); // Set this flag immediately
  };
  const handleDecision1 = (decision) => {
    
    const rationale = "Regulation §878.4825 - 2(i) “[...] patient contacting component [...]”";
    const verified = "Tracer updated 2024.02.17 requirement R2";
    const usedIn = decision === "yes" ? "ISO 10993-1:2003(E) Material Characterization" : "4.2.1 non-contact devices";

    setDecisionData((prevData) => ({
      ...prevData,
      1: { rationale, verified, usedIn }, // Immediate log update
    }));

    setDecision1Result(usedIn);
    setTopResult(usedIn);
  };

  const handleDecision2 = (decision) => {
    console.log(`Decision 2: ${decision}`); // Debugging
    const rationale = "Material determined";
    const verified = "Decision Tree - user selection";
    const usedIn = decision === "yes" ? "Result A" : "Result B";

    setDecisionData((prevData) => ({
      ...prevData,
      2: { rationale, verified, usedIn }, // Immediate log update
    }));
  };

  const handleDecision3 = (decision) => {
    console.log(`Decision 3: ${decision}`); // Debugging
    const rationale = "Sterility checked";
    const verified = "Decision Tree - user selection";
    const usedIn = decision === "yes" ? "Sterile environment" : "Non-sterile environment";

    setDecisionData((prevData) => ({
      ...prevData,
      3: { rationale, verified, usedIn }, // Immediate log update
    }));
  };
  const handleDecision4 = (decision) => {
    console.log(`Decision 4: ${decision}`); // Debugging
    const rationale = 'Decision 4 rationale';
    const verified = 'Verified data';
    const usedIn =
      decision === 'first' ? 'limited (<24 h)' : decision === 'second' ? 'Option 2' : 'Option 3';
  
    setDecisionData((prevData) => ({
      ...prevData,
      4: { rationale, verified, usedIn },
    }));
  
    
  };
  
  const handleResultClick = (decisionNumber, clickedResult) => {
    console.log(`Result Clicked: Decision ${decisionNumber}, Result ${clickedResult}`); // Debugging
    if (decisionNumber === 1) {
      setCurrentDecision(2); // Move to Decision 2
    } else if (decisionNumber === 2) {
      setTopResult(clickedResult);
      setCurrentDecision(3); // Move to Decision 3
    } else if (decisionNumber === 3) {
      setCurrentDecision(4); // Move to Decision 4
    } else if (decisionNumber === 4) {
      // Proceed to the next step after Decision 4
      setCurrentDecision(5); // Adjust as needed
    }
  };
  

  const handleBackClick = () => {
    if (currentDecision > 1) {
        setCurrentDecision((prev) => prev - 1);
        setHasSelected(false); // Hide congratulation modal when going back
    }
};

  const toggleSuggestion = () => {
    setShowSuggestion((prev) => !prev); // Toggle the suggestion box
  };

  return (
    <WizardContainer>
      <Header>
        <StyledBackButton variant="contained" onClick={handleBackClick}>
          <ArrowBackIcon /> Back
        </StyledBackButton>
        <WizardTitle>Decision Tree Wizard</WizardTitle>
      </Header>

      <WizardContent container spacing={2}>
        <Grid style={{ width: '100%', display:'grid', gap:'6.5rem', padding:'0.4rem' }} item xs={12} md={8}>
          <MainColumn>
            <FirstSide>
              <TreeSection>
              <AnimatePresence mode='wait'>
  {currentDecision === 1 && (
      <MotionWrapper
      key="decision1"
      initial={{ opacity: 0, visibility: 'hidden' }}
      animate={{ opacity: 1, visibility: 'visible' }}
      exit={{ opacity: 0, visibility: 'hidden' }}
      transition={transitionConfig}
    >
      <DecisionNode
        number={1}
        question="Does the device contact the body directly or indirectly?"
        yesLabel="Yes"
        noLabel="No"
        onDecisionMade={handleDecision1}
        onResultClick={(clickedResult) => handleResultClick(1, clickedResult)}
        onHelpClick={toggleSuggestion}
        isDashed={true}
      />
      
    </MotionWrapper>
  )}

  {currentDecision === 2 && (
   <MotionWrapper
   key="decision2"
   initial={{ opacity: 0, visibility: 'hidden' }}
   animate={{ opacity: 1, visibility: 'visible' }}
   exit={{ opacity: 0, visibility: 'hidden' }}
   transition={transitionConfig}
 >
      {decision1Result && (
        <>
          <TopResultBox>{topResult}</TopResultBox>
          <VerticalLine />
        </>
      )}
      <DecisionNode
        number={2}
        question="Is this material the same as in an existing commercially available device?"
        yesLabel="Yes"
        noLabel="No"
        onDecisionMade={handleDecision2}
        onResultClick={(clickedResult) => handleResultClick(2, clickedResult)}
        onHelpClick={toggleSuggestion}
        isDashed={true}
      />
    </MotionWrapper>
  )}

  {currentDecision === 3 && (
    <MotionWrapper
      key="decision3"
      initial={{ opacity: 0, visibility: 'hidden' }}
      animate={{ opacity: 1, visibility: 'visible' }}
      exit={{ opacity: 0, visibility: 'hidden' }}
      transition={transitionConfig}
    >
      {decision1Result && (
        <>
          <TopResultBox>{topResult}</TopResultBox>
          <VerticalLine />
        </>
      )}
      <DecisionNode
        number={3}
        question="Is the device used in a sterile environment?"
        yesLabel="Yes"
        noLabel="No"
        onDecisionMade={handleDecision3}
        onResultClick={(clickedResult) => handleResultClick(3, clickedResult)}
        onHelpClick={toggleSuggestion}
        isDashed={true}
      />
    </MotionWrapper>
  )}
{currentDecision === 4 && (
  <Box style={{ position: "relative" }}>
    <MotionWrapper
      key="decision4"
      initial={{ opacity: 0, visibility: "hidden" }}
      animate={{ opacity: 1, visibility: "visible" }}
      exit={{ opacity: 0, visibility: "hidden" }}
      transition={transitionConfig}
    >
      {/* Render TopResultBox initially at the top */}
      {!hasSelected && (
        <>
          <TopResultBox>{topResult}</TopResultBox>
          <VerticalLine />
        </>
      )}

      <motion.div
        initial={{ y: 0 }} // Default position
        animate={hasSelected ? { y: -30 } : { y: 0 }} // Move the DecisionNode up by 50px when selected
        transition={{ duration: 0.5 }} // Match transition duration with TopResultBox
        style={{ position: "relative" }} // Ensure it's positioned relatively to allow movement
      >
        <DecisionNode
          number={4}
          question="What is the duration of contact?"
          thirdDecision={true}
          firstLabel={<span>limited <br /> ( 24 h)</span>}
  secondLabel="prolonged (24h - 30d)"
  thirdLabel="permanent (>30 days)"
          onDecisionMade={(decision) => {
            handleDecisionMade(decision);
          }}
          onTopResultPositionChange={(position) => {
            handleTopResultPositionChange(position);
          }}
        />
      </motion.div>

      {/* Move TopResultBox immediately to the selected option */}
      {hasSelected && topResultPosition && (
        <motion.div
          ref={topResultBoxRef}
          style={{
            position: "absolute",
            width: "250px",
            height: "120px",
          }}
          initial={{ opacity: 0, y: -10, top: "10px", left: "10%" }} // Initial top and left positions
          animate={{
            opacity: 1,
            y: 0,
            top: "93%", // Move vertically
            left: `${topResultPosition.left - 425}px`, // Move horizontally (centered)
          }}
          transition={{ duration: 0.5 }} // Adjust duration as needed
        >
          <TopResultBox>{topResult}</TopResultBox>
        </motion.div>
      )}
    </MotionWrapper>
  </Box>
)}

        
</AnimatePresence>

              </TreeSection>
            </FirstSide>
            
          </MainColumn>
          {hasSelected && (
 <ResultBoxContainer>
 <Title>
   Congratulations -<span style={{color:'#000'}}>You have finished the wizard for ISO 10993-1</span> 
 </Title>
 <Subtitle>Automatic Updates by Accelidea:</Subtitle>

 <StyledList>
   <StyledListItem>
     ISO 10993-5 and 10993-10 were added to{" "}
     <StyledLink href="/standards-list">Standards list</StyledLink>
   </StyledListItem>

   <StyledListItem>
     <StyledLink href="/glossary-product-management">
       Glossary terms for Product Management
     </StyledLink>{" "}
     were updated
   </StyledListItem>

   <StyledListItem>
     <StyledLink href="/glossary-engineering">
       Glossary terms for Engineering
     </StyledLink>{" "}
     were updated
   </StyledListItem>

 
 </StyledList>
</ResultBoxContainer>
)}

        </Grid>

        <Grid item xs={12} md={4} style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>
          <Stepper style={{ flex: 1 }} currentDecision={currentDecision} />
          <Divider orientation="vertical" flexItem style={{ height: "80vh", borderRight: '2px solid #000', paddingLeft: '1.2rem' }} />
        </Grid>

        <Grid style={{ width: '100%' }} item xs={12} md={8}>
          <SideColumn>
            <DecisionLog decisionData={decisionData} currentDecision={currentDecision} showSuggestion={showSuggestion} setShowSuggestion={setShowSuggestion} />
            {hasSelected && (
            <StyledButton onClick={() => navigate('/Tracer-Requirements')}>
            <ButtonText>Finish and Go to Summary</ButtonText>
          </StyledButton>
            )}
          </SideColumn>
        </Grid>
      </WizardContent>
    </WizardContainer>
  );
};
const StyledButton = styled(Button)(({ theme }) => ({
  boxSizing: 'border-box',
  
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  
  padding: '10px 20px',
  gap: '20px',
  width: 'max-content',

  height: '44px',
  
  border: '1px solid #303030',
  borderRadius: '4px',
  
  '&:hover': {
    backgroundColor: '#f5f5f5', // Adjust for hover effect
  }
}));
const ButtonText = styled(Typography)(({ theme }) => ({

  
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '24px',
  fontFeatureSettings: "'tnum' on, 'lnum' on", // Optional feature settings

  color: '#303030',

}));

const ResultBoxContainer = styled(Box)(({ theme }) => ({
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "9px 20px 17px",
  gap: "10px",
  isolation: "isolate",

  background: "#FFFFFF",
  border: "4px solid #038C5A",
  borderRadius: "11px",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "'Inter', sans-serif",  // Applying the Inter font-family
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "19px",
  lineHeight: "23px", // Matches box height as requested
  textAlign: "center", // Center-aligns the text
  color: "#038C5A",
}));


const Subtitle = styled(Typography)(({ theme }) => ({
  fontFamily: "'Inter', sans-serif", // Applying the Inter font-family
  fontStyle: "normal",
  fontWeight: 700, // Bold text
  fontSize: "16px", // Font size 16px
  lineHeight: "19px", // Line height matches the box height
  color: "#303030", // Dark gray color
}));


const StyledList = styled(List)(({ theme }) => ({
  paddingLeft: "20px",
  counterReset: "list-counter", 
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  fontFamily: "'Inter', sans-serif", // Applying the Inter font-family
  fontStyle: "normal",
  fontWeight: 400, // Bold text
  fontSize: "16px", // Font size 16px
  lineHeight: "14px", // Line height matches the box height
  color: "#303030", 
  counterIncrement: "list-counter",
  "&::before": {
    content: "counter(list-counter) '.'", // Display the counter value with a dot
    position: "absolute",
    left: 0,
    fontWeight: 700, // Bold numbers
    color: "#000", // Green color for numbers
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "underline",
  color: "#000",
  marginRight:'2px',
  marginLeft:'2px'
}));
const MotionWrapper = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  
  backgroundColor: theme.backgroundColor || 'transparent',
}));


const WizardContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    padding: "16px 20px",
  },
}));

const WizardContent = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "stretch",
    gap: 0,
  },
}));

const VerticalLine = styled(Box)(({ theme }) => ({
  width: "4px",
  height: "87px",
  backgroundColor: "#303030",
  margin: "0px auto 35px",
}));

const TopResultBox = styled(Box)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#025131",
  padding: "5px",
  borderRadius: "4px",
  width: "250px",
  height: "120px",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "24px",
  fontFamily: "'Inter', sans-serif",
  cursor: "not-allowed",
  pointerEvents: "none",
}));


const FirstSide = styled(Box)(({ theme }) => ({
 
 
  
}));

const StyledBackButton = styled(Button)(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '5px 5px',
  gap: '5px',
  width: '110px',
  fontFamily: 'Inter, sans-serif',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '24px',
  backgroundColor: '#025131',
  border: '1px solid #303030',
  borderRadius: '4px',
  color: 'white',
  fontSize: '16px',
  cursor: 'pointer',
  textTransform: "capitalize",

  '&:hover': {
    backgroundColor: '#024428',
  },
}));

const MainColumn = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
 
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  maxWidth: "100%",
  flexDirection: "column",
  padding: "30px 36px 0px",
  fontFamily: "Inter, sans-serif",
  fontWeight: 700,
  fontSize: "20px",
  [theme.breakpoints.down("md")]: {
    marginLeft: "10px",
  },
}));

const WizardTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "'Inter', sans-serif",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "24px",
  display: "flex",
  alignItems: "center",
  color: "#038c5a",
  textDecoration: "none",
  marginTop: "13px",
}));

const TreeSection = styled(Box)(({ theme }) => ({
  marginTop: "28px",
  display: "flex",
  flexDirection: "column",
  
  alignItems: "center",
  maxWidth: "100%",
  [theme.breakpoints.down("md")]: {
    marginTop: "40px",
  },
}));

const SideColumn = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
 alignItems:'center',
  height:'100%',
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export default DecisionTreeWizard;
