import { gql } from "@apollo/client";


export const IS_PRODUCT_ADDED_TO_IDEA = gql`
    query IsProductAddedToIdea($ideaId: ID!) {
        idea(id: $ideaId) {
            products {
                id
                name
            }
        }
    }
`;