import { combineReducers } from "redux";

function ExploreReducer() {

    const idea = (state = null, action) => {
        switch (action.type) {
            case 'IDEA_INIT':
                return null;
            case 'NEW_IDEA_SUCCESS':
                return action.payload;
            default:
                return state;
        }
    }

    const keyword = (state = null, action) => {
        switch (action.type) {
            case 'KEYWORD_INIT':
                return null;
            case 'NEW_KEYWORD_SUCCESS':
                return action.payload;
            default:
                return state;
        }
    }

    const addedKeywordsLst = (state = [], action) => {
        switch (action.type) {
            case 'ADDED_KEYWORDS_LST_INIT':
                return [];
            case 'ADDED_KEYWORDS_LST_SUCCESS':
                return action.payload;
            default:
                return state;
        }
    }

    const suggestedKeywords = (state = null, action) => {
        switch (action.type) {
            case 'SUGGESTED_KEYWORDS_INIT':
                return null;
            case 'SUGGESTED_KEYWORDS_SUCCESS':
                return action.payload;
            default:
                return state;
        }
    }

    const suggestedKeywordsInView = (state = null, action) => {
        switch (action.type) {
            case 'SUGGESTED_KEYWORDS_IN_VIEW_INIT':
                return null;
            case 'SUGGESTED_KEYWORDS_IN_VIEW_SUCCESS':
                return action.payload;
            default:
                return state;
        }
    }

    const selectedKeywords = (state = null, action) => {
        switch (action.type) {
            case 'SELECTED_KEYWORDS_INIT':
                return null;
            case 'SELECTED_KEYWORDS_UPDATE':
                return action.payload;
            default:
                return state;
        }
    }

    const categoryCounts = (state = null, action) => {
        switch (action.type) {
            case 'SET_CATEGORY_COUNTS_INIT':
                return null;
            case 'SET_CATEGORY_COUNTS_SUCCESS':
                return action.payload;
            default:
                return state;
        }
    }

    const categoryData = (state = null, action) => {
        switch (action.type) {
            case 'SET_CATEGORY_DATA_INIT':
                return null;
            case 'SET_CATEGORY_DATA_SUCCESS':
                return action.payload;
            default:
                return state;
        }
    }

    const selectedCategory = (state = null, action) => {
        switch (action.type) {
            case 'SELECTED_CATEGORY_INIT':
                return null;
            case 'SELECTED_CATEGORY_SUCCESS':
                return action.payload;
            default:
                return state;
        }
    }

    const step = (state = 0, action) => {
        switch (action.type) {
            case 'GET_STEP_INIT':
                return 0;
            case 'UPDATE_STEP':
                return action.payload;
            default:
                return state;
        }
    }

    return combineReducers({
        idea,
        keyword,
        addedKeywordsLst,
        step,
        suggestedKeywords,
        suggestedKeywordsInView,
        selectedKeywords,
        categoryCounts,
        categoryData,
        selectedCategory,
    })
}

export default ExploreReducer();