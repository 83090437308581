import React from "react";
import { styled } from "@mui/material/styles";
import { Button as MuiButton } from "@mui/material";

const Button = styled(MuiButton)(({ active, isRequirements }) => ({
  backgroundColor: active
    ? isRequirements
      ? "rgba(2, 81, 49, 1)" // Green for active Requirements
      : "#843F2F"  // Red for active Risk
    : isRequirements
    ? "#fff"
    : "rgba(243, 231, 229, 1)", // Default light red for inactive Risk

  color: active ? "#FFFFFF" : "#303030", // White text when active, black when inactive

  fontFamily: "'Inter', sans-serif",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "24px",
  fontFeatureSettings: "'tnum' on, 'lnum' on",
 textTransform: "capitalize",
  border: active ? "none" : "1px solid transparent", // No border when active

  "&:hover": {
    backgroundColor: active
      ? isRequirements
        ? "rgba(1, 60, 36, 1)" // Darker green for active Requirements hover
        : "rgba(115, 0, 0, 1)" // Darker red for active Risk hover
      : isRequirements
      ? "#fff"
      : "rgba(243, 231, 229, 1)", // Keep the background color on hover for inactive

    border: active ? "none" : "1px solid #000", // Black border on hover for inactive buttons
  },
  padding: "10px 20px",
  borderRadius: "4px",
  cursor: "pointer",
}));

function ViewButton({ children, active, isRequirements, onClick }) {
  return (
    <Button active={active} isRequirements={isRequirements} onClick={onClick}>
      {children}
    </Button>
  );
}

export default ViewButton;
