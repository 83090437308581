import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, gql } from "@apollo/client";
import { Button, Box, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import { useEffect } from "react";

const StyledButton = styled(Button)(({ isSelected }) => ({
	height: "44px",
	backgroundColor: isSelected ? "#025131" : "transparent",
	fontFamily: "Inter",
	fontWeight: "bold",
	fontSize: "20px",
	color: isSelected ? "#fff" : "#303030",
	letterSpacing: 0,
	border: isSelected ? "1px solid #025131" : "1px solid #000",
	borderRadius: "4px",
	"&:hover": {
		backgroundColor: isSelected ? "#025131" : "#F2F2F2",
		color: isSelected ? "#fff" : "#000",
	},
	textTransform: "none",
}));

const StyledTextField = styled(TextField)(() => ({
	height: "60px",
	minWidth: "300px",
	backgroundColor: "#fff",
	fontFamily: "Inter",
	fontWeight: "light",
	fontSize: "16px",
	"& .MuiFilledInput-root": {
		border: "1px solid #000",
		borderRadius: "4px",
		backgroundColor: "#fff",
		"&:hover": {
			backgroundColor: "#fff",
		},
	},
	"& label": {
		color: "#000000",
	},
	"& label.Mui-focused": {
		color: "#000000",
	},
	"&:hover label": {
		color: "#000000",
	},
}));

const IdeaColumn = ({ step }) => {
	const dispatch = useDispatch();
	const ideaTitle = useSelector((state) => state?.explore?.idea?.title);

	const [idea, setIdea] = useState(ideaTitle || "");
	const [error, setError] = useState({ error: false, message: "" });

	const CREATE_IDEA_GQL = gql`
		mutation CreateIdea($title: String!) {
			createIdea(title: $title) {
				id
				title
			}
		}
	`;

	const [createIdea, { loading }] = useMutation(CREATE_IDEA_GQL);

	const handleAddIdea = () => {
		const isValid = /^[a-zA-Z0-9 ]*$/.test(idea);

		if (idea?.length === 0 || idea === "" || idea === undefined) {
			setError({ error: true, message: "Field cannot be empty" });
		} else if (idea?.trim() === "") {
			setError({ error: true, message: "Please enter a valid idea" });
		} else if (!isValid) {
			setError({ error: true, message: "No special chracters allowed" });
		} else {
			createIdea({
				variables: {
					title: idea,
				},
			})
				.then((res) => {
					dispatch({
						type: "NEW_IDEA_SUCCESS",
						payload: res?.data?.createIdea,
					});
					dispatch({
						type: "UPDATE_STEP",
						payload: 2,
					});
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	useEffect(() => {
		if (ideaTitle === null || ideaTitle?.length === 0) {
			setIdea(null);
		} else {
			setIdea(ideaTitle);
		}
	}, [ideaTitle]);

	return (
		<Box
			sx={{
				display: "flex",
				marginTop: "3.8em",
				flexDirection: "column",
				gap: "20px",
			}}
		>
			<StyledButton
				isSelected={step >= 1}
				style={{
					width: "149px",
				}}
				onClick={() => {
					dispatch({
						type: "UPDATE_STEP",
						payload: 1,
					});
				}}
				disabled={loading}
			>
				+ New Idea
			</StyledButton>

			<motion.div
				initial={{ opacity: 0, display: "hidden" }}
				animate={step > 0 ? { opacity: 1, display: "visible" } : {}}
				transition={{ duration: 1 }}
			>
				<Box
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "20px",
					}}
				>
					<StyledTextField
						label="New Idea"
						variant="filled"
						InputProps={{
							disableUnderline: true,
						}}
						inputProps={{
							maxLength: 30,
						}}
						value={idea ?? ""}
						onChange={(e) => {
							setError({ error: false, message: "" });
							setIdea(e.target.value);
						}}
						error={error?.error}
						helperText={error?.message}
						disabled={loading}
					/>

					<Box
						style={{
							display: "flex",
							justifyContent: "right",
						}}
					>
						<StyledButton
							style={{
								width: "86px",
							}}
							onClick={handleAddIdea}
							disabled={loading}
						>
							Next
						</StyledButton>
					</Box>
				</Box>
			</motion.div>
		</Box>
	);
};

export default IdeaColumn;
