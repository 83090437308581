import { useEffect, useState } from "react";
import {
	Typography,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledHeaderCell = styled(TableCell)(() => ({
	backgroundColor: "#F2F2F2",
	fontWeight: "bold",
	fontSize: "20px",
	fontFamily: "Inter",
	color: "#303030",
	border: "1px solid #303030",
	height: "34px",
	padding: "10px 20px",
}));

const StyledNestedBodyCell = styled(TableCell)(() => ({
	backgroundColor: "#FFFFFF",
	fontWeight: "light",
	fontSize: "16px",
	fontFamily: "Inter",
	color: "#038C5A",
	height: "25px",
	padding: "7px 20px",
}));

const NestedRow = ({
	view,
	columns,
	row,
	index,
	level,
	handleWizardToggle,
}) => {
	const [open, setOpen] = useState(false);

	console.log(row);

	return (
		<>
			<TableRow
				sx={{
					cursor: "pointer",
					"&:hover": {
						backgroundColor: "#DFF1EA",
					},
				}}
				onClick={() => {
					if (level === 1 && row && row?.sections && row.sections.length > 0) {
						setOpen((prevState) => !prevState);
					}

					if (
						level === 2 &&
						row &&
						row?.templates &&
						row.templates.length > 0
					) {
						setOpen((prevState) => !prevState);
					}

					if (level === 3) {
						handleWizardToggle(view);
					}
				}}
			>
				<StyledNestedBodyCell
					align="left"
					sx={{
						paddingLeft: `${level !== 1 ? level + 1 : level}rem`,
						backgroundColor: open ? "#C0E2D6" : "inherit",
					}}
				>
					<Typography fontWeight={"bold"} display="inline">
						{level === 1 && `${index + 1} - `}
						{level === 1 ? row?.sections[0]?.title : row?.title}
					</Typography>
				</StyledNestedBodyCell>

				<StyledNestedBodyCell
					align="center"
					style={{ width: 150 }}
					sx={{
						backgroundColor: open ? "#C0E2D6" : "inherit",
					}}
				>
					{row?.dueOn}
				</StyledNestedBodyCell>

				<StyledNestedBodyCell
					align="center"
					style={{ width: 150 }}
					sx={{
						// paddingLeft: `${level}rem`,
						backgroundColor: open ? "#C0E2D6" : "inherit",
					}}
				>
					{row?.completionRate}
				</StyledNestedBodyCell>
			</TableRow>
			{level === 1 &&
				open &&
				row.sections?.length > 0 &&
				row.sections.map((child, cIdx) => {
					return (
						<NestedRow
							index={child.id}
							key={child.id}
							row={child}
							columns={columns}
							level={level + 1}
							handleWizardToggle={handleWizardToggle}
						/>
					);
				})}

			{level === 2 &&
				open &&
				row.templates?.length > 0 &&
				row.templates.map((child, cIdx) => {
					return (
						<NestedRow
							index={child.id}
							key={child.id}
							row={child}
							columns={columns}
							level={level + 1}
							handleWizardToggle={handleWizardToggle}
						/>
					);
				})}
		</>
	);
};

const NestedTable = ({ view, columns, records, handleWizardToggle }) => {
	console.log("RECORDS: ", records);

	return (
		<TableContainer
			sx={{
				borderRadius: "4px",
				border: "1px solid #000",
				marginBottom: "20px",
			}}
		>
			<Table
				sx={{
					borderCollapse: "collapse",
					borderStyle: "hidden",
					"& td": {
						border: "1px solid #626262",
					},
				}}
			>
				<TableHead>
					<TableRow>
						{columns.map((column) => (
							<StyledHeaderCell
								key={column.id}
								align={column.align}
								style={{ width: column.width }}
							>
								{column.label}
							</StyledHeaderCell>
						))}
					</TableRow>
				</TableHead>

				<TableBody>
					{records &&
						records?.length > 0 &&
						records
							// .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row, index) => {
								return (
									<NestedRow
										view={view}
										columns={columns}
										row={row}
										key={index}
										index={index}
										level={1}
										handleWizardToggle={handleWizardToggle}
									/>
								);
							})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default NestedTable;
