import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const API_URL = process.env.REACT_APP_API_BASE_URL;

const httpLink = createHttpLink({
  uri: API_URL,
  credentials: "include",
});

const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      "Content-Type": "application/json",
    },
  };
});

const client = new ApolloClient({
  link: authLink?.concat(httpLink),
  cache: new InMemoryCache(),
  connectToDevTools: false,
});

export default client;
