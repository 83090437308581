import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography, CircularProgress } from '@mui/material';

function ConfirmDialog({ open, onClose, onConfirm, title, description, loading, cancelButtonLabel = 'No', confirmButtonLabel = 'Yes' }) {
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="confirm-dialog-title" aria-describedby="confirm-dialog-description">
            <DialogTitle sx={{ textDecoration: 'underline', pt: 4, mb: 2 }}>
                <Typography varaint="h4" fontWeight={700} fontSize={'20px'}>
                    {title}{' '}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography>{description}</Typography>
            </DialogContent>
            <DialogActions sx={{ mb: 2 }}>
                <Button onClick={onClose} variant="contained"  disabled={loading}>
                    {cancelButtonLabel}
                </Button>
                <Button onClick={onConfirm} variant="contained" color="primary" autoFocus>
                    {loading ? (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: 'primary.contrastText',
                                position: 'absolute'
                            }}
                        />
                    ) : (
                        confirmButtonLabel
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog;
