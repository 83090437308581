import { Box, CircularProgress, Grid2 as Grid, Typography } from "@mui/material";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_IDEA_COMPANIES } from "../../gql/queries/companies";
import { useEffect, useState } from "react";
import { NotificationToaster } from "../../utils/NotificationToaster";
import ConfirmDialog from "../shared/dialogs/ConfirmDialog";
import { REMOVE_COMPANY_FROM_IDEA } from "../../gql/mutations/companies";


const CompetitorsList = ({ ideaId, handleCompanyDialogOpen }) => {
    const [competitors, setCompetitors] = useState({
        list: [],
        selectedCompetitor: null,
        isConfirmDialogOpen: false
    });

    const [fetchCompanies, { loading }] = useLazyQuery(
        GET_IDEA_COMPANIES,
        {
            fetchPolicy: "no-cache"
        }
    );

    const [RemoveCompanyFromIdea, { loading: loadingRemoveCompanyFromIdea }] = useMutation(
        REMOVE_COMPANY_FROM_IDEA
    )

    const handleConfirmRemoveCompany = (selectedCompetitor) => {
        setCompetitors(prevState => ({
            ...prevState,
            isConfirmDialogOpen: true,
            selectedCompetitor
        }))
    }

    const handleRemoveCompany = () => {
        RemoveCompanyFromIdea({
            variables: {
                ideaId,
                companyId: competitors?.selectedCompetitor?.id,
            },
        })
            .then(() => {
                setCompetitors(prevState => ({
                    ...prevState,
                    list: prevState.list.filter(item => item.id !== prevState.selectedCompetitor.id),
                    isConfirmDialogOpen: false,
                    selectedCompetitor: null
                }))
                NotificationToaster({
                    message: "company removed successfully",
                    type: "success",
                });
            })
            .catch((e) => {
                NotificationToaster({
                    message: e.message ?? "Something went wrong",
                    type: "error",
                });
            });
    }

    useEffect(() => {
        fetchCompanies({
            variables: {
                ideaId
            },
        })
            .then((response) => {
                const companiesResponse = response.data.idea?.companies ?? [];
                setCompetitors(prevState => ({
                    ...prevState,
                    list: companiesResponse
                }))
            })
            .catch((e) => {
                NotificationToaster({
                    message: e.message ?? "Something went wrong",
                    type: "error",
                });
            });
    }, [ideaId]);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Typography
                    fontWeight={"bold"}
                    fontFamily={"Inter"}
                    fontSize={"16px"}
                    color={"#303030"}
                >
                    Competitors List
                </Typography>
            </Box>


            {
                loading ? (
                    <Grid
                        container
                        direction={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        <Grid
                            size={12}
                            textAlign={"center"}
                            sx={{
                                height: '100%',
                                alignItems: "center"
                            }}>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                ) : (
                    competitors?.list?.length === 0 ? (
                        <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"} sx={{ width: "100%", height: "100%" }}>
                            <Grid size={12} textAlign={"center"}>
                                No Records Found
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container sx={{ marginLeft: "30px", marginTop: "10px", maxHeight: "14.5rem", overflowY: "auto" }}>
                            {
                                competitors?.list?.map((item, idx) => {
                                    return (
                                        <Grid
                                            key={item?.id || idx + 1}
                                            size={12}
                                            spacing={0}
                                        >
                                            <Grid container>
                                                <Grid>
                                                    <Typography
                                                        fontWeight={500}
                                                        fontFamily={"Inter"}
                                                        fontSize={"16px"}
                                                        color={"#038C5A"}
                                                        display="inline"
                                                    >
                                                        Company #{idx + 1}:&nbsp;
                                                    </Typography>
                                                </Grid>
                                                <Grid size={"grow"} onClick={() => handleCompanyDialogOpen({ id: idx + 1, company: item })}>
                                                    <Typography
                                                        fontWeight={"bold"}
                                                        fontFamily={"Inter"}
                                                        fontSize={"16px"}
                                                        color={"#038C5A"}
                                                        display="inline"
                                                        fontStyle={"italic"}
                                                    >
                                                        {item.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    onClick={() => handleConfirmRemoveCompany(item)}
                                                    sx={{
                                                        pt: 0.3,
                                                        pb: 0.2,
                                                        pl: 0.5,
                                                        pr: 0.5,
                                                        ml: 0.1,
                                                        mb: 0.1,
                                                        backgroundColor: "#D9D9D9",
                                                        textAlign: "center",
                                                        fontWeight: "900",
                                                        fontFamily: "Inter",
                                                        cursor: "pointer",
                                                        fontSize: "12px"
                                                    }}>
                                                    X
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                {
                                                    item.products?.length > 0 && item.products.map((p, pidx) => {
                                                        return (
                                                            <Grid key={item?.id || pidx + 1} size={12}>
                                                                <Typography
                                                                    fontWeight={500}
                                                                    fontFamily={"Inter"}
                                                                    fontSize={"14px"}
                                                                    display="inline"
                                                                    color={"#038C5A"}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            color: "#038C5A",
                                                                            fontSize: "14px",
                                                                            marginLeft: "20px",
                                                                        }}
                                                                    >
                                                                        •
                                                                    </span>
                                                                    &nbsp;Product #{pidx + 1}:&nbsp;
                                                                </Typography>

                                                                <Typography
                                                                    fontWeight={"bold"}
                                                                    fontFamily={"Inter"}
                                                                    fontSize={"14px"}
                                                                    color={"#038C5A"}
                                                                    display="inline"
                                                                    fontStyle={"italic"}
                                                                >
                                                                    {p?.name ?? "---"}
                                                                </Typography>

                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>


                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    )
                )
            }



            {
                competitors?.isConfirmDialogOpen && (
                    <ConfirmDialog
                        open={competitors?.isConfirmDialogOpen}
                        onClose={() => {
                            setCompetitors(prevState => ({
                                ...prevState,
                                isConfirmDialogOpen: false
                            }))
                        }}
                        onConfirm={handleRemoveCompany}
                        title="Confirm Your Action"
                        description="Are you sure you want to delete this competitor?"
                        loading={false}
                    />
                )
            }
        </>
    )
}
export default CompetitorsList;