import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import EastIcon from "@mui/icons-material/East";

const StyledViewSummaryButton = styled(Button)(() => ({
    width: "236px",
    height: "44px",
    backgroundColor: "#F3E7E5",
    textTransform: "none",
    marginBottom: "40px",
}));

const GoToSummaryButton = ({ handleViewSummary }) => {
    return (
        <StyledViewSummaryButton onClick={handleViewSummary}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <EastIcon
                    style={{
                        marginRight: "4px",
                        color: "#303030",
                    }}
                />
                <Typography
                    fontFamily={"Inter"}
                    fontSize={"20px"}
                    fontWeight={"bold"}
                    color={"#303030"}
                >
                    Go to
                </Typography>
                <Typography
                    fontFamily={"Inter"}
                    fontSize={"20px"}
                    fontWeight={800}
                    color={"#303030"}
                >
                    &nbsp;Summary
                </Typography>
            </Box>
        </StyledViewSummaryButton>
    )
}

export default GoToSummaryButton;