import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, TextField, FormHelperText, Typography, Button, Box } from '@mui/material';

const CustomAutocompleteSelectField = ({
                                     control,
                                     name,
                                     options,
                                     rules,
                                     error,
                                     defaultValue,
                                     label = null,
                                     labelKey = 'label',
                                     valueKey = 'value',
                                     multiple = false,
                                     onChange = () => {},
                                     height = 'auto',
                                     ...rest
                                 }) => {
    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={multiple ? [] : defaultValue}
            render={({ field, fieldState: { error: fieldError } }) => (
                <>
                    <Autocomplete
                        {...field}
                        {...rest}
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        multiple={multiple}
                        options={options}
                        isOptionEqualToValue={(option, value) => option[valueKey] === value[valueKey]}
                        value={multiple ? field.value || [] : field.value ?? null}
                        onChange={(event, value) => {
                            onChange(value);
                            field.onChange(value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={!!fieldError}
                                fullWidth
                                variant="outlined"
                            />
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Typography key={index} variant="body2" {...getTagProps({ index })}>
                                    {option[labelKey]}
                                </Typography>
                            ))
                        }
                    />
                    {fieldError?.message && (
                        <FormHelperText sx={{ color: '#C30707', paddingLeft: '14px' }}>
                            {fieldError.message}
                        </FormHelperText>
                    )}
                    {/*{multiple && (*/}
                    {/*    <Button sx={{ mt: 1 }} onClick={handleClose}>*/}
                    {/*        Done*/}
                    {/*    </Button>*/}
                    {/*)}*/}
                </>
            )}
        />
    );
};

export default CustomAutocompleteSelectField;
