import React, { createContext, useState } from "react";

// Create a context
export const ToggleContext = createContext();

// Create a provider component
export const ToggleProvider = ({ children }) => {
  const [showDashedLines, setShowDashedLines] = useState(false); // Default value is false

  return (
    <ToggleContext.Provider value={{ showDashedLines, setShowDashedLines }}>
      {children}
    </ToggleContext.Provider>
  );
};
