import { Grid2 as Grid, Typography, CircularProgress, Button, TextField } from "@mui/material";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useForm, Controller } from 'react-hook-form';
import { GET_ENG_GLOSSARY_GQL } from "../../gql/queries/summary";
import { UPDATE_ENGINEERING_GLOSSARY_GQL } from "../../gql/mutations/ideas";
import { useEffect, useState } from "react";
import { NotificationToaster } from "../../utils/NotificationToaster";

const EngineeringGlossary = (props) => {
    const { ideaId } = props;

    const {
        handleSubmit,
        control,
        setValue
    } = useForm({
        defaultValues: {
            material: '',
            dimensions: '',
        }
    });

    const [getEngGlossary, { loading }] = useLazyQuery(
        GET_ENG_GLOSSARY_GQL,
        {
            fetchPolicy: 'no-cache'
        }
    );

    const [updateEngineeringGlossay, { loading: updateEGlossayLoading }] = useMutation(
        UPDATE_ENGINEERING_GLOSSARY_GQL
    )

    const fetchEngGlossaryData = () => {
        getEngGlossary({
            variables: {
                ideaId
            },
        })
            .then((response) => {
                const companiesResponse = response.data.idea ?? {};
                const calculatedDimension = `${companiesResponse?.height ?? "0"} x ${companiesResponse?.width ?? 0} x ${companiesResponse?.depth ?? 0}`;

                setValue('material', companiesResponse.material ?? "not defined yet");
                setValue('dimensions', calculatedDimension);
            })
            .catch((e) => {
                NotificationToaster({
                    message: e.message ?? "Something went wrong",
                    type: "error",
                });
            });
    }

    const onSubmit = (data) => {
        const dimensions = data?.dimensions;
        const match = dimensions?.match(/^(\d+)\s+x\s+(\d+)\s+x\s+(\d+)$/);

        if (match) {
            const [, number1, number2, number3] = match;

            console.log("Number 1:", number1);
            console.log("Number 2:", number2);
            console.log("Number 3:", number3);

            const payload = {
                material: data?.material || "",
                height: number1 || "",
                width: number2 || "",
                depth: number3 || "",
            }

            updateEngineeringGlossay({
                variables: {
                    updateIdeaId: ideaId,
                    ...payload
                }
            })
                .then((res) => {
                    NotificationToaster({ message: 'Engineering Glossary updated successfully', type: 'success' });
                })
                .catch((err) => {
                    NotificationToaster({ message: 'An error occured while updating Engineering Glossay', type: 'error' });
                })

        } else {
            NotificationToaster({ message: 'Dimensions added are incorrect', type: 'error' });
        }

        // UpdateIdea({
        //     variables: {
        //         updateIdeaId: ideaId,
        //         ...payload,
        //     },
        // })
        //     .then(() => {
        //         delete payload.countries;
        //         setDefaultFormValues(payload)
        //         NotificationToaster({
        //             message: "PM glossary updated successfully",
        //             type: "success",
        //         });
        //     })
        //     .catch((e) => {
        //         NotificationToaster({
        //             message: e.message ?? "Something went wrong",
        //             type: "error",
        //         });
        //     });
    };

    useEffect(() => {
        fetchEngGlossaryData();
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction={"column"} spacing={2}>
                <Grid size={8}>
                    <Typography
                        fontWeight={"bold"}
                        fontFamily={"Inter"}
                        fontSize={"16px"}
                        color={"#303030"}
                    >
                        Engineering Glossary
                    </Typography>
                </Grid>

                {
                    loading ? (
                        <Grid
                            container
                            direction={"column"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            sx={{
                                width: "100%",
                                height: "100%",
                                marginTop: 2
                            }}
                        >
                            <Grid
                                size={12}
                                textAlign={"center"}
                                sx={{
                                    height: '100%',
                                    alignItems: "center"
                                }}>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            container
                            sx={{
                                marginLeft: "30px",
                                marginTop: "10px"
                            }}
                            alignItems={"center"}
                            spacing={1}
                        >
                            <Grid size={3.5}>
                                <Typography
                                    fontWeight={"light"}
                                    fontFamily={"Inter"}
                                    fontSize={"16px"}
                                    color={"#303030"}
                                >
                                    Material:
                                </Typography>
                            </Grid>

                            <Grid size={7.5}>
                                <Controller
                                    name="material"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            sx={{ margin: 0 }}
                                            size={"small"}
                                            {...field}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid size={3.5}>
                                <Typography
                                    fontWeight={"light"}
                                    fontFamily={"Inter"}
                                    fontSize={"16px"}
                                    color={"#303030"}
                                >
                                    Dimensions (H x W x D):
                                </Typography>
                            </Grid>

                            <Grid size={7.5}>
                                <Controller
                                    name="dimensions"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "Dimensions are required",
                                        pattern: {
                                            value: /^\d+\s+x\s+\d+\s+x\s+\d+$/,
                                            message: "Format must be 'number x number x number'"
                                        }
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            sx={{ margin: 0 }}
                                            size={"small"}
                                            {...field}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            error={!!error}
                                            helperText={error ? error.message : ""}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    )
                }
            </Grid>

            {
                !loading && (
                    <Grid
                        container
                        direction={"row"}
                        spacing={1}
                        alignItems={"center"}
                        justifyContent={"center"}
                        mt={2}
                    >
                        <Button
                            type="submit"
                            sx={{
                                width: '20px',
                                color: '#fff',
                                backgroundColor: '#025131'
                            }}
                            disabled={updateEGlossayLoading}
                        >
                            Save
                        </Button>
                    </Grid>
                )
            }
        </form>
    )
}
export default EngineeringGlossary;