import { combineReducers } from "redux";

function SummaryReducer() {

    const predicateDevices = (state = null, action) => {
        switch (action.type) {
            case 'PREDICATE_DEVICES_INIT':
                return null;
            case 'PREDICATE_DEVICES_SUCCESS':
                return action.payload;
            default:
                return state;
        }
    }

    return combineReducers({
        predicateDevices,
    })
}

export default SummaryReducer();