import * as React from 'react';
import Box from '@mui/material/Box';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/material/styles';

export default function CustomVerticalStepper({ currentDecision, totalDecisions = 3 }) {
  // The top value for the first decision is 14%, and the last decision is 1%
  const firstTop = 18; // 14% for the first box
  const lastTop = 1;   // 1% for the last box

  // Calculate the stepBox height dynamically based on the number of total decisions
  const stepBoxHeight = 75 / totalDecisions; // Each stepbox will take up a portion of 75vh

  // Function to calculate the position based on the decision number
  const calculatePosition = () => {
    const positionRange = 43; // The range between the first and last top values
    const percentageStep = positionRange / (totalDecisions - 1); // Calculate step between each decision
    return `${firstTop + percentageStep * (currentDecision - 1)}%`; // Calculate top based on current decision
  };

  return (
    <StepperContainer>
      {/* Arrow at the top */}
      <ArrowWrapper>
        <ArrowDropUpIcon fontSize="large" />
      </ArrowWrapper>

      <StepContentWrapper>
        <StepBox
          sx={{
            height: `${stepBoxHeight}vh`, // Dynamic height based on the number of total decisions
            top: calculatePosition(), // Dynamically set top position based on current decision
            transform: 'translateY(-50%)', // To center the stepbox in its place
          }}
        />
      </StepContentWrapper>

      {/* Arrow at the bottom */}
      <ArrowWrapper>
        <ArrowDropDownIcon fontSize="large" />
      </ArrowWrapper>
    </StepperContainer>
  );
}

// Styled Components

const StepperContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '80vh', // Total height of 75vh
  border: '1px solid #626262',
  width: '30px',
  backgroundColor: '#f5f5f5',
  position: 'relative',
  overflow: 'hidden', // Prevent overflow outside the container
}));

const ArrowWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});

const StepContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  position: 'relative',
  height: '100%',
});

const StepBox = styled(Box)({
  width: '26px',
  backgroundColor: '#e0e0e0', 
  marginBottom: '4px', 
  borderBottom: '1px solid #000',
  borderTop: '1px solid #000',
  position: 'absolute', // Absolutely positioned to allow dynamic movement
  transition: 'all 0.5s ease', // Smooth transition when moving between decisions
});
